import React, {
    useCallback,
    useState,
    useEffect
} from 'react';
import {Grid} from '@mui/material';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import NewsCard from "src/components/NewsCard";
import LinearProgress from "@mui/material/LinearProgress";

function News({...rest}) {
    const isMountedRef = useIsMountedRef();
    const [newsList, setNewsList] = useState(null);

    const getNewsList = useCallback(() => {
        axios
            .get('/api/general/newsteasers', {
                params: {
                    offset: 0,
                    limit: 3
                }
            })
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("newsteasers") && response.data.payload.newsteasers.length > 0) {
                        setNewsList(response.data.payload.newsteasers);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getNewsList();
    }, [getNewsList]);

    return (
        <Grid container spacing={3} {...rest}>
            {!newsList && <LinearProgress/>}
            {
                newsList && newsList.length > 0 &&
                newsList.map(news => (
                    <Grid item key={news.id} xl={3} md={4} sm={6} xs={12}>
                        <NewsCard news={news}/>
                    </Grid>
                ))
            }
        </Grid>
    );
}

News.propTypes = {};

export default News;
