import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Container
} from '@mui/material';
import Page from 'src/components/Page';
import NewsCard from "../../../components/NewsCard";
import Header from './Header';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import LoadingScreen from "src/components/LoadingScreen";
import {getTitle} from "src/utils/meta";
import {Navigate} from 'react-router-dom';
import Grid from "@mui/material/Grid";

function NewsListView() {
    const isMountedRef = useIsMountedRef();
    const [newsList, setNewsList] = useState(null);

    const getNewsList = useCallback(() => {
        axios
            .get('/api/general/newsteasers', {
                params: {
                    offset: 0,
                    limit: 24
                }
            })
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("newsteasers") && response.data.payload.newsteasers.length > 0) {
                        setNewsList(response.data.payload.newsteasers);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getNewsList();
    }, [getNewsList]);

    if (newsList === null) {
        return <LoadingScreen/>;
    }

    if (newsList === false) {
        return <Navigate to="/404"/>;
    }

    return (
        <Page style={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle("News")}>
            <Container maxWidth="lg" sx={{paddingTop: 3, paddingBottom: 3}}>
                <Header/>
                <Box mt={1}>
                    <Grid
                        container
                        spacing={3}
                    >
                        {
                            newsList && newsList.length > 0 &&
                            newsList.map(news => (
                                <Grid item key={news.id} xl={3} md={4} sm={6} xs={12}>
                                    <NewsCard news={news}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
}

export default NewsListView;
