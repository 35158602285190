import React from 'react';
import PropTypes from 'prop-types';
import {useTheme} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Bar} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import numeral from "src/mixins/numeral";
import Score from 'src/utils/score';

function Compact({trendScore, risikoScore, technischerScore, valueScore, kombiScore, handleDetailClick, ...rest}) {
    const theme = useTheme();

    const barChartOptions = {
        tooltips: {
            enabled: false
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display: false,
                },
                ticks: {
                    fontColor: theme.palette.text.secondary,
                }
            }],
            yAxes: [{
                display: false,
                ticks: {
                    min: 0,
                    max: 1,
                    beginAtZero: true,
                }
            }]
        },
        legend: {
            display: false,
        },
        plugins: {
            datalabels: {
                anchor: 'center',
                color: '#ffffff',
                formatter: value => {
                    return numeral(value).format('0.0 %')
                }
            }
        }
    };

    const barChartData = {
        labels: ['Trend', 'Safety', 'Tech', 'Value', 'Kombi'],
        datasets: [
            {
                barPercentage: 0.8,
                data: [trendScore, risikoScore, technischerScore, valueScore, kombiScore],
                backgroundColor: [
                    Score.getChartScoreColor('trendScore').color,
                    Score.getChartScoreColor('risikoScore').color,
                    Score.getChartScoreColor('technischerScore').color,
                    Score.getChartScoreColor('valueScore').color,
                    Score.getChartScoreColor('kombiScore').color
                ],
                borderColor: [
                    Score.getChartScoreColor('trendScore').color,
                    Score.getChartScoreColor('risikoScore').color,
                    Score.getChartScoreColor('technischerScore').color,
                    Score.getChartScoreColor('valueScore').color,
                    Score.getChartScoreColor('kombiScore').color
                ]
            }
        ]
    };

    return (
        <Card {...rest}>
            <CardHeader title="Score-Überblick"/>
            <Divider/>
            <Box sx={{
                paddingTop: 3
            }}>
                <Bar data={barChartData} options={barChartOptions}/>
            </Box>
            <Box
                p={2}
                display="flex"
                justifyContent="flex-end"
            >
                <Button
                    onClick={handleDetailClick}
                    size="small"
                >
                    Zur Score-Detailansicht
                    <NavigateNextIcon/>
                </Button>
            </Box>
        </Card>
    );
};

Compact.propTypes = {
    trendScore: PropTypes.number,
    risikoScore: PropTypes.number,
    technischScore: PropTypes.number,
    valueScore: PropTypes.number,
    kombiScore: PropTypes.number,
    handleDetailClick: PropTypes.func
};

export default Compact;
