import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useNavigate} from 'react-router';
import {Avatar, Box, Container, Card, CardContent, CardMedia, Divider, Link, Typography} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import LegalNoteLinkList from "src/components/LegalNoteLinkList";
import LoginForm from './LoginForm';

function LoginView() {
    const navigate = useNavigate();

    const handleSubmitSuccess = () => {
        navigate('/');
    };

    return (
        <Page
            title="Anmelden"
            style={{
                justifyContent: 'center',
                display: 'flex',
                height: '100%',
                minHeight: '100vh',
                flexDirection: 'column',
            }}
        >
            <Container maxWidth="md">
                <Box
                    mb={8}
                    display="flex"
                    alignItems="center"
                >
                    <RouterLink to="/">
                        <Logo invert/>
                    </RouterLink>
                </Box>
                <Card sx={{
                    overflow: 'visible',
                    display: 'flex',
                    position: 'relative',
                    '& > *': {
                        flexGrow: 1,
                        flexBasis: '50%',
                        width: '50%'
                    }
                }}>
                    <CardContent sx={{pt: 8, pr: 4, pb: 3, pl: 4}}>
                        <Avatar sx={{
                            backgroundColor: 'secondary.main',
                            color: 'common.white',
                            padding: 1,
                            position: 'absolute',
                            top: -32,
                            borderRadius: 1,
                            height: 64,
                            width: 64
                        }}>
                            <LockIcon fontSize="large"/>
                        </Avatar>
                        <Typography
                            variant="h2"
                            color="textPrimary"
                        >
                            Anmelden
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            color="textSecondary"
                        >
                            Bitte geben Sie Ihre Zugangsdaten ein.
                        </Typography>
                        <Box mt={3}>
                            <LoginForm onSubmitSuccess={handleSubmitSuccess}/>
                        </Box>
                        <Box my={2}>
                            <Divider/>
                        </Box>
                        <Link
                            component={RouterLink}
                            to="/register/requestpassword"
                            variant="body2"
                            color="textSecondary"
                            underline="none"
                        >
                            Passwort vergessen?
                        </Link><br/>
                        <Link
                            href="https://www.investolio.de/de/mitglied-werden"
                            target="_blank"
                            variant="body2"
                            color="textSecondary"
                            underline="none"
                        >
                            Mitglied werden
                        </Link>
                    </CardContent>
                    <CardMedia
                        sx={{
                            borderTopRightRadius: 4,
                            borderBottomRightRadius: 4,
                            padding: 3,
                            color: 'common.white',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            display: {
                                lg: 'flex',
                                xs: 'none',
                            }
                        }}
                        image="/static/images/auth.jpg"
                        title="Cover"
                    >
                    </CardMedia>
                </Card>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LegalNoteLinkList/>
                </Box>
            </Container>
        </Page>
    );
}

export default LoginView;
