import React, {useState, useRef, useEffect, useCallback} from 'react';
import {
    Container,
    Grid,
    Box,
    Typography
} from '@mui/material';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import Page from 'src/components/Page';
import axios from 'src/utils/axios';
import Header from './Header';
import Toolbar from "./Toolbar";
import ScoresCompact from "./Scores/Compact";
import Details from "./Details";
import PriceChart from "./PriceChart";
import ScoreChart from "./ScoreChart";
import ScoresDetail from "./Scores/Detail";
import {useParams} from "react-router-dom";
import {getTitle} from "src/utils/meta";
import {useSnackbar} from "notistack";
import useIsMountedRef from "src/hooks/useIsMountedRef";

function SearchView() {
    const isMountedRef = useIsMountedRef();
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const {paramIndexKurz, paramIsin, paramDatum} = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const stockScoresCompact = useRef(null);
    const account = useSelector((state) => state.account);

    const handleDetailClick = event => {
        event.preventDefault();
        stockScoresCompact.current.scrollIntoView({behavior: 'smooth'});
    };

    const handleSearch = (isin, indexKurz, datum) => {
        if (isin && indexKurz && datum) {
            navigate(`/stocks/search/${indexKurz}/${isin}/${datum}`);
        }
    };

    const getSearchResult = useCallback((isin, indexKurz, datum) => {
        if (isMountedRef.current) {
            if (isin && indexKurz && datum) {
                setLoading(true);

                return axios
                    .get('/api/invest/aktie', {
                        params: {
                            ISIN: isin,
                            Index_kurz: indexKurz,
                            datum: datum
                        }
                    }).then((response) => {
                        setLoading(false);

                        if (response.data.payload.hasOwnProperty("aktie") && response.data.payload.aktie.length > 0) {
                            setData(response.data.payload.aktie[0]);
                        } else {
                            enqueueSnackbar('Keine Daten zur Suchanfrage vorhanden', {
                                variant: 'error'
                            });
                            setData(null);
                        }
                    }).catch(error => {
                        setLoading(false);
                    });
            }
        }
    }, [isMountedRef, enqueueSnackbar]);

    useEffect(() => {
        if (paramIsin && paramIndexKurz) {
            getSearchResult(paramIsin, paramIndexKurz, (typeof paramDatum == 'undefined' ? account.user.livedate : paramDatum));
        }
    }, [getSearchResult, account, paramIsin, paramIndexKurz, paramDatum]);

    let title:string = paramIsin ? paramIsin + ' -  Aktiensuche' : 'Aktiensuche';

    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle(title)}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth="lg">
                <Header/>
                <Box mt={3}>
                    <Toolbar searchIsin={paramIsin} searchIndexKurz={paramIndexKurz}
                             searchDate={typeof paramDatum == 'undefined' ? account.user.livedate : paramDatum} onSearch={handleSearch} loading={loading}/>
                </Box>
                <Box mt={6}>
                    {
                        data !== null && !loading &&
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={7}>
                                    <Details data={data}/>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <ScoresCompact
                                        trendScore={data.Trend_Score}
                                        risikoScore={data.Risiko_Score}
                                        technischerScore={data.Technisch_Score}
                                        valueScore={data.Value_Score}
                                        kombiScore={data.Kombi_Score}
                                        handleDetailClick={handleDetailClick}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <PriceChart aktie={data}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <ScoreChart aktie={data}/>
                                </Grid>
                                <Grid item xs={12} ref={stockScoresCompact}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        mb={3}
                                    >
                                        <Typography
                                            sx={{
                                                position: 'relative',
                                                '&:before': {
                                                    position: 'absolute',
                                                    bottom: -8,
                                                    left: 0,
                                                    content: '" "',
                                                    height: 3,
                                                    width: 48,
                                                    backgroundColor: 'primary.main'
                                                }
                                            }}
                                            variant="h5"
                                            color="textPrimary"
                                        >
                                            Score-Detailansicht
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <ScoresDetail
                                kombiScore={data.Kombi_Score}
                                technischerScore={data.Technisch_Score}

                                trendScore={data.Trend_Score}
                                smaRatio={data.SMA_Ratio}
                                deltaSma={data.Delta_SMA}
                                hochTiefRatio={data.HochTiefRatio}

                                risikoScore={data.Risiko_Score}
                                verlustVola={data.VerlustVola}
                                cdd={data.CDD}
                                upDownDays={data.UpDownDays}
                                qnr={data.QNR}

                                valueScore={data.Value_Score}
                                kgv={data.KGV}
                                kuv={data.KUV}
                                ebitev={data.EBITEV}
                                roc={data.ROC}
                                modRoa={data.ModROA}
                                grMarg={data.GrMarg}
                                netMarg={data.NetMarg}/>
                        </>
                    }
                </Box>
            </Container>
        </Page>
    );
}

export default SearchView;
