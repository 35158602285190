import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import React, {useCallback, useEffect, useState} from 'react';
import {Container, Card, Box, Button} from '@mui/material';
import Investitionsquote from "src/components/Investitionsquote";
import Header from "./Header";
import Page from "src/components/Page";
import {getTitle} from "src/utils/meta";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Grid from "@mui/material/Grid";
import axios from "src/utils/axios";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import LoadingScreen from "src/components/LoadingScreen";
import SignalLabel from "src/components/SignalLabel";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import numeral from "src/mixins/numeral";

function InvestmentRateView({...rest}) {
    const isMountedRef = useIsMountedRef();
    const [investitionsquote, setInvestitionsquote] = useState(null);
    const [helpOpen, setHelpOpen] = useState(false);
    const [helpTitle, setHelpTitle] = useState('');
    const [helpText, setHelpText] = useState('');

    const handleHelpClick = (helpTitle, helpText) => {
        if (helpTitle && helpText) {
            setHelpTitle(helpTitle);
            setHelpText(helpText);
        }

        setHelpOpen(!helpOpen);
    };

    const handleHelpClose = () => {
        setHelpOpen(false);
    };

    const getTableRow = (key, value) => {
        value = value || numeral(investitionsquote[key]['Wert']).format('0.00 %');

        return (
            <TableRow>
                <TableCell>{investitionsquote[key]['Key:bez']}</TableCell>
                <TableCell align="right">
                    <SignalLabel signal={investitionsquote[key]['Signal']} value={value}/>
                </TableCell>
                <TableCell style={{
                    width: 10,
                }}>
                    <IconButton
                        onClick={() => handleHelpClick(investitionsquote[key]['Key:bez'], investitionsquote[key]['Text'])}
                        size="large">
                        <SvgIcon fontSize="small">
                            <HelpOutlineIcon/>
                        </SvgIcon>
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    };

    const getInvestitionsquote = useCallback(() => {
        axios
            .get('/api/general/investitionsquote')
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("investitionsquote")) {
                        setInvestitionsquote(response.data.payload.investitionsquote);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getInvestitionsquote();
    }, [getInvestitionsquote]);

    if (!investitionsquote) {
        return <LoadingScreen/>;
    }

    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle("Investitionsquote")}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth="lg">
                <Header/>
                <Box mt={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Card sx={{
                                color: 'secondary.contrastText',
                                backgroundColor: 'secondary.main',
                            }}>
                                <CardHeader
                                    title="Aktuelle Investitionsquote"
                                    action={
                                        <IconButton
                                            style={{color: 'white'}}
                                            onClick={() => handleHelpClick(investitionsquote['QuoteFinal']['Key:bez'], investitionsquote['QuoteFinal']['Text'])}
                                            size="large">
                                            <SvgIcon fontSize="small">
                                                <HelpOutlineIcon/>
                                            </SvgIcon>
                                        </IconButton>
                                    }
                                />
                                <CardContent sx={{
                                    paddingY: 0,
                                    paddingX: 2
                                }}>
                                    <Investitionsquote value={investitionsquote.QuoteFinal.Wert}/>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <Table>
                                    <TableHead>
                                        {getTableRow('Q1_Signal')}
                                    </TableHead>
                                    <TableBody>
                                        {getTableRow('Aktienmarkt_vs_GD260')}
                                        {getTableRow('Aktienmarkt_vs_GD130')}
                                        {getTableRow('Ver\u00e4nderung_GD20')}
                                    </TableBody>
                                </Table>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <Table>
                                    <TableHead>
                                        {getTableRow('Q2_Signal')}
                                    </TableHead>
                                    <TableBody>
                                        {getTableRow('Ver\u00e4nderung_20')}
                                        {getTableRow('Ver\u00e4nderung_130')}
                                        {getTableRow('Ver\u00e4nderung_260')}
                                    </TableBody>
                                </Table>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <Table>
                                    <TableHead>
                                        {getTableRow('Q3_Signal')}
                                    </TableHead>
                                    <TableBody>
                                        {getTableRow('Aktien_Kaufsignal', parseInt(investitionsquote['Aktien_Kaufsignal']['Wert']))}
                                        {getTableRow('Aktien_Verkaufsignal', parseInt(investitionsquote['Aktien_Verkaufsignal']['Wert']))}
                                    </TableBody>
                                </Table>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <Table>
                                    <TableHead>
                                        {getTableRow('Q4_Signal')}
                                    </TableHead>
                                    <TableBody>
                                        {getTableRow('Renditedifferenz_Anleihen')}
                                        {getTableRow('VIX', numeral(investitionsquote['VIX']['Wert']).format('0.0[0]'))}
                                        {getTableRow('Zyklisch_vs_Defensiv')}
                                        {getTableRow('Intermarket')}
                                    </TableBody>
                                </Table>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
                <Dialog
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "500px",  // Set your width here
                            },
                        }
                    }}
                    open={helpOpen}
                    onClose={handleHelpClose}
                >
                    <DialogTitle>{helpTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {helpText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleHelpClick} color="primary" autoFocus>
                            Schließen
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Page>
    );
}

InvestmentRateView.propTypes = {};

export default InvestmentRateView;
