import React, {useCallback, useEffect, useState} from 'react';
import _, {debounce} from 'lodash';
import PropTypes from "prop-types";
import useIsMountedRef from "../hooks/useIsMountedRef";
import axios from "../utils/axios";
import LoadingScreen from "./LoadingScreen";
import {DataGridPro, deDE, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid-pro';

function CustomDataGrid({
                            options,
                            apiRef,
                            page,
                            pageSize,
                            onPageChange,
                            onPageSizeChange,
                            onSortModelChange,
                            columns,
                            configSettingKey,
                            initialState,
                            ...rest
                        }) {
    const isMountedRef = useIsMountedRef();
    const [settingInitialState, setSettingInitialState] = useState(null);
    const [gridPage, setGridPage] = useState(page || 0);
    const [gridPageSize, setGridPageSize] = useState(pageSize || 25);
    const [config, setConfig] = useState(false);
    const defaultOptions = {
        paging: false,
        pageSizeOptions: [10, 25, 50, 100],
        header: true,
        footer: false,
    };

    options = _.merge(defaultOptions, options);

    const saveState = (overwriteState) => {
        //console.log(overwriteState);
        let gridState = apiRef.current.exportState();

        if (overwriteState) {
            gridState = _.merge(apiRef.current.exportState(), overwriteState);
        }

        setUserSetting(gridState);
    };

    const setUserSetting = useCallback(debounce((exportState) => {
        axios
            .put('/api/user/settings/material-table_' + configSettingKey, {
                setting: {
                    state: exportState
                }
            })
            .then((response) => {
                if (!response.data.payload.hasOwnProperty('ok') || response.data.payload.ok !== true) {
                    throw new Error(response.data.errorMessage)
                }
            }).catch((error) => {
        });
    }, 250), [columns, configSettingKey]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton/>
                <GridToolbarExport
                    csvOptions={{
                        utf8WithBom: true,
                        delimiter: ';'
                    }}
                    printOptions={{
                        disableToolbarButton: true,
                    }}
                />
            </GridToolbarContainer>
        );
    };

    const getConfig = useCallback(() => {
        if (!configSettingKey) {
            setConfig(null);
        } else {
            if (!settingInitialState) {
                // Nur dynamisch die Konfig laden, wenn es einen Header bei der Material-Table gibt
                axios
                    .get('/api/user/settings/material-table_' + configSettingKey)
                    .then((response) => {
                        if (isMountedRef.current) {
                            if (response.data.payload.hasOwnProperty("setting") && response.data.payload.setting) {
                                // Das Attritbut "columnVisibilityModel" muss in v5 auf leer gesetzt werden, wenn es nicht vorahnden ist, da es ansonsten nicht im
                                // Export drin ist:https://github.com/mui/mui-x/issues/6589
                                if (
                                    response.data.payload.setting.hasOwnProperty('state')
                                    && response.data.payload.setting.state.hasOwnProperty('columns')
                                    && !response.data.payload.setting.state.columns.hasOwnProperty('columnVisibilityModel')
                                ) {
                                    response.data.payload.setting.state.columns['columnVisibilityModel'] = {};
                                }

                                // Das Attribut "preferencePanel" muss in v5 gesetzt werden, weil beim CLose-Event "onPreferencePanelClose" das Panel immer noch
                                // als sichtbar fehlerhaft gesetzt ist: https://github.com/mui/mui-x/issues/4737#issuecomment-1138662220
                                if (
                                    response.data.payload.setting.hasOwnProperty('state')
                                    && response.data.payload.setting.state.hasOwnProperty('preferencePanel')
                                ) {
                                    response.data.payload.setting.state['preferencePanel']['open'] = false;
                                }

                                if (response.data.payload.setting.hasOwnProperty('state')) {
                                    setGridPage(response.data.payload.setting.state.pagination.page);
                                    setGridPageSize(response.data.payload.setting.state.pagination.pageSize);
                                    setSettingInitialState(response.data.payload.setting.state)
                                }

                                setConfig(true);
                            } else {
                                setSettingInitialState(initialState);
                                setConfig(null);
                            }
                        }
                    });
            }
        }
    }, [configSettingKey]);

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    if (config === false) {
        return <LoadingScreen/>;
    }

    return (
        <div style={{display: 'flex', height: '100%'}}>
            <div style={{flexGrow: 1}}>
                <DataGridPro
                    apiRef={apiRef}
                    experimentalFeatures={{newEditingApi: true}}
                    localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                    rows={rest.data}
                    columns={columns}
                    autoHeight
                    density={"comfortable"}
                    page={gridPage}
                    pageSize={gridPageSize}
                    pageSizeOptions={options.pageSizeOptions}
                    sx={{
                        '& .MuiDataGrid-row--editing': {
                            boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px'
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-row--editing .MuiDataGrid-cell--editable': {
                            backgroundColor: 'rgba(25, 118, 210, 0.08) !important;'
                        },
                        '&.MuiDataGrid-root': {
                            borderTop: 0,
                            borderRight: 0,
                            borderLeft: 0,
                        },
                        '& .MuiDataGrid-row': {cursor: 'pointer'},
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeaders': {
                            display: options.header === false ? 'none' : 'block'
                        },
                    }}
                    // https://github.com/mui/mui-x/issues/4897
                    headerHeight={options.header === false ? 0 : 56}
                    hideFooter={options.footer === false}
                    disableColumnFilter
                    components={options.header === false ? {} : {
                        Toolbar: CustomToolbar,
                    }}
                    onPageSizeChange={(pageSize) => {
                        setGridPageSize(pageSize);
                        saveState({pagination: {pageSize: pageSize}});

                        if (onPageSizeChange) {
                            onPageSizeChange(pageSize);
                        }
                    }}
                    onPageChange={(page) => {
                        setGridPage(page);
                        saveState({pagination: {page: page}});

                        if (onPageChange) {
                            onPageChange(page);
                        }
                    }}
                    onColumnVisibilityModelChange={(gridColumnVisibilityModel) => {
                        saveState({columns: {columnVisibilityModel: gridColumnVisibilityModel}});
                    }}
                    /*
                    onColumnWidthChange={() => {
                        saveState({});
                    }}
                    onColumnResize={() => {
                        saveState({});
                    }}
                    onPreferencePanelOpen={() => {
                        saveState({});
                    }}
                    onPreferencePanelClose={() => {
                        saveState({});
                    }}
                    //onMenuOpen={saveState}
                    //onMenuClose={saveState}
                    onPinnedColumnsChange={() => {
                        saveState({});
                    }}
                    */
                    onSortModelChange={(sortModel) => {
                        saveState({sorting: {sortModel: sortModel}});

                        if (onSortModelChange) {
                            onSortModelChange(sortModel);
                        }
                    }}
                    {...rest}
                    initialState={{
                        ...initialState,
                        ...settingInitialState,
                    }}
                />
            </div>
        </div>
    );
}

CustomDataGrid.propTypes = {
    options: PropTypes.object,
};

CustomDataGrid.defaultProps = {
    options: {},
};

export default CustomDataGrid;
