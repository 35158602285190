import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {AppBar, Box, Hidden, IconButton, Toolbar} from '@mui/material';
import {Menu as MenuIcon} from 'react-feather';
import Logo from 'src/components/Logo';
import Account from './Account';
import Notifications from './Notifications';

function TopBar({onMobileNavOpen, ...rest}) {
    return (
        <AppBar
            sx={{
                //zIndex: 'drawer' + 100,
                boxShadow: 'none',
                backgroundColor: 'primary.main'
            }}
            {...rest}
        >
            <Toolbar style={{
                minHeight: 64
            }}>
                <RouterLink to="/">
                    <Logo/>
                </RouterLink>
                <Box
                    ml={2}
                    flexGrow={1}
                />
                <Notifications/>
                <Box ml={2} mr={2}>
                    <Account/>
                </Box>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={onMobileNavOpen}
                        size="large">
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    onMobileNavOpen: PropTypes.func
};

export default TopBar;
