import React from 'react';
import PropTypes from "prop-types";
import {GlobalStyles} from "@mui/material";

function DangerouslyHtml({html, ...rest}) {
    return (
        <>
            <GlobalStyles styles={(theme) => ({
                p: {
                    color: theme.palette.text.secondary,
                    ...theme.typography.body2,
                    marginBottom: theme.spacing(2),
                    '&:last-child': {
                        marginBottom: 0
                    }
                },
                ul: {
                    margin: theme.spacing(2),
                    '&:last-child': {
                        marginBottom: 0
                    }
                },
                li: {
                    color: theme.palette.text.secondary,
                    ...theme.typography.body2
                },
                a: {
                    color: theme.palette.secondary.main,
                    textDecoration: 'underline',
                },
                strong: {
                    fontWeight: theme.typography.fontWeightMedium,
                },
            })}/>
            <div
                dangerouslySetInnerHTML={{__html: html}}
                {...rest}
            />
        </>
    );
}

DangerouslyHtml.propTypes = {
    html: PropTypes.string.isRequired
};

export default DangerouslyHtml;
