import React from 'react';
import {Box, Link, Typography} from '@mui/material';

function LegalNoteLinkList() {
    return (
        <>
            <Box m={1}>
                <Link
                    variant="body2"
                    color="textSecondary"
                    href="https://www.investolio.de/de/agb"
                    target="_blank"
                    underline="none"
                >
                    AGB
                </Link>
            </Box>
            <Box mt={1}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >|</Typography>
            </Box>
            <Box m={1}>
                <Link
                    variant="body2"
                    color="textSecondary"
                    href="https://www.investolio.de/de/datenschutz"
                    target="_blank"
                    underline="none"
                >
                    Datenschutz
                </Link>
            </Box>
            <Box mt={1}>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >|</Typography>
            </Box>
            <Box m={1}>
                <Link
                    variant="body2"
                    color="textSecondary"
                    href="https://www.investolio.de/de/impressum"
                    target="_blank"
                    underline="none"
                >
                    Impressum
                </Link>
            </Box>
        </>
    );
}

export default LegalNoteLinkList;
