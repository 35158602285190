import React from 'react';
import {Container} from '@mui/material';
import Page from 'src/components/Page';
import Header from './Header';
import DepotCreateForm from "./DepotCreateForm";

function DepotCreateView() {
    return (
        <Page
            sx={{
                backgroundColor: 'background.dark',
                minHeight: '100%',
            }}
            title="Depot anlegen"
        >
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 100
            }} maxWidth="lg">
                <Header/>
                <DepotCreateForm/>
            </Container>
        </Page>
    );
}

export default DepotCreateView;
