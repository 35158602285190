import React, {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {useNavigate} from 'react-router';
import {useDispatch} from 'react-redux';
import {useSnackbar} from 'notistack';
import {User as UserIcon} from 'react-feather';
import {Box, ButtonBase, Menu, MenuItem} from '@mui/material';
import {logout} from 'src/actions/accountActions';

function Account() {
    const navigate = useNavigate();
    const ref = useRef(null);
    const dispatch = useDispatch();
    //const account = useSelector((state) => state.account);
    const {enqueueSnackbar} = useSnackbar();
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleLogout = async () => {
        try {
            handleClose();
            await dispatch(logout());
            navigate('/');
        } catch (error) {
            enqueueSnackbar('Unable to logout', {
                variant: 'error'
            });
        }
    };

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                component={ButtonBase}
                onClick={handleOpen}
                ref={ref}
            >
                <UserIcon/>
            </Box>
            <Menu
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                PaperProps={{
                    style: {
                        width: 200,
                    }
                }}
                getContentAnchorEl={null}
                anchorEl={ref.current}
                open={isOpen}
            >
                <MenuItem onClick={handleClose} component={RouterLink} to="/account/general">
                    Benutzerkonto
                </MenuItem>
                {
                    false && <MenuItem onClick={handleClose} component={RouterLink} to="/settings"> Einstellungen</MenuItem>
                }
                <MenuItem onClick={handleLogout}>
                    Abmelden
                </MenuItem>
            </Menu>
        </>
    );
}

export default Account;
