import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import numeral from "src/mixins/numeral";

function Investitionsquote({value}) {
    return (
        <Box display="flex" alignItems="center" flexWrap="wrap">
            <Typography
                variant="h3"
                color="inherit"
            >
                {numeral(value).format('(0.0 %)')}
            </Typography>
            <LinearProgress
                sx={{
                    my: 0,
                    mx: 1,
                    flexGrow: 1,
                    backgroundColor: 'common.white',
                    "& .MuiLinearProgress-bar": {
                        backgroundColor: '#8cb3dc'
                    }
                }}
                value={value * 100}
                variant="determinate"
            />
        </Box>
    );
}

Investitionsquote.propTypes = {
    value: PropTypes.number,
};

export default Investitionsquote;
