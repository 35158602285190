import React from 'react';
import {Box, Container} from '@mui/material';
import Header from "./Header";
import Page from "src/components/Page";
import Notifications from "./Notifications";
import {getTitle} from "src/utils/meta";

function SettingsView({...rest}) {
    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle("Einstellungen")}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth="lg">
                <Header/>
                <Box mt={3}>
                    <Notifications/>
                </Box>
            </Container>
        </Page>
    );
}

SettingsView.propTypes = {};

export default SettingsView;
