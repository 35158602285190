import React, {forwardRef, useEffect, useCallback} from 'react';
import {Helmet} from 'react-helmet';
import {useLocation} from 'react-router';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import config from "src/config";

const Page = forwardRef(({title, children, ...rest}, ref) => {
  const location = useLocation();

  const sendPageViewEvent = useCallback(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize(config.GA_MEASUREMENT_ID, {
        testMode: config.STAGE === 'prod' ? false : true,
        debug: config.STAGE === 'prod' ? false : true,
      });
      window.GA_INITIALIZED = true;
    }

    if (window.GA_INITIALIZED) {
      ReactGA.set({anonymizeIp: true});
      ReactGA.set({page: location.pathname});
      ReactGA.pageview(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener('CookiebotOnAccept', function (e) {
      if (window.Cookiebot.consent.marketing) {
        sendPageViewEvent();
      }
    }, false);
  }, [sendPageViewEvent]);

  return (
    <div
      ref={ref}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default Page;
