import React, {useEffect, useState, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {
    alpha,
    Box,
    useTheme
} from '@mui/material';
import {Line} from 'react-chartjs-2';
import DownsamplePlugin from '../../mixins/chartjs-plugin-downsample-date';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import axios from '../../utils/axios';
import LinearProgress from "@mui/material/LinearProgress";

function Chart({isin, indexKurz, datum, ...rest}) {
    const theme = useTheme();
    const isMountedRef = useIsMountedRef();

    const [loading, setLoading] = useState(true);
    const [dataKurs, setDataKurs] = useState([]);

    const chartReference = useRef(null);

    const getChartData = useCallback(() => {
        setLoading(true);
        axios
            .get('/api/invest/aktie/scores', {
                params: {
                    ISIN: isin,
                    Index_kurz: indexKurz,
                    datum: datum,
                    timespan: "1Y"
                }
            }).then((response) => {
            if (isMountedRef.current) {
                const restructuredData = {
                    'Kurs': [],
                };

                if (response.data.payload.scores.length > 0) {
                    for (const score of response.data.payload.scores) {
                        restructuredData['Kurs'].push({
                            x: score.datum,
                            y: score.Kurs
                        });
                    }

                    setDataKurs(restructuredData['Kurs']);
                    setLoading(false);
                }
            }
        });
    }, [isMountedRef, isin, indexKurz, datum]);

    useEffect(() => {
        getChartData();
    }, [getChartData]);

    const lineChartOptions = {
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                type: 'time',
                distribution: 'series',
                offset: false,
                ticks: {
                    display: false
                },
                gridLines: {
                    display: false,
                    drawBorder: false,
                    tickMarkLength: 0,
                },
            }],
            yAxes: [{
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider,
                    tickMarkLength: 0,
                },
                ticks: {
                    display: false,
                },
            }]
        },
        tooltips: {
            enabled: false
        },
        legend: {
            display: false
        },
        downsample: {
            enabled: false,
            // max number of points to display per dataset
            threshold: 52,
        },
        plugins: {
            datalabels: {
                display: false
            },
        }
    };

    const reloadChart = () => {
        return {
            datasets: [{
                label: 'Aktienkurs',
                fill: true,
                borderWidth: 2,
                lineTension: 0.1,
                backgroundColor: alpha(theme.palette.secondary.main, 0.25),
                borderColor: theme.palette.secondary.main,
                pointBackgroundColor: 'rgb(13, 71, 161)',
                pointHoverRadius: 5,
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 2,
                data: dataKurs
            }]
        };
    };

    return (
        <>
            {loading && <LinearProgress/>}
            <Box
                height={{xs: 100, md: 125}} {...rest}>
                <Line ref={chartReference} redraw data={reloadChart} options={lineChartOptions} plugins={[DownsamplePlugin]}/>
            </Box>
        </>
    );
}

Chart.propTypes = {
    isin: PropTypes.string.isRequired,
    indexKurz: PropTypes.string.isRequired,
    datum: PropTypes.string.isRequired,
};

export default Chart;
