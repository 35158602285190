import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, Divider} from '@mui/material';
import ScoreLabel from "src/components/ScoreLabel";
import CustomDataGrid from "src/components/CustomDataGrid";
import {useNavigate} from "react-router";
import numeral from "src/mixins/numeral";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {OpenInNew} from "@mui/icons-material";
import {useGridApiRef} from "@mui/x-data-grid-pro";

function RankingStockResults({onSearch, apiRef, manualSearchCountForUpdating, ...rest}) {
    const navigate = useNavigate();
    const [rankings, setRankings] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [queryOptions, setQueryOptions] = React.useState({
        page: null,
        sortModel: [{field: 'Aktienname', sort: 'asc'}],
        pageSize: null
    });

    const updateQueryOptions = (k, v) => setQueryOptions((prev) => ({...prev, [k]: v}));

    useEffect(() => {
        setLoading(true);

        onSearch(queryOptions).then(function (result) {
            setRankings(result.data);
            setRowCount(result.totalCount);
            setLoading(false);
        }, err => {
            console.error(err);
            setLoading(false);
        });
    }, [apiRef.current, queryOptions, manualSearchCountForUpdating]);

    return (
        <Card {...rest}>
            <CardHeader title="Suchergebnis"/>
            <Divider/>
            <CustomDataGrid
                columns={[
                    {
                        headerName: "Name",
                        field: "Aktienname",
                        minWidth: 250,
                        flex: 1,
                    },
                    {
                        headerName: "ISIN",
                        field: "ISIN",
                        width: 220,
                    },
                    {
                        headerName: "Sektor",
                        field: "Sector:bez",
                        width: 220,
                        sortable: false
                    },
                    {
                        headerName: "Index",
                        field: "Index_kurz:bez",
                        width: 160,
                        sortable: false
                    },
                    {
                        headerName: "Kurs",
                        field: "Kurs",
                        type: "number",
                        width: 120,
                        renderCell: params => numeral(params.row.Kurs).format('0.00') + " " + params.row["Currency:symbol"]
                    },
                    {
                        headerName: "Trend",
                        field: "Trend_Score",
                        type: "number",
                        width: 85,
                        renderCell: params => <ScoreLabel key="trendScore" name="trendScore" value={params.row.Trend_Score}/>
                    },
                    {
                        headerName: "Safety",
                        field: "Risiko_Score",
                        type: "number",
                        width: 85,
                        renderCell: params => <ScoreLabel key="risikoScore" name="risikoScore" value={params.row.Risiko_Score}/>
                    },
                    {
                        headerName: "Tech",
                        field: "Technisch_Score",
                        type: "number",
                        width: 85,
                        renderCell: params => <ScoreLabel key="technischerScore" name="technischerScore" value={params.row.Technisch_Score}/>
                    },
                    {
                        headerName: "Value",
                        field: "Value_Score",
                        type: "number",
                        width: 85,
                        renderCell: params => <ScoreLabel key="valueScore" name="valueScore" value={params.row.Value_Score}/>
                    },
                    {
                        headerName: "Kombi",
                        field: "Kombi_Score",
                        type: "number",
                        width: 85,
                        renderCell: params => <ScoreLabel key="kombiScore" name="kombiScore" value={params.row.Kombi_Score}/>
                    },
                    {
                        headerName: "Marktkapitalisierung",
                        field: "MarketCap",
                        type: "number",
                        width: 175,
                        renderCell: params => numeral(params.row.MarketCap).format('(0,0)') + " Mio. €"
                    },
                    {
                        headerName: "Handelsvolumen",
                        field: "Handelsvolumen",
                        type: "number",
                        width: 175,
                        renderCell: params => numeral(params.row.Handelsvolumen).format('(0,0)') + " Tsd. €"
                    },
                    {
                        field: 'actions',
                        type: 'actions',
                        width: 100,
                        getActions: (params) => {
                            return [
                                <GridActionsCellItem
                                    icon={<OpenInNew/>}
                                    label="Aktie öffnen"
                                    onClick={(event) => {
                                        window.open(`/stocks/search/${params.row.Index_kurz}/${params.row.ISIN}`, "_blank")
                                        //navigate(`/stocks/search/${params.row.Index_kurz}/${params.row.ISIN}`);
                                    }}
                                />
                            ];
                        }
                    },
                ]}
                options={{
                    footer: true,
                }}
                pageSize={queryOptions.pageSize}
                page={queryOptions.page}
                configSettingKey="ranking_detail"
                onRowClick={(params) => {
                    navigate(`/stocks/search/${params.row.Index_kurz}/${params.row.ISIN}`);
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            "ISIN": false,
                        },
                    },
                    sorting: {
                        sortModel: queryOptions.sortModel
                    },
                }}
                apiRef={apiRef}
                rowCount={rowCount}
                rows={rankings}
                pagination
                loading={loading}
                paginationMode="server"
                onPageChange={(page) => {
                    updateQueryOptions('page', page);
                }}
                onPageSizeChange={(pageSize) => {
                    updateQueryOptions('pageSize', pageSize);
                }}
                sortingMode="server"
                onSortModelChange={(sortModel) => {
                    updateQueryOptions('sortModel', sortModel);
                }}
            />
        </Card>
    );
}

RankingStockResults.propTypes = {
    onSearch: PropTypes.func
};

export default RankingStockResults;
