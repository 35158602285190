import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Card,
    Container, Link
} from '@mui/material';
import Page from 'src/components/Page';
import Header from './Header';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import LoadingScreen from "src/components/LoadingScreen";
import ScoreLabel from "src/components/ScoreLabel";
import {useSnackbar} from "notistack";
import {getTitle} from "src/utils/meta";
import CustomDataGrid from "src/components/CustomDataGrid";
import {useNavigate} from "react-router";
import {GridActionsCellItem, GridRowModes} from "@mui/x-data-grid";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {useGridApiRef} from "@mui/x-data-grid-pro";

function DepotListView() {
    const apiRef = useGridApiRef();
    const isMountedRef = useIsMountedRef();
    const [depots, setDepots] = useState(null);
    const [rowModesModel, setRowModesModel] = useState({});
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const getDepots = useCallback(() => {
        axios
            .get('/api/user/depots', {
                params: {
                    offset: 0,
                    limit: 30
                }
            })
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("depots")) {
                        setDepots(response.data.payload.depots);
                    }
                }
            });
    }, [isMountedRef]);


    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id) => () => {
        axios.delete('/api/user/depots/' + id)
            .then((response) => {
                if (!response.data.payload.hasOwnProperty("count") || response.data.payload.count === 0) {
                    throw new Error(response.data.errorMessage)
                } else {
                    setDepots((prevDepots) => prevDepots.filter((depot) => depot.id !== id));
                    enqueueSnackbar('Depot gelöscht', {
                        variant: 'success'
                    });
                }
            }).catch((error) => {
            enqueueSnackbar('Depot konnte nicht gelöscht werden: ' + error, {
                variant: 'error'
            });
        })
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
    };

    const processRowUpdate = useCallback(
        (newRow, oldRow) =>
            new Promise(async (resolve, reject) => {

                axios.put('/api/user/depots/' + newRow.id, {
                    "bezeichnung": newRow["bezeichnung"],
                }).then((response) => {
                    if (!response.data.payload.hasOwnProperty("count")) {
                        resolve(oldRow);
                        enqueueSnackbar('Depot "' + oldRow.bezeichnung + '" konnte nicht aktualisiert werden', {
                            variant: 'error'
                        });
                    } else {
                        return axios
                            .get('/api/user/depots', {
                                params: {
                                    offset: 0,
                                    limit: 30
                                }
                            })
                    }
                }).then((response) => {
                    if (response.data.payload.hasOwnProperty('depots')) {
                        resolve(newRow);
                        setDepots(response.data.payload.depots);
                        enqueueSnackbar('Depot "' + newRow.bezeichnung + '" aktualisiert', {
                            variant: 'success'
                        });
                    }
                })
            }),
        [],
    );

    useEffect(() => {
        getDepots();
    }, [getDepots]);

    if (!depots) {
        return <LoadingScreen/>;
    }

    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle("Depots")}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth={false}>
                <Header/>
                <Box mt={3}>
                    <Card>
                        <CustomDataGrid
                            apiRef={apiRef}
                            columns={[{
                                headerName: "Name",
                                field: "bezeichnung",
                                defaultSort: 'asc',
                                flex: 1,
                                editable: true,
                            }, {
                                headerName: "Aktien",
                                field: "depot_aktie_count",
                                type: "number",
                            }, {
                                headerName: "Trend",
                                field: "Trend_Score_AVG",
                                type: "number",
                                width: 90,
                                renderCell: (params) => (
                                    <ScoreLabel key="trendScore" name="trendScore" value={params.value}/>
                                ),
                            }, {
                                headerName: "Safety",
                                field: "Risiko_Score_AVG",
                                type: "number",
                                width: 100,
                                renderCell: (params) => (
                                    <ScoreLabel key="risikoScore" name="risikoScore" value={params.value}/>
                                ),
                            }, {
                                headerName: "Tech",
                                field: "Technisch_Score_AVG",
                                type: "number",
                                width: 90,
                                renderCell: (params) => (
                                    <ScoreLabel key="technischerScore" name="technischerScore" value={params.value}/>
                                ),
                            }, {
                                headerName: "Value",
                                field: "Value_Score_AVG",
                                type: "number",
                                width: 90,
                                renderCell: (params) => (
                                    <ScoreLabel key="valueScore" name="valueScore" value={params.value}/>
                                ),
                            }, {
                                headerName: "Kombi",
                                field: "Kombi_Score_AVG",
                                type: "number",
                                width: 90,
                                renderCell: (params) => (
                                    <ScoreLabel key="kombiScore" name="kombiScore" value={params.value}/>
                                ),
                            }, {
                                field: 'actions',
                                type: 'actions',
                                width: 100,
                                getActions: ({id}) => {
                                    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                                    if (isInEditMode) {
                                        return [
                                            <GridActionsCellItem
                                                icon={<SaveIcon/>}
                                                label="Save"
                                                onClick={handleSaveClick(id)}
                                            />,
                                            <GridActionsCellItem
                                                icon={<CancelIcon/>}
                                                label="Cancel"
                                                className="textPrimary"
                                                onClick={handleCancelClick(id)}
                                                color="inherit"
                                            />,
                                        ];
                                    }

                                    return [
                                        <GridActionsCellItem
                                            icon={<EditIcon/>}
                                            label="Edit"
                                            onClick={handleEditClick(id)}
                                        />,
                                        <GridActionsCellItem
                                            icon={<DeleteIcon/>}
                                            label="Delete"
                                            onClick={handleDeleteClick(id)}
                                        />,
                                    ];
                                }
                            }
                            ]}
                            apiRef={apiRef}
                            configSettingKey="depot"
                            data={depots}
                            initialState={{
                                sorting: {
                                    sortModel: [{field: 'bezeichnung', sort: 'asc'}],
                                },
                            }}
                            onRowClick={(params) => navigate(`/depot/${params.row.id}`)}
                            editMode="row"
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                            processRowUpdate={processRowUpdate}
                        />
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default DepotListView;
