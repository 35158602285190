import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Box, Card, CardContent, Container, Divider, Link, Typography} from '@mui/material';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import LegalNoteLinkList from "src/components/LegalNoteLinkList";
import PasswordRequestForm from './PasswordRequestForm';

function PasswordRequestView() {
    const [success, setSuccess] = useState(null);
    const handleSubmitSuccess = () => {
        setSuccess(true);
    };

    return (
        <Page
            title="Passwort vergessen"
            style={{
                justifyContent: 'center',
                display: 'flex',
                height: '100%',
                minHeight: '100vh',
                flexDirection: 'column',
                paddingBottom: 80,
                paddingTop: 80
            }}
        >
            <Container maxWidth="sm">
                <Box
                    mb={5}
                    display="flex"
                    alignItems="center"
                >
                    <RouterLink to="/">
                        <Logo invert/>
                    </RouterLink>
                </Box>
                <Card>
                    <CardContent>
                        {
                            success === true &&
                            <>
                                <Typography
                                    gutterBottom
                                    variant="h2"
                                    color="textPrimary"
                                >
                                    Passwort vergessen?
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Wir haben Ihnen per E-Mail einen Link zum Zurücksetzen Ihres Passworts geschickt.
                                </Typography>
                                <Box mt={3}>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Wenn Sie auf den Link in der E-Mail klicken, werden Sie auf eine Seite weitergeleitet, auf der Sie Ihr neues Passwort
                                        setzen können.
                                    </Typography>
                                </Box>
                                <Box my={2}>
                                    <Divider/>
                                </Box>
                                <Link
                                    component={RouterLink}
                                    to="/login"
                                    variant="body2"
                                    color="textSecondary"
                                    underline="none"
                                >
                                    Zur Anmeldung
                                </Link>
                            </>
                        }
                        {
                            !success &&
                            <>
                                <Typography
                                    gutterBottom
                                    variant="h2"
                                    color="textPrimary"
                                >
                                    Passwort vergessen?
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Bitte geben Sie Ihre E-Mail-Adresse an, um einen Link zum Zurücksetzen Ihres Passworts anzufordern.
                                </Typography>
                                <Box mt={3}>
                                    <PasswordRequestForm onSubmitSuccess={handleSubmitSuccess}/>
                                </Box>
                                <Box my={2}>
                                    <Divider/>
                                </Box>
                                <Link
                                    component={RouterLink}
                                    to="/login"
                                    variant="body2"
                                    color="textSecondary"
                                    underline="none"
                                >
                                    Zur Anmeldung
                                </Link>
                            </>
                        }
                    </CardContent>
                </Card>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LegalNoteLinkList/>
                </Box>
            </Container>
        </Page>
    );
}

export default PasswordRequestView;
