/* eslint-disable react/no-array-index-key */
import React, {
    Suspense,
    Fragment
} from 'react';
import {
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import RightsGuard from 'src/components/RightsGuard';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import FaqListView from "src/views/help/FaqListView";
import Error404View from "src/views/pages/Error404View";
import LoginView from "src/views/auth/LoginView";
import RegisterView from "src/views/auth/RegisterView";
import PasswordRequestView from "src/views/auth/PasswordRequestView";
import PasswordResetView from "src/views/auth/PasswordResetView";
import DashboardView from "src/views/reports/DashboardView";
import SearchView from "src/views/stocks/SearchView";
import RankingListView from "src/views/stocks/RankingListView";
import RankingDetailView from "src/views/stocks/RankingDetailView";
import CompareView from "src/views/stocks/CompareView";
import DepotListView from "src/views/depot/DepotListView";
import DepotCreateView from "src/views/depot/DepotCreateView";
import DepotDetailView from "src/views/depot/DepotDetailView";
import WatchListView from "src/views/depot/WatchListView";
import WatchlistCreateView from "src/views/depot/WatchlistCreateView";
import WatchlistDetailView from "src/views/depot/WatchlistDetailView";
import InvestmentStrategyListView from "src/views/investment/InvestmentStrategyListView";
import InvestmentStrategyDetailView from "src/views/investment/InvestmentStrategyDetailView";
import InvestmentRateView from "src/views/investment/InvestmentRateView";
import AccountView from "src/views/account/AccountView";
import SettingsView from "src/views/account/SettingsView";
import VideoCourseListView from "src/views/help/VideoCourseListView";
import VideoCourseDetailView from "src/views/help/VideoCourseDetailView";
import NewsListView from "src/views/news/NewsListView";
import NewsDetailView from "src/views/news/NewsDetailView";
import Verify from "./views/auth/RegisterView/Verify";
import {Box} from "@mui/material";

const routesConfig = [
    {
        path: '*',
        component: () => <Error404View/>
    },
    {
        path: '/login',
        component: () => <LoginView/>
    },
    {
        path: '/login/:message',
        component: () => <LoginView/>
    },
    {
        path: '/register',
        component: () => <RegisterView/>
    },
    {
        //path: '/register/verify/:email(.+\\@.+\\..+)/:token([a-zA-Z0-9]{32})',
        path: '/register/verify/:email/:token',
        component: () => <RegisterView/>
    },
    {
        path: '/register/requestpassword',
        component: () => <PasswordRequestView/>
    },
    {
        //path: '/register/resetpassword/:email(.+\\@.+\\..+)/:token([a-zA-Z0-9]{32})',
        path: '/register/resetpassword/:email/:token',
        component: () => <PasswordResetView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/',
        component: () => <DashboardView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/stocks/search/:paramIndexKurz/:paramIsin',
        component: () => <SearchView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        //path: '/stocks/search/:paramIndexKurz([a-zA-Z0-9]{1,20})/:paramIsin([a-zA-Z0-9]{12})/:paramDatum(\\d\\d\\d\\d-\\d\\d-\\d\\d)?',
        path: '/stocks/search/:paramIndexKurz/:paramIsin/:paramDatum',
        component: () => <SearchView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/stocks',
        component: () => <Navigate to="/stocks/search"/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/stocks/search',
        component: () => <SearchView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/stocks/rankings',
        component: () => <RankingListView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/stocks/rankings/add',
        component: () => <RankingDetailView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        //path: '/stocks/rankings/:id([0-9].*?)',
        path: '/stocks/rankings/:id',
        component: () => <RankingDetailView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/stocks/compare',
        component: () => <CompareView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/depot',
        component: () => <DepotListView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/depot/create',
        component: () => <DepotCreateView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        //path: '/depot/:id([0-9].*)',
        path: '/depot/:id',
        component: () => <DepotDetailView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/depot/watchlist',
        component: () => <WatchListView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/depot/watchlist/create',
        component: () => <WatchlistCreateView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        //path: '/depot/watchlist/:id([0-9].*)',
        path: '/depot/watchlist/:id',
        component: () => <WatchlistDetailView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/investment',
        component: () => <Navigate to="/investment/strategy"/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/investment/strategy',
        component: () => <InvestmentStrategyListView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        //path: '/investment/strategy/:paramIndex([0-9].*)',
        path: '/investment/strategy/:paramIndex',
        component: () => <InvestmentStrategyDetailView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/investment/rate',
        component: () => <InvestmentRateView/>
    },
    {
        layout: DashboardLayout,
        guard: AuthGuard,
        path: '/account',
        component: () => <Navigate to="/account/general"/>
    },
    {
        layout: DashboardLayout,
        guard: AuthGuard,
        path: '/account/:paramCurrentTab',
        component: () => <AccountView/>
    },
    {
        layout: DashboardLayout,
        guard: AuthGuard,
        //path: '/account/:paramCurrentTab?/:message?',
        path: '/account/:paramCurrentTab/:message',
        component: () => <AccountView/>
    },
    {
        layout: DashboardLayout,
        guard: AuthGuard,
        path: '/settings',
        component: () => <SettingsView/>
    },
    {
        layout: DashboardLayout,
        guard: AuthGuard,
        path: '/help',
        component: () => <Navigate to="/help/faq"/>
    },
    {
        layout: DashboardLayout,
        guard: AuthGuard,
        path: '/help/quick-start',
        component: () => <QuickStartListView/>
    },
    {
        layout: DashboardLayout,
        guard: AuthGuard,
        path: '/help/faq',
        component: () => <FaqListView/>
    },
    {
        layout: DashboardLayout,
        guard: AuthGuard,
        path: '/help/video-course',
        component: () => <VideoCourseListView/>
    },
    {
        layout: DashboardLayout,
        guard: AuthGuard,
        //path: '/help/video-course/:paramId([0-9].*)',
        path: '/help/video-course/:paramId',
        component: () => <VideoCourseDetailView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        path: '/news',
        component: () => <NewsListView/>
    },
    {
        layout: DashboardLayout,
        guard: RightsGuard,
        //path: '/news/:paramId([0-9].*)',
        path: '/news/:paramId',
        component: () => <NewsDetailView/>
    },
];


const renderRoutes = (routes) => (routes ? (
    <Suspense fallback={<LoadingScreen/>}>
        <Routes>
            {routes.map((route, i) => {
                const Guard = route.guard || GuestGuard;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        element={
                            <Guard>
                                <Layout>
                                    {route.routes ? renderRoutes(route.routes) : <Component/>}
                                </Layout>
                            </Guard>
                        }
                    />
                );
            })}
        </Routes>
    </Suspense>
) : null);

function AppRoutes() {
    return renderRoutes(routesConfig);
}

export default AppRoutes;
