import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    Box,
    Card,
    CardMedia,
    Link,
    Typography
} from '@mui/material';

function NewsCard({news, ...rest}) {
    return (
        <Link underline="none" component={RouterLink} to={`/news/${news.id}`} {...rest}>
            <Card>
                <Box p={3} pb={2}>
                    <CardMedia style={{
                        height: 0,
                        paddingTop: '56.25%', // 16:9
                        backgroundColor: 'background.dark'
                    }} image={news.bild_url}/>
                    <Box display="flex" alignItems="center" mt={2}>
                        <Box>
                            <Typography color="textPrimary" variant="h5">
                                {news.titel}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {moment.unix(news.veroeffentlichungsdatum_timestamp).format("L")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box pb={2} px={3}>
                    <Typography color="textSecondary" variant="body2">
                        {news.teasertext}
                    </Typography>
                </Box>
            </Card>
        </Link>
    );
}

NewsCard.propTypes = {
    news: PropTypes.object.isRequired
};

export default NewsCard;
