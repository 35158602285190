import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, alpha, useTheme, Box} from '@mui/material';
import moment from 'moment';
import {Line} from 'react-chartjs-2';
import DownsamplePlugin from 'src/mixins/chartjs-plugin-downsample-date';
import Divider from "@mui/material/Divider";
import numeral from "src/mixins/numeral";

function Chart({dataDepot, dataBenchmark, ...rest}) {
    const theme = useTheme();

    const [dataDepotHidden, setDataDepotHidden] = useState(false);
    const [dataBenchmarkHidden, setDataBenchmarkHidden] = useState(false);

    const chartReference = useRef(null);

    const getChartData = () => {
        return {
            datasets: [{
                label: 'Depot',
                fill: true,
                borderWidth: 1,
                lineTension: 0.1,
                backgroundColor: alpha(theme.palette.secondary.main, 0.25),
                borderColor: theme.palette.secondary.main,
                pointBackgroundColor: 'rgb(13, 71, 161)',
                pointHoverRadius: 2,
                               pointRadius: 0,
                               pointHitRadius: 2,
                data: dataDepot,
                hidden: dataDepotHidden,
            }, {
                label: 'Benchmark',
                fill: true,
                borderWidth: 1,
                lineTension: 0.1,
                backgroundColor: 'rgba(249, 168, 37, 0.4)',
                borderColor: 'rgb(249, 168, 37)',
                pointBackgroundColor: 'rgb(249, 168, 37)',
                pointHoverRadius: 2,
                pointRadius: 0,
                pointHitRadius: 2,
                data: dataBenchmark,
                hidden: dataBenchmarkHidden,
            }]
        };
    };

    const lineChartOptions = {
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                type: 'time',
                distribution: 'series',
                offset: true,
                ticks: {
                    source: 'data',
                    autoSkip: true,
                    autoSkipPadding: 50,
                    time: {
                        unitStepSize: 10
                    },
                    maxRotation: 0,
                    fontColor: theme.palette.text.secondary,
                    padding: 20,
                },
                gridLines: {
                    display: false,
                    drawBorder: false
                },
            }],
            yAxes: [{
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider
                },
                scaleLabel: {
                    display: true,
                },
                ticks: {
                    autoSkip: true,
                    autoSkipPadding: 50,
                    fontColor: theme.palette.text.secondary,
                    padding: 20,
                    callback: (value) => numeral(value).format('0,0 $')
                },
            }]
        },
        tooltips: {
            mode: 'single',
            enabled: true,
            intersect: false,
            caretSize: 10,
            yPadding: 20,
            xPadding: 20,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.background.default,
            titleFontColor: theme.palette.text.primary,
            bodyFontColor: theme.palette.text.secondary,
            footerFontColor: theme.palette.text.secondary,
            callbacks: {
                title: function (tooltipItems, data) {
                    if (tooltipItems[0]) {
                        return moment(tooltipItems[0].xLabel).format('DD.MM.YYYY');
                    }
                },
                label: function (tooltipItem, data) {
                    return numeral(tooltipItem.value).format('0,0 $');
                },
            }
        },
        legend: {
            position: 'bottom',
            labels: {
                padding: 20
            },
            onClick: function (e, legendItem) {
                switch (legendItem.datasetIndex) {
                    case 0:
                        setDataDepotHidden(!legendItem.hidden);
                        break;
                    case 1:
                        setDataBenchmarkHidden(!legendItem.hidden);
                        break;

                    default:
                        break;
                }
            },
            onHover: function (e) {
                e.target.style.cursor = 'pointer';
            },
        },
        downsample: {
            enabled: true,
            // max number of points to display per dataset
            threshold: 100,
        },
        plugins: {
            datalabels: {
                display: false
            },
        }
    };

    return (
        <Card {...rest}>
            <CardHeader title="Performance Chart"/>
            <Divider/>
            <Box sx={{
                paddingTop: 3,
                paddingBottom: 2,
                height: 300,
            }}>
                <Line ref={chartReference} redraw data={getChartData} options={lineChartOptions} plugins={[DownsamplePlugin]}/>
            </Box>
        </Card>
    );
};

Chart.propTypes = {
    dataDepot: PropTypes.array.isRequired,
    dataBenchmark: PropTypes.array.isRequired,
};

export default Chart;
