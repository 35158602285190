import React from 'react';
import PropTypes from 'prop-types';
import Yup from 'src/mixins/yup';
import {Formik} from 'formik';
import {useSnackbar} from 'notistack';
import {
    Box,
    Typography,
    TextField,
    Button,
    Divider,
    FormHelperText
} from '@mui/material';
import axios from "src/utils/axios";
import SpinnerButton from "src/components/SpinnerButton";
import numeral from "src/mixins/numeral";

function AddEditRankingForm({ranking, rankingFilterOptions, onCancel, onSubmit, ...rest}) {
    const {enqueueSnackbar} = useSnackbar();

    return (
        <Formik
            initialValues={{
                name: ranking && ranking.bezeichnung ? ranking.bezeichnung : '',
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().min(3).max(100).required(),
            })}
            onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                let data = {
                    "bezeichnung": values.name,
                    "filteroptions": {}
                };

                Object.keys(rankingFilterOptions).forEach(key => {
                    if (rankingFilterOptions[key] && rankingFilterOptions[key] !== "") {
                        switch (key) {
                            case 'Handelsvolumen_min':
                            case 'Handelsvolumen_max':
                            case 'MarketCap_min':
                            case 'MarketCap_max':
                                data["filteroptions"][key] = numeral(rankingFilterOptions[key]).value();
                                break;

                            default:
                                data["filteroptions"][key] = rankingFilterOptions[key];
                                break;
                        }
                    }
                });

                // Edit
                if (ranking && ranking.id) {
                    await axios.put('/api/filter/filters/' + ranking.id, data).then((response) => {
                        resetForm();
                        setStatus({success: true});
                        setSubmitting(false);
                        enqueueSnackbar('Ranking gespeichert', {
                            variant: 'success'
                        });
                        onSubmit({...ranking, ...data});
                    }).catch((error) => {
                        let myerror = (error.response && error.response.data.message) || 'Es ist ein unerwarteter Fehler aufgetreten.';

                        setStatus({success: false});
                        setErrors({submit: myerror});
                        setSubmitting(false);
                    });
                }
                // Add
                else {
                    await axios.post('/api/filter/filters', data).then((response) => {
                        if (response.data.payload.hasOwnProperty("filter_id")) {
                            data["id"] = response.data.payload.filter_id;

                            resetForm();
                            setStatus({success: true});
                            setSubmitting(false);
                            enqueueSnackbar('Ranking gespeichert', {
                                variant: 'success'
                            });
                            onSubmit(data);
                        }
                    }).catch((error) => {
                        error = (error.response && error.response.data.message) || 'Es ist ein unerwarteter Fehler aufgetreten.';

                        setStatus({success: false});
                        setErrors({submit: error});
                        setSubmitting(false);
                    });
                }
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldTouched,
                  setFieldValue,
                  touched,
                  values
              }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...rest}
                >
                    <Box p={3} pb={0}>
                        <Typography
                            align="center"
                            gutterBottom
                            variant="h3"
                            color="textPrimary"
                        >Ranking speichern</Typography>
                    </Box>
                    <Box p={3}>
                        <Box mt={2}>
                            <TextField
                                autoFocus
                                error={Boolean(touched.name && errors.name)}
                                fullWidth
                                helperText={touched.name && errors.name}
                                label="Name"
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                variant="outlined"
                            />
                        </Box>
                    </Box>
                    <Divider/>
                    <Box
                        p={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Box flexGrow={1}/>
                        <Button onClick={onCancel}>
                            Abbrechen
                        </Button>
                        <SpinnerButton isSubmitting={isSubmitting} sx={{
                            marginLeft: 2
                        }}>
                            Speichern
                        </SpinnerButton>
                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}
                    </Box>
                </form>
            )}
        </Formik>
    );
}

AddEditRankingForm.propTypes = {
    ranking: PropTypes.any,
    rankingFilterOptions: PropTypes.any,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
};

AddEditRankingForm.defaultProps = {
    onSubmit: () => {
    },
    onCancel: () => {
    },
};

export default AddEditRankingForm;
