import React from 'react';
import Yup from 'src/mixins/yup';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {
    Box,
    FormHelperText, Link
} from '@mui/material';
import SpinnerButton from "src/components/SpinnerButton";
import axios from 'src/utils/axios';
import PasswordTextFieldSwitcher from "src/components/PasswordTextFieldSwitcher";
import {Link as RouterLink} from "react-router-dom";

function PasswordResetForm({user, token, onSubmitSuccess, ...rest}) {
    return (
        <Formik
            initialValues={{
                password: '',
                passwordConfirmation: '',
            }}
            validationSchema={Yup.object().shape({
                password: Yup.string().min(8).max(50).required().matches(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    "Bitte geben Sie min. 1 Großbuchstabe (A-Z), 1 Kleinbuchstabe (a-z), 1 Zahl (0-9) und 1 Sonderzeichen (@$!%*#?&) ein."
                ),
                passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwörter müssen übereinstimmen')
            })}
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                await axios.get('/api/open/user/resetpassword', {
                    params: {
                        username: user,
                        password: values.password,
                        vt: token,
                    }
                }).then((response) => {
                    onSubmitSuccess();
                }).catch((error) => {
                    error = (error.response && error.response.data.message) || 'Es ist ein unerwarteter Fehler aufgetreten.';

                    setStatus({success: false});
                    setErrors({submit: error});
                    setSubmitting(false);
                });
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
              }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...rest}
                >
                    <PasswordTextFieldSwitcher
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Passwort"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.password}
                        variant="outlined"
                    />
                    <PasswordTextFieldSwitcher
                        error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                        fullWidth
                        helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                        label="Passwort bestätigen"
                        margin="normal"
                        name="passwordConfirmation"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.passwordConfirmation}
                        variant="outlined"
                    />
                    <Box mt={2}>
                        <SpinnerButton size="large" fullWidth isSubmitting={isSubmitting}>
                            Passwort ändern
                        </SpinnerButton>
                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>
                                    Ihr Passwort konnte nicht geändert werden. Bitte fordern Sie sich <Link component={RouterLink}
                                                                                                            to="/register/requestpassword"
                                                                                                            underline="none">hier</Link> einen neuen Link zum
                                    Zurücksetzen des Passworts an und probieren es anschließend erneut.
                                </FormHelperText>
                            </Box>
                        )}
                    </Box>
                </form>
            )}
        </Formik>
    );
}

PasswordResetForm.propTypes = {
    onSubmitSuccess: PropTypes.func
};

PasswordResetForm.default = {
    onSubmitSuccess: () => {
    }
};

export default PasswordResetForm;
