import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Box, Card, CardActionArea, CardMedia, Typography} from '@mui/material';
import DangerouslyHtml from "src/components/DangerouslyHtml";

function VideoCourseCard({videoCourse, ...rest}) {
    return (
        <CardActionArea component={RouterLink} to={`/help/video-course/${videoCourse.id}`}>
            <Card {...rest}>
                <Box px={3} pb={2} pt={3}>
                    <CardMedia
                        sx={{
                            height: 200,
                            backgroundColor: 'background.dark'
                        }}
                        image={videoCourse.bild_url}
                    />
                    <Box display="flex" alignItems="center" mt={2}>
                        <Typography color="textPrimary" variant="h5">
                            {videoCourse.titel}
                        </Typography>
                    </Box>
                </Box>
                <Box pb={2} px={3}>
                    <DangerouslyHtml html={videoCourse.beschreibung}/>
                </Box>
            </Card>
        </CardActionArea>
    );
}

VideoCourseCard.propTypes = {
    videoCourse: PropTypes.object.isRequired
};

export default VideoCourseCard;
