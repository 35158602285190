import React, {
    useRef,
    useState,
    useEffect
} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
    useDispatch,
    useSelector
} from 'react-redux';
import {
    Avatar,
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    SvgIcon,
    Tooltip,
    Typography
} from '@mui/material';
import {
    Bell as BellIcon,
    Package as PackageIcon,
    MessageCircle as MessageIcon,
    Truck as TruckIcon
} from 'react-feather';

const iconsMap = {
    order_placed: PackageIcon,
    new_message: MessageIcon,
    item_shipped: TruckIcon
};

function Notifications() {
    const notifications = useSelector((state) => state.notifications.notifications);
    const ref = useRef(null);
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        //dispatch(getNotifications());
    }, [dispatch]);

    return <>
        <Tooltip title="Benachrichtigungen">
            <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
                <SvgIcon>
                    <BellIcon/>
                </SvgIcon>
            </IconButton>
        </Tooltip>
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            PaperProps={{
                style: {
                    width: 320,
                }
            }}
            anchorEl={ref.current}
            onClose={handleClose}
            open={isOpen}
        >
            <Box p={2}>
                <Typography
                    variant="h5"
                    color="textPrimary"
                >
                    Beanchrichtigungen
                </Typography>
            </Box>
            {notifications.length === 0 ? (
                <Box p={2}>
                    <Typography color="textSecondary" variant="body2">
                        Keine Benachrichtigungen vorhanden
                    </Typography>
                </Box>
            ) : (
                <>
                    <List disablePadding>
                        {notifications.map((notification) => {
                            const Icon = iconsMap[notification.type];

                            return (
                                <ListItem
                                    component={RouterLink}
                                    divider
                                    key={notification.id}
                                    to="#"
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            sx={{
                                                backgroundColor: 'secondary.main',
                                                color: 'secondary.contrastText'
                                            }}
                                        >
                                            <SvgIcon fontSize="small">
                                                <Icon/>
                                            </SvgIcon>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={notification.title}
                                        primaryTypographyProps={{variant: 'subtitle2', color: 'textPrimary'}}
                                        secondary={notification.description}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                    <Box
                        p={1}
                        display="flex"
                        justifyContent="center"
                    >
                        <Button
                            component={RouterLink}
                            size="small"
                            to="#"
                        >
                            Mark all as read
                        </Button>
                    </Box>
                </>
            )}
        </Popover>
    </>;
}

export default Notifications;
