import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
    Typography,
    Breadcrumbs,
    Link
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Header({...rest}) {
    return (
        <div {...rest}>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small"/>}
                aria-label="breadcrumb"
            >
                <Link color="inherit" to="/account" underline="none" component={RouterLink}>
                    Verwaltung
                </Link>
                <Typography color="textPrimary">
                    Einstellungen
                </Typography>
            </Breadcrumbs>
            <Typography
                variant="h3"
                color="textPrimary"
            >
                Einstellungen
            </Typography>
        </div>
    );
}

Header.propTypes = {};

export default Header;
