import React from 'react';
import PropTypes from 'prop-types';
import Yup from 'src/mixins/yup';
import {Formik} from 'formik';
import {useSnackbar} from 'notistack';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    TextField,
    FormControl,
    InputLabel,
    Button,
    Divider,
    InputAdornment,
    FormHelperText
} from '@mui/material';
import axios from "src/utils/axios";
import SpinnerButton from "src/components/SpinnerButton";

function AddStockDepotForm({stock, depots, onCancel, onSubmit, ...rest}) {
    const {enqueueSnackbar} = useSnackbar();

    return (
        <Formik
            initialValues={{
                depot: '',
                price: stock.Kurs,
                num: ''
            }}
            validationSchema={Yup.object().shape({
                depot: Yup.number().required(),
                price: Yup.number().required(),
                num: Yup.number().required()
            })}
            onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                await axios.post('/api/user/depots/akties', {
                    "depot_id": values.depot,
                    "ISIN": stock.ISIN,
                    "Index_kurz": stock.Index_kurz,
                    "quantity": values.num,
                    "Kaufkurs": values.price
                }).then((response) => {
                    resetForm();
                    setStatus({success: true});
                    setSubmitting(false);
                    enqueueSnackbar('Aktie zum Depot hinzugefügt', {
                        variant: 'success'
                    });
                    onSubmit();
                }).catch((error) => {
                    error = (error.response && error.response.data.message) || 'Es ist ein unerwarteter Fehler aufgetreten.';

                    setStatus({success: false});
                    setErrors({submit: error});
                    setSubmitting(false);
                });
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldTouched,
                  setFieldValue,
                  touched,
                  values
              }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...rest}
                >
                    <Box p={3} pb={0}>
                        <Typography
                            align="center"
                            gutterBottom
                            variant="h3"
                            color="textPrimary"
                        >Aktie zum Depot hinzufügen</Typography>
                    </Box>
                    <Box p={3}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Depot</InputLabel>
                            <Select
                                error={Boolean(touched.depot && errors.depot)}
                                label="Depot"
                                name="depot"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                value={values.depot}
                            >
                                {depots.length === 0 && <MenuItem value="" disabled>Kein Depot vorhanden</MenuItem>}
                                {
                                    depots.length > 0 &&
                                    depots.map(depot => (
                                        <MenuItem key={depot.id} value={depot.id}>{depot.bezeichnung}</MenuItem>
                                    ))
                                }
                            </Select>
                            {Boolean(touched.depot && errors.depot) && (
                                <FormHelperText error>
                                    {errors.depot}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Box mt={2}>
                            <TextField
                                error={Boolean(touched.price && errors.price)}
                                fullWidth
                                helperText={touched.price && errors.price}
                                label="Kurs"
                                name="price"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.price}
                                variant="outlined"
                                type="number"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">{stock['Currency:symbol']}</InputAdornment>,
                                }}
                            />
                        </Box>
                        <Box mt={2}>
                            <TextField
                                error={Boolean(touched.num && errors.num)}
                                fullWidth
                                helperText={touched.num && errors.num}
                                label="Stück"
                                name="num"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.num}
                                variant="outlined"
                                type="number"
                            />
                        </Box>
                    </Box>
                    <Divider/>
                    <Box
                        p={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Box flexGrow={1}/>
                        <Button onClick={onCancel}>
                            Abbrechen
                        </Button>
                        <SpinnerButton isSubmitting={isSubmitting} sx={{
                            marginLeft: 2
                        }}>
                            Hinzufügen
                        </SpinnerButton>
                    </Box>
                    {errors.submit && (
                        <Box p={3}>
                            <FormHelperText error>
                                {errors.submit}
                            </FormHelperText>
                        </Box>
                    )}
                </form>
            )}
        </Formik>
    );
}

AddStockDepotForm.propTypes = {
    stock: PropTypes.object,
    depots: PropTypes.array,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
};

AddStockDepotForm.defaultProps = {
    onSubmit: () => {
    },
    onCancel: () => {
    },
};

export default AddStockDepotForm;
