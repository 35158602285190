import React from 'react';
import {
    Box,
    CircularProgress
} from '@mui/material';
import Logo from 'src/components/Logo';

function SlashScreen() {
    return (
        <Box sx={{
            alignItems: 'center',
            backgroundColor: 'background.default',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            padding: 3,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 2000
        }}>
            <Box
                display="flex"
                justifyContent="center"
                mb={4}
            >
                <Logo invert/>
            </Box>
            <CircularProgress/>
        </Box>
    );
}

export default SlashScreen;
