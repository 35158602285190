import React from 'react';
import PropTypes from 'prop-types';
import Box from "@mui/material/Box";

function getColorBySignal(signal) {
    let ret = {};

    switch (parseInt(signal)) {
        case 0:
            ret = {'color': '#bb273f', 'backgroundColor': '#ffefef'};
            break;

        case 5:
            ret = {'color': '#c3b924', 'backgroundColor': '#f8fbde'};
            break;

        case 10:
        default:
            ret = {'color': '#51ae2a', 'backgroundColor': '#e0f4db'};
            break;
    }

    return ret;
};

function SignalLabel({label, value, signal, ...rest}) {
    return (
        <Box sx={{
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: 2,
            display: 'inline-block',
            fontWeight: 'medium',
            width: 90,
            paddingTop: .5,
            paddingBottom: .5,
            textTransform: 'uppercase'
        }}
             style={getColorBySignal(signal)}
             {...rest}
        >
            {
                label !== '' &&
                <div>{label}</div>
            }
            <div>{value}</div>
        </Box>
    );
}

SignalLabel.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any.isRequired,
    signal: PropTypes.number.isRequired
};

export default SignalLabel;
