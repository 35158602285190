import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Typography, Breadcrumbs, Link, Grid} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Header({...rest}) {
    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            {...rest}
        >
            <Grid item>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small"/>}
                    aria-label="breadcrumb"
                >
                    <Link color="inherit" to="/investment" underline="none" component={RouterLink}>
                        Investment
                    </Link>
                    <Typography color="textPrimary">
                        Investmentstrategien
                    </Typography>
                </Breadcrumbs>
                <Typography
                    variant="h3"
                    color="textPrimary"
                >
                    Investmentstrategien
                </Typography>
            </Grid>
        </Grid>
    );
}

Header.propTypes = {};

export default Header;
