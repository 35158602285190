import React from 'react';
import {LicenseInfo} from '@mui/x-license-pro';
import {createBrowserHistory} from 'history';
import 'moment/locale/de';
import {SnackbarProvider} from 'notistack';
import {StyledEngineProvider} from '@mui/material';
import {ThemeProvider} from '@mui/system';
import Auth from './components/Auth';
import CookieBot from 'react-cookiebot/lib/CookieBot';
import ScrollReset from './components/ScrollReset';
import {createAppTheme} from 'src/theme';
import AppRoutes from 'src/AppRoutes';
import 'src/assets/scss/main.scss';
import CustomRouter from "./components/CustomRouter";

export const history = createBrowserHistory();

LicenseInfo.setLicenseKey('3436c1e1e0ce3dcc379b26a087dc4ce9Tz01OTM1MixFPTE3MDczOTM2MDQ2ODMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={createAppTheme()}>
                <SnackbarProvider maxSnack={1}>
                    <CustomRouter history={history}>
                        <Auth>
                            <ScrollReset/>
                            <CookieBot domainGroupId='6d2fc053-e77f-4f1a-bfd3-f31a489a88e5'/>
                            <AppRoutes/>
                        </Auth>
                    </CustomRouter>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
