import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Container, Hidden
} from '@mui/material';
import Page from 'src/components/Page';
import DangerouslyHtml from "src/components/DangerouslyHtml";
import Header from './Header';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import LoadingScreen from "src/components/LoadingScreen";
import {getTitle} from "src/utils/meta";
import {Navigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";

function NewsDetailView() {
    const isMountedRef = useIsMountedRef();
    const {paramId} = useParams();
    const [news, setNews] = useState(null);

    const getNews = useCallback(() => {
        axios
            .get('/api/general/newsteasers/' + paramId)
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("newsteaser")) {
                        setNews(response.data.payload.newsteaser);
                    }
                }
            });
    }, [isMountedRef, paramId]);

    useEffect(() => {
        getNews();
    }, [getNews]);

    if (news === null) {
        return <LoadingScreen/>;
    }

    if (news === false) {
        return <Navigate to="/404"/>;
    }

    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle(news.titel + " - News")}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth="lg">
                <Header news={news}/>
                <Box mt={1}>
                    <Grid
                        container
                        spacing={3}
                    >
                        {
                            <Hidden smUp>
                                <Grid
                                    item
                                    lg={8}
                                    xl={7}
                                    xs={12}
                                >
                                    <Card>
                                        <CardContent>
                                            <DangerouslyHtml html={'<p><strong>' + news.teasertext + '</strong></p>'}/>
                                        </CardContent>
                                    </Card>
                                    <Box mt={3}>
                                        <Card>
                                            <CardContent>
                                                <img style={{
                                                    margin: 'auto',
                                                    display: 'block',
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                }} alt="" src={news.bild_url}/>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                    <Box mt={3}>
                                        <Card>
                                            <CardContent>
                                                <DangerouslyHtml html={news.detailtext}/>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                            </Hidden>
                        }
                        {
                            <Hidden only="xs">
                                <Grid
                                    item
                                    md={7}
                                    xs={12}
                                >
                                    <Card>
                                        <CardContent>
                                            <DangerouslyHtml html={'<p><strong>' + news.teasertext + '</strong></p>'}/>
                                        </CardContent>
                                    </Card>
                                    <Box mt={3}>
                                        <Card>
                                            <CardContent>
                                                <DangerouslyHtml html={news.detailtext}/>
                                            </CardContent>
                                        </Card>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    md={5}
                                    xs={12}
                                >
                                    <Card>
                                        <CardContent>
                                            <img style={{
                                                margin: 'auto',
                                                display: 'block',
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                            }} alt="" src={news.bild_url}/>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Hidden>
                        }
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
}

export default NewsDetailView;
