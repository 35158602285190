import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import AddStockWatchlistForm from "./AddStockWatchlistForm";
import axios from "src/utils/axios";
import useIsMountedRef from "src/hooks/useIsMountedRef";

function AddStockWatchlistModal({stock, onSubmit, onCancel, open, ...rest}) {
  const isMountedRef = useIsMountedRef();
  const [watchlists, setWatchlists] = useState(null);

  const getWatchlists = useCallback(() => {
    axios
      .get('/api/user/depots', {
        params: {
          typ: 'watchlist',
          offset: 0,
          limit: 30
        }
      })
      .then((response) => {
        if (isMountedRef.current) {
          if (response.data.payload.hasOwnProperty("depots")) {
            setWatchlists(response.data.payload.depots);
          }
        }
      });
  }, [isMountedRef]);

  useEffect(() => {
    getWatchlists();
  }, [getWatchlists]);

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onCancel} {...rest}>
      {/* Dialog renders its body even if not open */}
      {open && (
        <AddStockWatchlistForm stock={stock} watchlists={watchlists} onCancel={onCancel} onSubmit={onSubmit}/>
      )}
    </Dialog>
  );
};

AddStockWatchlistModal.propTypes = {
  stock: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool
};

AddStockWatchlistModal.defaultProps = {
  onSubmit: () => {
  },
  onCancel: () => {
  },
  open: false
};

export default AddStockWatchlistModal;
