import React, {
    useCallback,
    useState,
    useEffect
} from 'react';
import {Box, Button, Card, CardHeader, Divider, Link, Typography,} from '@mui/material';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from "src/utils/axios";
import LinearProgress from "@mui/material/LinearProgress";
import {Link as RouterLink} from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CustomDataGrid from "src/components/CustomDataGrid";
import {useNavigate} from "react-router";
import {useGridApiRef} from "@mui/x-data-grid-pro";

function Rankings({...rest}) {
    const apiRef = useGridApiRef();
    const isMountedRef = useIsMountedRef();
    const navigate = useNavigate();
    const [rankings, setRankings] = useState(null);

    const getRankings = useCallback(() => {
        axios
            .get('/api/filter/filters', {
                params: {
                    offset: 0,
                    limit: 30
                }
            })
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("filters")) {
                        setRankings(response.data.payload.filters);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getRankings();
    }, [getRankings]);

    return (
        <Card {...rest}>
            <CardHeader title="Meine Rankings"/>
            <Divider/>
            {rankings === null && <LinearProgress/>}
            {
                rankings &&
                <CustomDataGrid
                    apiRef={apiRef}
                    options={{
                        header: false,
                    }}
                    columns={[
                        {
                            headerName: "Name",
                            field: "bezeichnung",
                            defaultSort: 'asc',
                            flex: 1
                        }
                    ]}
                    onRowClick={(params) => navigate(`/stocks/rankings/${params.row.id}`)}
                    data={rankings}
                />
            }
            <Box
                p={2}
                display="flex"
                justifyContent="flex-end"
            >
                <Button
                    component={RouterLink}
                    size="small"
                    to="/stocks/rankings"
                >
                    Zur Rankingübersicht
                    <NavigateNextIcon/>
                </Button>
            </Box>
        </Card>
    );
}

Rankings.propTypes = {};

export default Rankings;
