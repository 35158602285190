import React from 'react';
import PropTypes from "prop-types";
import {Button} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function SpinnerButton({children, isSubmitting, ...rest}) {
    return (
        <Button
            style={{
                position: 'relative'
            }}
            color="secondary"
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            {...rest}
        >
            {isSubmitting && <CircularProgress size={24} style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
            }}/>}
            {children}
        </Button>
    );
}

SpinnerButton.propTypes = {
    children: PropTypes.node.isRequired,
    isSubmitting: PropTypes.bool
};

export default SpinnerButton;
