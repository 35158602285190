import React from 'react';
import {Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, Typography} from '@mui/material';

function Notifications({...rest}) {
    const handleSubmit = async (event) => {
        event.preventDefault();
        // Make API request
        //await wait(500);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Card {...rest}>
                <CardHeader title="Benachrichtigung"/>
                <Divider/>
                <CardContent>
                    <Grid
                        container
                        spacing={6}
                        wrap="wrap"
                    >
                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <Typography
                                gutterBottom
                                variant="h6"
                                color="textPrimary"
                            >
                                System
                            </Typography>
                            <div>
                                <FormControlLabel
                                    control={(
                                        <Checkbox defaultChecked/>
                                    )}
                                    label="Lorem Ipsum"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox/>}
                                    label="Lorem Ipsum"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={(
                                        <Checkbox defaultChecked/>
                                    )}
                                    label="Lorem Ipsum"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={(
                                        <Checkbox defaultChecked/>
                                    )}
                                    label={(
                                        <>
                                            <Typography
                                                variant="body1"
                                                color="textPrimary"
                                            >
                                                Lorem Ipsum
                                            </Typography>
                                            <Typography variant="caption">
                                                Lorem Ipsum dolor sit
                                            </Typography>
                                        </>
                                    )}
                                />
                            </div>
                        </Grid>
                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <Typography
                                gutterBottom
                                variant="h6"
                                color="textPrimary"
                            >
                                Mitgliedschaft
                            </Typography>
                            <div>
                                <FormControlLabel
                                    control={(
                                        <Checkbox defaultChecked/>
                                    )}
                                    label="Lorem Ipsum"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={(
                                        <Checkbox defaultChecked/>
                                    )}
                                    label="Lorem Ipsum"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider/>
                <Box
                    p={2}
                    display="flex"
                    justifyContent="flex-end"
                >
                    <Button
                        color="secondary"
                        type="submit"
                        variant="contained"
                    >
                        Speichern
                    </Button>
                </Box>
            </Card>
        </form>
    );
}

Notifications.propTypes = {};

export default Notifications;
