import React from 'react';
import {Container} from '@mui/material';
import Page from 'src/components/Page';
import Header from './Header';
import WatchlistCreateForm from "./WatchlistCreateForm";

function WatchlistCreateView() {
    return (
        <Page
            sx={{
                backgroundColor: 'background.dark',
                minHeight: '100%',
            }}
            title="Watchlist anlegen"
        >
            <Container
                sx={{
                    paddingTop: 3,
                    paddingBottom: 100
                }}
                maxWidth="lg">
                <Header/>
                <WatchlistCreateForm/>
            </Container>
        </Page>
    );
}

export default WatchlistCreateView;
