import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Table, TableBody, Grid} from '@mui/material';
import DangerouslyHtml from "src/components/DangerouslyHtml";
import KombiScore from "./KombiScore";
import Row from './Row';
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import numeral from "src/mixins/numeral";

function ScoresDetail({
                          kombiScore,
                          technischerScore,
                          trendScore,
                          smaRatio,
                          deltaSma,
                          hochTiefRatio,
                          risikoScore,
                          verlustVola,
                          cdd,
                          upDownDays,
                          qnr,
                          valueScore,
                          kgv,
                          kuv,
                          ebitev,
                          roc,
                          modRoa,
                          grMarg,
                          netMarg,
                          ...rest
                      }) {
    const [helpOpen, setHelpOpen] = useState(false);
    const [helpTitle, setHelpTitle] = useState('');
    const [helpText, setHelpText] = useState('');

    const handleHelpClick = (helpTitle, helpText) => {
        if (helpTitle && helpText) {
            setHelpTitle(helpTitle);
            setHelpText(helpText);
        }

        setHelpOpen(!helpOpen);
    };

    const handleHelpClose = () => {
        setHelpOpen(false);
    };

    const kombiScoreData = {
        label: 'Kombi-Score',
        value: kombiScore,
        helpTitle: 'Kombi-Score',
        helpText: '<p><strong>Der Kombi-Score ergibt sich indem der Technische Score mit dem Value-Score kombiniert wird.</strong> Der Technische Score fließt mit einer Gewichtung von 60% ein, da er bereits den Trend-Score und den Safety-Score in sich vereint. Der Value-Score erhält entsprechend eine Gewichtung von 40%.</p> <p>Weist eine Aktie einen hohen Kombi-Score auf deutet das auf einen stabilen und schwankungsarmen Aufwärtstrend bei gleichzeitig günstiger Bewertung und hohen Profitabilität des Unternehmens hin.</p> <p>Aktien mit einem hohen Kombi-Score performen künftig tendenziell besser als Aktien mit einem niedrigen Kombi-Score.</p> <p>Eine genaue Erklärung des Kombi-Scores sowie eine beispielhafte Berechnung finden Sie in diesem <a href="/help/video-course/329">Video-Kurs</a>.</p>'
    };

    const technikScoreData = {
        name: 'technischerScore',
        label: 'Technischer-Score',
        value: technischerScore,
        helpTitle: 'Technischer-Score',
        helpText: '<p><strong>Der Technische Score ergibt sich indem der Trend-Score mit dem Safety-Score kombiniert wird.</strong> Der Trend-Score erhält dabei eine Gewichtung von 60%, der Safety-Score eine Gewichtung von 40%. Somit liegt der Fokus etwas stärker auf der Messung von Aufwärtstrends.</p> <p>Weist eine Aktie einen hohen Technischen Score auf, hat sie zuletzt einen starken Aufwärtstrend bei gleichzeitig geringen Kursschwankungen verzeichnet. Tendenziell verzeichnen solche Aktien auch in den kommenden Monaten eine bessere Performance in Form höherer Renditen und geringerer Kursrisiken.</p> <p> Aktien mit einem hohen Technischen Score performen künftig entsprechend tendenziell besser als Aktien mit einem niedrigen Technischen Score.</p> <p>Eine genaue Erklärung des Technischen Scores sowie eine beispielhafte Berechnung finden Sie in diesem <a href="/help/video-course/329">Video-Kurs</a>.</p>',
        children: [{
            name: 'trendScore',
            label: 'Trend-Score',
            value: trendScore,
            helpTitle: 'Trend-Score',
            helpText: '<p><strong>Der Trend-Score misst die Stärke eines Aufwärtstrends einer Aktie im Vergleich zu allen anderen Aktien einer Region.</strong></p> <p>Aktien, die in den letzten Monaten besser performt haben als andere Aktien, tendieren dazu auch künftig besser zu performen. Dieses Verhalten wird auch als <strong>Momentumeffekt</strong> bezeichnet. Entsprechend tendieren Aktien mit hohen Trend-Scores dazu künftig besser zu performen als Aktien mit niedrigen Trend-Scores.</p> <p>Der Trend-Score berechnet sich auf Basis der drei Kennzahlen:</p> <ul><li>SMA-Ratio</li> <li>Delta-SMA</li> <li>52-Wochen-Hoch-Tief-Ratio</li></ul> <p>Eine genaue Erklärung des Trend-Scores sowie eine beispielhafte Berechnung finden Sie in diesem <a href="/help/video-course/311">Video-Kurs</a>.</p>',
            children: [{
                name: 'smaRatio',
                label: 'SMA-Ratio',
                value: smaRatio,
                helpTitle: 'SMA-Ratio',
                helpText: '<p><strong>Die SMA-Ratio misst die Stärke des mittelfristigen Aufwärtstrends anhand von zwei gleitenden Durchschnitten.</strong></p> <p><strong>SMA</strong> steht für Simple-Moving-Average (deutsch: einfacher gleitender Durchschnitt).</p> <p><strong>Ratio</strong> besagt, dass zwei Kennzahlen zueinander ins Verhältnis gesetzt werden.</p> <p>Die SMA-Ratio berechnet sich indem der 20-Tage-Durchschnitt ins Verhältnis zum 200-Tage-Durchschnitt gesetzt wird. Damit gibt die SMA-Ratio an wie viel Prozent der 20-Tage-Durchschnitt aktuell über oder unter dem 200-Tage-Durchschnitt liegt.</p> <p>Hohe Werte sind bei der SMA-Ratio zu bevorzugen, weil sie zeigen, dass die Aktie aktuell einen starken Aufwärtstrend aufweist.</p> <p>Eine detaillierte Erläuterung der SMA-Ratio sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/311">Video-Kurs</a>.</p>',
            }, {
                name: 'deltaSma',
                label: 'Delta-SMA',
                value: deltaSma,
                helpTitle: 'Delta-SMA',
                helpText: '<p><strong>Der Delta-SMA misst die Stärke des langfristigen Aufwärtstrends anhand des gleitenden Durchschnitts über 200 Handelstage.</strong></p> <p><strong>SMA</strong> steht für Simple-Moving-Average (deutsch: einfacher gleitender Durchschnitt).</p> <p><strong>Delta</strong> ist ein anderes Wort für Veränderung.</p> <p>Der Delta-SMA misst somit die Veränderung des 200-Tage-Durchschnitts in einem bestimmten Zeitraum. Dieser Zeitraum umfasst 20 Handelstage. Der Delta-SMA vergleicht somit den heutigen Stand des 200-Tage-Durchschnitts mit dem Stand des 200-Tage-Durchschnitts von vor 20 Handelstagen. Der Delta-SMA gibt an wie viel Prozent der 200-Tage-Durchschnitt heute höher oder tiefer steht als vor 20 Handelstagen.</p> <p>Hohe Werte sind beim Delta-SMA zu bevorzugen, weil sie zeigen, dass die Aktie einen starken Aufwärtstrend aufweist.</p> <p>Eine detaillierte Erläuterung des Delta-SMA sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/311">Video-Kurs</a>.</p>',
            }, {
                name: 'hochTiefRatio',
                label: '52WHTR',
                value: hochTiefRatio,
                helpTitle: '52-Wochen-Hoch-Tief-Ratio (52WHTR)',
                helpText: '<p><strong>Die 52-Wochen-Hoch-Tief-Ratio (52WHTR) misst die Stärke eines Aufwärtstrends einer Aktie indem der aktuelle Kurs ins Verhältnis zum 52-Wochen-Hoch sowie zum 52-Wochen-Tief gesetzt wird.</strong></p> <p>Aktien mit starken Aufwärtstrends notieren für gewöhnlich dicht an ihrem 52-Wochen-Hoch. Zusätzlich weisen diese Aktien aber auch einen großen Abstand zu ihrem 52-Wochen-Tief auf.</p> <p>Da für die Berechnung der 52WHTR sowohl der Abstand zum 52-Wochen-Hoch als auch der Abstand zum 52-Wochen-Tief betrachtet werden, ist der absolute Wert nur schwer zu interpretieren. Es gilt: hohe Werte sind bei der 52WHTR zu bevorzugen, weil sie zeigen, dass die Aktie dicht an ihrem 52-Wochen-Hoch liegt und gleichzeitig weit entfernt von ihrem 52-Wochen-Tief ist und somit einen starken Aufwärtstrend aufweist.</p> <p>Eine detaillierte Erläuterung der 52-Wochen-Hoch-Tief-Ratio sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/311">Video-Kurs</a>.</p>',
            },]
        }, {
            name: 'risikoScore',
            label: 'Safety-Score',
            value: risikoScore,
            helpTitle: 'Safety-Score',
            helpText: '<p><strong>Der Safety-Score misst die Kursrisiken einer Aktie im Vergleich zu allen anderen Aktien einer Region.</strong></p> <p>Aktien, die in der Vergangenheit geringere Kursrisken (bspw. Kursschwankungen oder große Drawdown, also Kursrückgänge) aufgewiesen haben, tendieren dazu auch künftig geringere Kursrisiken zu verzeichnen und erzielen dabei häufig auch noch eine höhere Rendite. Dieses Muster wird als <strong>Risikoanomalie</strong> bezeichnet.</p> <p>Entsprechend tendieren Aktien mit hohen Safety-Scores dazu künftig eine stabile Kursentwicklung bei einer ansehnlichen Rendite zu verzeichnen.</p> <p>Der Safety-Score berechnet sich auf Basis der 4 Kennzahlen:</p> <ul><li>Verlust-Volatilität</li> <li>Conditional Drawdown (CDD)</li> <li>Up-Down-Days</li> <li>Quadrierte negativen Renditen (QNR)</li></ul> <p>Eine genaue Erklärung des Safety-Scores sowie eine beispielhafte Berechnung finden Sie in diesem <a href="/help/video-course/327">Video-Kurs</a>.</p>',
            children: [{
                name: 'verlustVola',
                label: 'Verlust-Volatilität',
                value: verlustVola,
                helpTitle: 'Verlust-Volatilität',
                helpText: '<p><strong>Die Verlust-Volatilität misst die Kursschwankungen einer Aktie und damit ihre Kursrisiken.</strong> Für gewöhnlich wird eine niedrige Volatilität und damit eine niedrige Schwankung des Aktienkurses und somit ein geringes Risiko bevorzugt.</p> <p>Die Verlust-Volatilität ist eine Abwandlung der klassischen Volatilität. Die klassische Volatilität bestraft große positive und negative Schwankungen allerdings gleichmäßig. Große positive Schwankungen (also große Tagesgewinne) stören einen Investor jedoch nicht.</p> <p>Um die Verlust-Volatilität zu berechnen setzen wir alle positiven Tagesrenditen einfach auf 0, sodass nur negative Renditen in die Berechnung der Verlust-Volatilität einfließen. Die Verlust-Volatilität berechnet sich auf Basis der täglichen Renditen des letzten Jahres (260 Handelstage).</p> <p>Bei der Verlust-Volatilität sind niedrige Werte zu bevorzugen, da sie auf geringe Kursschwankungen der Aktie hindeuten.</p> <p>Eine detaillierte Erläuterung der Verlust-Volatilität sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/327">Video-Kurs</a>.</p>',
            }, {
                name: 'cdd',
                label: 'CDD',
                value: cdd,
                helpTitle: 'Conditional Drawdown (CDD)',
                helpText: '<p><strong>Der Conditional Drawdown (CDD) ist ein Maß, das die Kursrückgänge einer Aktie misst.</strong> Der CDD ist ein vergleichbares Maß zum in der Praxis häufig betrachteten Maximalen Drawdown (MaxDD).</p> <p>Der MaxDD gibt an wie groß der Kursrückgang einer Aktie ausgehend von ihrem zuvor erreichten Hoch maximal war. Mit dem CDD wollen wir das Bild aber etwas weiter fassen. Wir betrachten hierfür nicht nur den einen größten Verlust, sondern mehrere große Drawdowns (Kursrückgänge).</p> <p>Der CDD berechnet sich auf Basis der Kursentwicklung über die letzten 3 Jahre. Der CDD ist immer negativ, da wir hier Kursrückgänge analysieren.</p> <p>Besonders kleine negative Werte sind beim CDD zu bevorzugen, da sie auf geringe zwischenzeitliche Kursrückgänge, also Drawdowns, hindeuten.</p> <p>Eine detaillierte Erläuterung des CDD sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/327">Video-Kurs</a>.</p>',
            }, {
                name: 'upDownDays',
                label: 'UpDownDays',
                value: numeral(upDownDays).format('0.0'),
                helpTitle: 'Up-Down-Days',
                helpText: '<p><strong>Die Up-Down-Days sind ein Maß, das die Kontinuität der Kursentwicklung einer Aktie misst.</strong> Die Up-Down-Days zählen an wie vielen Tagen eine Aktie im vergangenen Jahr gestiegen ist und an wie vielen sie gefallen ist.</p> <p>Die Stärke des jeweiligen Gewinns oder Verlusts an einem Tag spielt bei der Berechnung keine Rolle. Weist eine Aktie deutlich mehr Gewinntage als Verlusttage auf, deutet das auf eine stabile Kursentwicklung und gleichzeitig auf einen Aufwärtstrend hin.</p> <p>Die Up-Down-Days werden auf Basis eines Jahres (260 Handelstage) berechnet.</p> <p>Hohe Werte sind bei den Up-Down-Days zu bevorzugen, weil sie zeigen, dass eine Aktie geringe Kursschwankungen und einen stabilen Aufwärtstrend aufweist.</p> <p>Eine detaillierte Erläuterung der Up-Down-Days sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/327">Video-Kurs</a>.</p>',
            }, {
                name: 'qnr',
                label: 'QNR',
                value: qnr,
                helpTitle: 'Quadrierte negative Renditen (QNR)',
                helpText: '<p><strong>Die Quadrierten negativen Renditen (QNR) zielen darauf ab Aktien mit hohen negativen Tagesrenditen überproportional stark zu bestrafen.</strong></p> <p>Um die QNR zu berechnen wird ein Durchschnitt über die Tagesrenditen eines Jahres (260 Handelstage) gebildet. Positive Tagesrenditen fließen unverändert in diesen Durchschnitt ein. Negative Tagesrenditen werden vor der Durchschnittsbildung hingegen modifiziert: es fließt der quadrierte Wert in die Berechnung ein. Aus einer Tagesrendite von bspw. -5% wird somit eine QNR an diesem Tag von -25%.</p> <p>Aktien, die häufiger größere Tagesverluste verzeichnen weisen entsprechend sehr stark negative QNRs auf.</p> <p>Bei der QNR sind hohe Werte zu bevorzugen. Häufig ist die QNR aufgrund ihrer Berechnungslogik allerdings negativ, sodass besonders kleine negative Werte ein gutes Zeichen sind. Diese Aktien weisen entsprechend eine stabile Kursentwicklung mit wenig größeren Verlusten auf.</p> <p>Eine detaillierte Erläuterung der QNR sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/327">Video-Kurs</a>.</p>',
            },]
        }]
    };

    const valueScoreData = {
        name: 'valueScore',
        label: 'Value-Score',
        value: valueScore,
        helpTitle: 'Value-Score',
        helpText: '<p><strong>Der Value-Score misst in erster Linie die Bewertung und Profitabilität einer Aktie.</strong> Außerdem werden Margen bei der Berechnung mit berücksichtigt.</p> <p>Sowohl die Wissenschaft als auch unsere Analysen zeigen, dass Aktien, die eine günstige Bewertung und gleichzeitig eine hohe Profitabilität aufweisen dazu tendieren künftig eine bessere Performance zu verzeichnen. Entsprechend sind Aktien mit hohen Value-Scores aussichtsreicher als Aktien mit niedrigen Value-Scores.</p> <p>Der Value-Score berechnet sich auf Basis 7 fundamentaler Kennzahlen:</p> <ul><li><strong>Zwei Bewertungskennzahlen:</strong> KGV (Kurs-Gewinn-Verhältnis) & KUV (Kurs-Umsatz-Verhältnis).</li> <li><strong>Drei Profitabilitätskennzahlen:</strong> EBIT/EV (EBIT / Enterprise Value), den ROC (Return-on-Capital) sowie den ModROA (modifizierter Return-on-Assets).</li> <li><strong>Zwei Margenkennzahlen:</strong> Bruttomarge & Nettomarge.</li></ul> <p>Eine genaue Erklärung des Value-Scores sowie eine beispielhafte Berechnung finden Sie in diesem <a href="/help/video-course/328">Video-Kurs</a>.</p>',
        children: [{
            name: 'kgv',
            label: 'KGV',
            value: numeral(kgv).format('0.0'),
            helpTitle: 'KGV (Kurs-Gewinn-Verhältnis)',
            helpText: '<p><strong>Das KGV (Kurs-Gewinn-Verhältnis) berechnet sich indem die Marktkapitalisierung eines Unternehmens durch seinen Jahresüberschuss dividiert wird.</strong></p> <p>Je höher der Jahresüberschuss (Gewinn) im Verhältnis zur Marktkapitalisierung (Bewertung des Unternehmens) ist, desto günstiger ist das Unternehmen bewertet. Beachte: das KGV kann nur für Unternehmen berechnet werden, die einen Gewinn erwirtschaften.</p> <p>Beim KGV gilt die Regel: niedrige Werte sind besser. Ein niedriges KGV zeigt somit eine günstige Bewertung an.</p> <p>Eine detaillierte Erläuterung des KGV sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/328">Video-Kurs</a>.</p>',
        }, {
            name: 'kuv',
            label: 'KUV',
            value: numeral(kuv).format('0.0'),
            helpTitle: 'KUV (Kurs-Umsatz-Verhältnis)',
            helpText: '<p><strong>Das KUV (Kurs-Umsatz-Verhältnis) berechnet sich indem die Marktkapitalisierung eines Unternehmens durch seinen Umsatz dividiert wird.</strong></p> <p>Je höher der Umsatz im Verhältnis zur Marktkapitalisierung (Bewertung des Unternehmens) ist, desto günstiger ist das Unternehmen bewertet.</p> <p>Beim KUV gilt die Regel: niedrige Werte sind besser. Ein niedriges KUV zeigt somit eine günstige Bewertung an.</p> <p>Eine detaillierte Erläuterung des KUV sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/328">Video-Kurs</a>.</p>',
        }, {
            name: 'ebitev',
            label: 'EBIT/EV',
            value: ebitev,
            helpTitle: 'EBIT/EV (EBIT / Enterprise Value)',
            helpText: '<p><strong>Das EBIT/EV (EBIT / Enterprise Value) ergibt sich indem das EBIT eines Unternehmens durch seinen Enterprise Value dividiert wird.</strong></p> <p><strong>EBIT</strong> steht für Earnings Before Interests and Taxes (deutsch: Gewinn vor Zinsen und Steuern). Mit dem EBIT wird also eine Gewinngröße betrachtet, bei der der Zinsaufwand und die Steuern noch nicht abgezogen wurden.</p> <p>Der <strong>Enterprise Value</strong> (deutsch: Unternehmenswert) ergibt sich vereinfacht gesagt als die Summe aus Marktkapitalisierung, der vom Unternehmen gehaltenen Minderheitsbeteiligungen und Fremdkapital abzüglich aller liquiden Mittel.</p> <p>Für das EBIT/EV gilt, dass hohe Werte besser sind, weil daran zu sehen ist, dass der Gewinn (das EBIT) im Vergleich zum Unternehmenswert (Enterprise Value) sehr hoch ist. Ein hoher EBIT/EV zeigt somit eine hohe Profitabilität an.</p> <p>Eine detaillierte Erläuterung des EBIT/EV sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/328">Video-Kurs</a>.</p>',
        }, {
            name: 'roc',
            label: 'ROC',
            value: roc,
            helpTitle: 'ROC (Return-on-Capital)',
            helpText: '<p><strong>Der ROC (Return-on-Capital) ergibt sich indem das EBIT eines Unternehmens durch sein Gesamtkapital dividiert wird.</strong></p> <p><strong>EBIT</strong> steht für Earnings Before Interests and Taxes (deutsch: Gewinn vor Zinsen und Steuern). Mit dem EBIT wird also eine Gewinngröße betrachtet, bei der der Zinsaufwand und die Steuern noch nicht abgezogen wurden.</p> <p>Das <strong>Gesamtkapital</strong> entspricht vereinfacht gesagt der Summe aus Eigenkapital, langfristigem Fremdkapital sowie der vom Unternehmen gehaltenen Minderheitsbeteiligungen.</p> <p>Für den ROC gilt, dass hohe Werte besser sind, da der Gewinn (EBIT) im Verhältnis zum Gesamtkapital dann besonders hoch ist. Ein hoher ROC zeigt somit eine hohe Profitabilität an.</p> <p>Eine detaillierte Erläuterung des ROC sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/328">Video-Kurs</a>.</p>',
        }, {
            name: 'modRoa',
            label: 'ModROA',
            value: modRoa,
            helpTitle: 'ModROA (Modifizierter Return-on-Assets)',
            helpText: '<p><strong>Der ModROA (Modifizierter Return-on-Assets) ergibt sich indem das EBITDA eines Unternehmens durch sein Gesamtvermögen (bzw. die Bilanzsumme) dividiert wird.</strong></p> <p><strong>EBITDA</strong> steht für Earnings Before Interests, Taxes, Depreciation and Amortization (deutsch: Gewinn vor Zinsen, Steuern und Abschreibungen). Mit dem EBITDA wird also eine Gewinngröße betrachtet, bei der der Zinsaufwand, die Steuern und insb. die Abschreibungen noch nicht abgezogen wurden.</p> <p>Das <strong>Gesamtvermögen</strong> entspricht der Bilanzsumme des Unternehmens und ergibt sich vereinfacht gesagt aus dem Anlagevermögen und dem Umlaufvermögen. Zum Anlagevermögen gehören bspw. Maschinen, Produktionshallen oder immaterielle Vermögensgegenstände. Das Umlaufvermögen umfasst u.a. Vorräte und liquide Mittel.</p> <p>Für den ModROA gilt, dass hohe Werte besser sind, da der Gewinn (EBITDA) im Verhältnis zum Vermögen dann besonders hoch ist. Ein hoher ModROA zeigt somit eine hohe Profitabilität an.</p> <p>Eine detaillierte Erläuterung des ModROA sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/328">Video-Kurs</a>.</p>',
        }, {
            name: 'grMarg',
            label: 'Bruttomarge',
            value: grMarg,
            helpTitle: 'Bruttomarge',
            helpText: '<p><strong>Die Bruttomarge ergibt sich indem der Bruttoertrag eines Unternehmens durch seinen Umsatz dividiert wird.</strong></p> <p>Der <strong>Bruttoertrag</strong> berechnet sich indem vom Umsatz die Herstellungskosten sowie die Abschreibungen abgezogen werden.</p> <p>Bei der Bruttomarge werden hohe Werte bevorzugt. Eine hohe Bruttomarge zeigt, dass das Unternehmen im Verhältnis zu seinem Umsatz einen hohen Bruttoertrag erwirtschaften konnte.</p> <p>Eine detaillierte Erläuterung der Bruttomarge sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/328">Video-Kurs</a>.</p>',
        }, {
            name: 'netMarg',
            label: 'Nettomarge',
            value: netMarg,
            helpTitle: 'Nettomarge',
            helpText: '<p><strong>Die Nettomarge ergibt sich indem der Jahresüberschuss (Nettoergebnis) eines Unternehmens durch seinen Umsatz dividiert wird.</strong></p> <p>Der <strong>Jahresüberschuss</strong> berechnet sich indem vom Umsatz alle entstandenen Kosten abgezogen werden.</p> <p>Bei der Nettomarge werden hohe Werte bevorzugt. Eine hohe Nettomarge zeigt, dass das Unternehmen im Verhältnis zu seinem Umsatz einen hohen Jahresüberschuss erwirtschaften konnte.</p> <p>Eine detaillierte Erläuterung der Nettomarge sowie konkrete Rechenbeispiele finden Sie in diesem <a href="/help/video-course/328">Video-Kurs</a>.</p>',
        },]
    };

    return (<Grid container spacing={3}>
        <Grid item xs={12}>
            <KombiScore onHelp={handleHelpClick} {...kombiScoreData}/>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card {...rest}>
                <Table>
                    <TableBody>
                        <Row onHelp={handleHelpClick} {...technikScoreData}/>
                    </TableBody>
                </Table>
            </Card>
        </Grid>
        <Grid item md={6} xs={12}>
            <Card {...rest}>
                <Table>
                    <TableBody>
                        <Row onHelp={handleHelpClick} {...valueScoreData}/>
                    </TableBody>
                </Table>
            </Card>
        </Grid>
        <Dialog
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                    },
                }
            }}
            open={helpOpen}
            onClose={handleHelpClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle>{helpTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <DangerouslyHtml html={helpText}/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleHelpClick} color="primary" autoFocus>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    </Grid>);
};

ScoresDetail.propTypes = {
    kombiScore: PropTypes.number,
    technischerScore: PropTypes.number,
    trendScore: PropTypes.number,
    smaRatio: PropTypes.number,
    deltaSma: PropTypes.number,
    hochTiefRatio: PropTypes.number,
    risikoScore: PropTypes.number,
    verlustVola: PropTypes.number,
    cdd: PropTypes.number,
    upDownDays: PropTypes.number,
    qnr: PropTypes.number,
    valueScore: PropTypes.number,
    kgv: PropTypes.number,
    kuv: PropTypes.number,
    ebitev: PropTypes.number,
    roc: PropTypes.number,
    modRoa: PropTypes.number,
    grMarg: PropTypes.number,
    netMarg: PropTypes.number,
};

export default ScoresDetail;
