import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
    Breadcrumbs,
    Button,
    Grid,
    Link,
    Typography
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Header({...rest}) {
    return (
        <Grid
            container
            justifyContent="space-between"
            spacing={3}
            {...rest}
        >
            <Grid item>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small"/>}
                    aria-label="breadcrumb"
                >
                    <Link
                        variant="body1"
                        color="inherit"
                        to="/depot"
                        component={RouterLink}
                        underline="none">
                        Depot
                    </Link>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                    >
                        Watchlists
                    </Typography>
                </Breadcrumbs>
                <Typography
                    variant="h3"
                    color="textPrimary"
                >
                    Neue Watchlist anlegen
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    component={RouterLink}
                    to="/depot/watchlist"
                >
                    Abbrechen
                </Button>
            </Grid>
        </Grid>
    );
}

Header.propTypes = {};

export default Header;
