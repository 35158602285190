import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Card,
    Container
} from '@mui/material';
import numeral from "src/mixins/numeral";
import Page from 'src/components/Page';
import Header from './Header';
import {useNavigate, useParams} from "react-router";
import {Navigate} from 'react-router-dom';
import useIsMountedRef from "src/hooks/useIsMountedRef";
import {useSnackbar} from 'notistack';
import axios from "src/utils/axios";
import LoadingScreen from "src/components/LoadingScreen";
import ScoreLabel from "src/components/ScoreLabel";
import {getTitle} from "src/utils/meta";
import CustomDataGrid from "src/components/CustomDataGrid";
import {GridActionsCellItem, GridRowModes} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {useGridApiRef} from "@mui/x-data-grid-pro";

function WatchlistDetailView() {
    const apiRef = useGridApiRef();
    const isMountedRef = useIsMountedRef();
    const [watchlist, setWatchlist] = useState(null);
    const [stocks, setStocks] = useState(null);
    const {id} = useParams();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const getWatchlist = useCallback(() => {
        axios
            .get('/api/user/depot/akties', {
                params: {
                    depot_id: parseInt(id),
                    offset: 0,
                    limit: 250
                }
            })
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("depot") && response.data.payload.hasOwnProperty("depotsaktien")) {
                        setWatchlist(response.data.payload.depot);
                        setStocks(response.data.payload.depotsaktien);
                    }
                }
            })
            .catch((error) => {
                setWatchlist(false);
                setStocks(false);
            });
    }, [isMountedRef, id]);

    const handleDeleteClick = (aktieId) => () => {
        axios.delete('/api/user/depot/aktie', {data: {depot_aktie_id: aktieId}}).then((response) => {
            if (!response.data.payload.hasOwnProperty("count") || response.data.payload.count === 0) {
                throw new Error(response.data.errorMessage)
            } else {
                return axios
                    .get('/api/user/depot/akties', {
                        params: {
                            depot_id: parseInt(id),
                            offset: 0,
                            limit: 250
                        }
                    })
            }
        }).then((response) => {
            if (response.data.payload.hasOwnProperty('depot') && response.data.payload.hasOwnProperty('depotsaktien')) {
                setWatchlist(response.data.payload.depot);
                setStocks(response.data.payload.depotsaktien);
                enqueueSnackbar('Aktie gelöscht', {
                    variant: 'success'
                });
            } else {
                throw new Error(response.data.errorMessage)
            }
        }).catch((error) => {
            enqueueSnackbar('Aktie konnte nicht gelöscht werden: ' + error, {
                variant: 'error'
            });
        });
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
    };

    useEffect(() => {
        getWatchlist();
    }, [getWatchlist]);

    if (watchlist === null || stocks === null) {
        return <LoadingScreen/>;
    }

    if (watchlist === false) {
        return <Navigate to="/404"/>;
    }

    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle('Watchlist "' + watchlist.bezeichnung + '"')}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth={false}>
                <Header watchlist={watchlist.bezeichnung}/>
                <Box mt={3}>
                    <Card>
                        <CustomDataGrid
                            apiRef={apiRef}
                            columns={[
                                {
                                    headerName: "Name",
                                    field: "Aktienname",
                                    flex: 1,
                                },
                                {
                                    headerName: "ISIN",
                                    field: "ISIN",
                                    width: 200,
                                },
                                {
                                    headerName: "Sektor",
                                    field: "Sector:bez",
                                    width: 200,
                                    sortable: false
                                },
                                {
                                    headerName: "Index",
                                    field: "Index_kurz:bez",
                                    width: 160,
                                    sortable: false
                                },
                                {
                                    headerName: "Kurs",
                                    field: "Kurs",
                                    type: "number",
                                    width: 80,
                                    renderCell: params => params.row.active ? numeral(params.row.Kurs).format('0.00') + " " + params.row["Currency:symbol"] : '-'
                                },
                                {
                                    headerName: "Trend",
                                    field: "Trend_Score",
                                    type: "number",
                                    width: 90,
                                    renderCell: params => params.row.active ?
                                        <ScoreLabel key="trendScore" name="trendScore" value={params.row.Trend_Score}/> : '-'
                                },
                                {
                                    headerName: "Safety",
                                    field: "Risiko_Score",
                                    type: "number",
                                    width: 90,
                                    renderCell: params => params.row.active ?
                                        <ScoreLabel key="risikoScore" name="risikoScore" value={params.row.Risiko_Score}/> : '-'
                                },
                                {
                                    headerName: "Tech",
                                    field: "Technisch_Score",
                                    type: "number",
                                    width: 90,

                                    renderCell: params => params.row.active ?
                                        <ScoreLabel key="technischerScore" name="technischerScore" value={params.row.Technisch_Score}/> : '-'
                                },
                                {
                                    headerName: "Value",
                                    field: "Value_Score",
                                    type: "number",
                                    width: 90,
                                    renderCell: params => params.row.active ?
                                        <ScoreLabel key="valueScore" name="valueScore" value={params.row.Value_Score}/> : '-'
                                },
                                {
                                    headerName: "Kombi",
                                    field: "Kombi_Score",
                                    type: "number",
                                    width: 90,

                                    renderCell: params => params.row.active ?
                                        <ScoreLabel key="kombiScore" name="kombiScore" value={params.row.Kombi_Score}/> : '-'
                                },
                                {
                                    headerName: "Marktkapitalisierung",
                                    field: "MarketCap",
                                    type: "number",
                                    width: 160,
                                    renderCell: params => params.row.active ? numeral(params.row.MarketCap).format('(0,0)') + " Mio. €" : '-',
                                },
                                {
                                    headerName: "Handelsvolumen",
                                    field: "Handelsvolumen",
                                    type: "number",
                                    width: 140,
                                    renderCell: params => params.row.active ? numeral(params.row.Handelsvolumen).format('(0,0)') + " Tsd. €" : '-',
                                }, {
                                    field: 'actions',
                                    type: 'actions',
                                    width: 50,
                                    getActions: ({id}) => {
                                        return [
                                            <GridActionsCellItem
                                                icon={<DeleteIcon/>}
                                                label="Delete"
                                                onClick={handleDeleteClick(id)}
                                            />,
                                        ];
                                    }
                                }
                            ]}
                            data={stocks}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        "ISIN": false,
                                        "Sector:bez": false,
                                        "Index_kurz:bez": false,
                                        "MarketCap": false,
                                        "Handelsvolumen": false,
                                        "Kaufpreis:calc": false,
                                    },
                                },
                                sorting: {
                                    sortModel: [{field: 'Aktienname', sort: 'asc'}],
                                },
                            }}
                            configSettingKey="watchlist_detail"
                            onRowClick={(params) => {
                                navigate(`/stocks/search/${params.row.Index_kurz}/${params.row.ISIN}`);
                            }}
                        />
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default WatchlistDetailView;
