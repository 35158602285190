import React, {useCallback, useEffect, useState} from 'react';
import {Box, Card, Container, Link} from '@mui/material';
import Page from 'src/components/Page';
import Header from './Header';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import LoadingScreen from "src/components/LoadingScreen";
import ScoreLabel from "src/components/ScoreLabel";
import {getTitle} from "src/utils/meta";
import CustomDataGrid from "src/components/CustomDataGrid";
import {useNavigate} from "react-router";
import {useGridApiRef} from "@mui/x-data-grid-pro";

function InvestmentStrategyListView() {
    const apiRef = useGridApiRef();
    const isMountedRef = useIsMountedRef();
    const [investmentstrategien, setInvestmentstrategien] = useState(null);
    const navigate = useNavigate();

    const getInvestmentstrategien = useCallback(() => {
        axios
            .get('/api/general/investmentstrategien')
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("investmentstrategien")) {
                        let data = response.data.payload.investmentstrategien.map((value, index) => {
                            return {...value, ...value["depot"], id: index};
                        });

                        setInvestmentstrategien(data);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getInvestmentstrategien();
    }, [getInvestmentstrategien]);

    if (!investmentstrategien) {
        return <LoadingScreen/>;
    }

    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle("Investmentstrategien")}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth={false}>
                <Header/>
                <Box mt={3}>
                    <Card>
                        <CustomDataGrid
                            apiRef={apiRef}
                            configSettingKey="investment_strategy"
                            columns={[
                                {
                                    headerName: "Name",
                                    field: "Bezeichnung",
                                    defaultSort: 'asc',
                                    flex: 1
                                },
                                {
                                    headerName: "Aktien",
                                    field: "depot_aktie_count",
                                    type: "number",
                                    renderCell: params => params.row.hasOwnProperty("depot_aktie_count") ? params.row.depot_aktie_count : 0
                                },
                                {
                                    headerName: "Trend",
                                    field: "Trend_Score_AVG",
                                    type: "number",
                                    width: 90,
                                    renderCell: params => <ScoreLabel key="trendScore" name="trendScore"
                                                                      value={params.row.hasOwnProperty("Trend_Score_AVG") ? params.row.Trend_Score_AVG : 0}/>
                                },
                                {
                                    headerName: "Safety",
                                    field: "Risiko_Score_AVG",
                                    type: "number",
                                    width: 90,
                                    renderCell: params => <ScoreLabel key="risikoScore" name="risikoScore"
                                                                      value={params.row.hasOwnProperty("Risiko_Score_AVG") ? params.row.Risiko_Score_AVG : 0}/>
                                },
                                {
                                    headerName: "Tech",
                                    field: "Technisch_Score_AVG",
                                    type: "number",
                                    width: 90,
                                    renderCell: params => <ScoreLabel key="technischerScore" name="technischerScore"
                                                                      value={params.row.hasOwnProperty("Technisch_Score_AVG") ? params.row.Technisch_Score_AVG : 0}/>
                                },
                                {
                                    headerName: "Value",
                                    field: "Value_Score_AVG",
                                    type: "number",
                                    width: 90,
                                    renderCell: params => <ScoreLabel key="valueScore" name="valueScore"
                                                                      value={params.row.hasOwnProperty("Value_Score_AVG") ? params.row.Value_Score_AVG : 0}/>
                                },
                                {
                                    headerName: "Kombi",
                                    field: "Kombi_Score_AVG",
                                    type: "number",
                                    width: 90,
                                    renderCell: params => <ScoreLabel key="kombiScore" name="kombiScore"
                                                                      value={params.row.hasOwnProperty("Kombi_Score_AVG") ? params.row.Kombi_Score_AVG : 0}/>
                                },
                            ]}
                            data={investmentstrategien}
                            initialState={{
                                sorting: {
                                    sortModel: [{field: 'Bezeichnung', sort: 'asc'}],
                                },
                            }}
                            onRowClick={(params) => navigate(`/investment/strategy/${params.row.id}`)}
                        />
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default InvestmentStrategyListView;
