import moment from "moment";
import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Grid,
    SvgIcon,
    Typography
} from '@mui/material';
import {
    Calendar as CalendarIcon,
} from 'react-feather';

function Header({news, ...rest}) {
    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            {...rest}
        >
            <Grid item>
                <Typography
                    variant="h3"
                    color="textPrimary"
                >
                    {news.titel}
                </Typography>
                <Box
                    mx={-2}
                    display="flex"
                    color="text.secondary"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: 2
                    }}>
                        <SvgIcon
                            fontSize="small"
                            sx={{
                                marginRight: 1
                            }}
                        >
                            <CalendarIcon/>
                        </SvgIcon>
                        <Typography
                            variant="body2"
                            color="inherit"
                            component="span"
                        >
                            {moment.unix(news.veroeffentlichungsdatum_timestamp).format("L")}
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

Header.propTypes = {
    news: PropTypes.object.isRequired
};

export default Header;
