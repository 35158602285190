import React from 'react';
import PropTypes from 'prop-types';
import Score from 'src/utils/score';
import Box from "@mui/material/Box";
import numeral from "src/mixins/numeral";

function getLabel(name) {
    let ret = name;

    switch (name) {
        case 'Trend_Score_AVG':
            ret = 'Trend';
            break;

        case 'Risiko_Score_AVG':
            ret = 'Safety';
            break;

        case 'Technisch_Score_AVG':
            ret = 'Tech';
            break;

        case 'Value_Score_AVG':
            ret = 'Value';
            break;

        case 'Kombi_Score_AVG':
            ret = 'Kombi';
            break;

        default:
            break;
    }

    return ret;
}

function getRealName(name) {
    let ret = name;

    switch (name) {
        case 'Trend_Score_AVG':
            ret = 'trendScore';
            break;

        case 'Risiko_Score_AVG':
            ret = 'risikoScore';
            break;

        case 'Technisch_Score_AVG':
            ret = 'technischerScore';
            break;

        case 'Value_Score_AVG':
            ret = 'valueScore';
            break;

        case 'Kombi_Score_AVG':
            ret = 'kombiScore';
            break;

        default:
            break;
    }

    return ret;
}

function ScoreLabel({name, label, value, ...rest}) {
    label = getLabel(label);
    name = getRealName(name);

    return (
        <Box sx={{
            alignItems: 'center',
            textAlign: 'center',
            borderRadius: 2,
            display: 'inline-block',
            fontWeight: 'medium',
            width: 75,
            padding: .5,
            textTransform: 'uppercase',
        }}
             style={Score.getColorByValue(name, value)}
             {...rest}
        >
            {
                label !== '' &&
                <div>{label}</div>
            }
            <div>{isNaN(value) ? value : numeral(value).format('0.0 %')}</div>
        </Box>
    );
}

ScoreLabel.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired
};

export default ScoreLabel;
