import React, {useCallback, useEffect, useState} from 'react';
import {Box, Container, Grid} from '@mui/material';
import Page from 'src/components/Page';
import LoadingScreen from "src/components/LoadingScreen";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import axios from "src/utils/axios";
import Header from './Header';
import VideoCourseCard from "./VideoCourseCard";

function VideoCourseListView() {
    const isMountedRef = useIsMountedRef();
    const [videoCourses, setVideoCourses] = useState(null);

    const getVideoCourses = useCallback(() => {
        axios
            .get('/api/general/kurse')
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("kurse")) {
                        setVideoCourses(response.data.payload.kurse);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getVideoCourses();
    }, [getVideoCourses]);

    if (!videoCourses) {
        return <LoadingScreen/>;
    }

    return (
        <Page title="Videokurse">
            <Container maxWidth="lg" sx={{
                paddingTop: 3,
                paddingBottom: 3
            }}>
                <Header/>
                <Box mt={3}>
                    <Grid container spacing={3}>
                        {videoCourses.map((videoCourse) => (
                            <Grid item key={videoCourse.id} xs={12} sm={6} md={4}>
                                <VideoCourseCard videoCourse={videoCourse}/>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
}

export default VideoCourseListView;
