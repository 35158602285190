import React from 'react';
import {useSelector} from 'react-redux';
import {Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';

function RightsGuard({children}) {
  const account = useSelector((state) => state.account);

  if (account.user) {
    if (!_.includes(account.user.roles, "ROLE_PAYING")) {
        return <Navigate to="/account/subscription/not-paying" replace/>;
    }
  } else {
      return <Navigate to="/login" replace/>;
  }

  return children;
}

RightsGuard.propTypes = {
  children: PropTypes.any
};

export default RightsGuard;
