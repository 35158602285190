import React from 'react';
import Yup from 'src/mixins/yup';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {Box, Checkbox, FormHelperText, TextField, Typography, Link} from '@mui/material';
import {FORM} from "src/constants";
import SpinnerButton from "src/components/SpinnerButton";
import axios from 'src/utils/axios';
import PasswordTextFieldSwitcher from "src/components/PasswordTextFieldSwitcher";

function RegisterForm({onSubmitSuccess, ...rest}) {
    return (
        <Formik
            initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                policy: false
            }}
            validationSchema={Yup.object().shape({
                firstName: Yup.string().max(255).required(),
                lastName: Yup.string().max(255).required(),
                email: Yup.string().email().max(255).required(),
                password: Yup.string().min(8).max(50).required().matches(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    "Bitte geben Sie min. 1 Großbuchstabe (A-Z), 1 Kleinbuchstabe (a-z), 1 Zahl (0-9) und 1 Sonderzeichen (@$!%*#?&) ein."
                ),
                policy: Yup.boolean().oneOf([true], FORM.MESSAGES.INVALID_AGB_DATENSCHUTZ)
            })}
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                await axios.post('/api/open/users', {
                    "username": values.email,
                    "password": values.password,
                    "vorname": values.firstName,
                    "nachname": values.lastName,
                    "telefon": ''
                }).then((response) => {
                    onSubmitSuccess();
                }).catch((error) => {
                    error = (error.response && error.response.data.message) || 'Es ist ein unerwarteter Fehler aufgetreten.';

                    setStatus({success: false});
                    setErrors({submit: error});
                    setSubmitting(false);
                });
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
              }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...rest}
                >
                    <TextField
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        label="Vorname"
                        margin="normal"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="firstName"
                        value={values.firstName}
                        variant="outlined"
                    />
                    <TextField
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        label="Nachname"
                        margin="normal"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="lastName"
                        value={values.lastName}
                        variant="outlined"
                    />
                    <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="E-Mail-Adresse"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="email"
                        value={values.email}
                        variant="outlined"
                    />
                    <PasswordTextFieldSwitcher
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Passwort"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.password}
                        variant="outlined"
                    />
                    <Box
                        alignItems="center"
                        display="flex"
                        mt={2}
                        ml={-1}
                    >
                        <Checkbox
                            checked={values.policy}
                            name="policy"
                            onChange={handleChange}
                        />
                        <Typography
                            variant="body2"
                            color="textSecondary"
                        >
                            Mit Ihrer Registrierung akzeptieren Sie unsere
                            {' '}
                            <Link
                                component="a"
                                href="https://www.investolio.de/de/agb"
                                color="secondary"
                                target="_blank"
                                underline="none">
                                Allgemeinen Geschäftsbedingungen und Datenschutzbestimmungen.
                            </Link>
                        </Typography>
                    </Box>
                    {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>
                            {errors.policy}
                        </FormHelperText>
                    )}
                    <Box mt={2}>
                        <SpinnerButton size="large" fullWidth isSubmitting={isSubmitting}>
                            Registrieren
                        </SpinnerButton>
                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}
                    </Box>
                </form>
            )}
        </Formik>
    );
}

RegisterForm.propTypes = {
    onSubmitSuccess: PropTypes.func
};

RegisterForm.default = {
    onSubmitSuccess: () => {
    }
};

export default RegisterForm;
