import React, {useCallback, useEffect, useState} from 'react';
import _ from "lodash";
import {Box, Container, Grid} from '@mui/material';
import {useParams} from "react-router";
import {Navigate} from 'react-router-dom';
import Page from 'src/components/Page';
import LoadingScreen from "src/components/LoadingScreen";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import axios from "../../../utils/axios";
import Header from './Header';
import VideoCard from "./VideoCard";

function VideoCourseDetailView() {
    const isMountedRef = useIsMountedRef();
    const {paramId} = useParams();
    const [videoCourse, setVideoCourse] = useState(null);

    const getVideoCourses = useCallback(() => {
        axios
            .get('/api/general/kurse')
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("kurse")) {
                        let videoCourseIndex = _.findIndex(response.data.payload.kurse, {id: parseInt(paramId)});

                        if (videoCourseIndex >= 0) {
                            setVideoCourse(response.data.payload.kurse[videoCourseIndex]);
                        } else {
                            setVideoCourse(false);
                        }
                    }
                }
            });
    }, [isMountedRef, paramId]);

    useEffect(() => {
        getVideoCourses();
    }, [getVideoCourses]);

    if (videoCourse === null) {
        return <LoadingScreen/>;
    }

    if (videoCourse === false) {
        return <Navigate to="/404"/>;
    }

    return (
        <Page title={"Videokurs - " + videoCourse.titel}>
            <Container maxWidth="lg" sx={{
                backgroundColor: 'background.dark',
                minHeight: '100%',
                paddingTop: 3,
                paddingBottom: 3
            }}>
                <Header videoCourse={videoCourse.titel}/>
                <Box mt={3}>
                    <Grid container spacing={3}>
                        {videoCourse.kursteile.map((video, index) => (
                            <Grid item key={index} md={6} xs={12}>
                                <VideoCard videoList={video}/>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
}

export default VideoCourseDetailView;
