import {combineReducers} from 'redux';
import accountReducer from './accountReducer';
import notificationsReducer from './notificationsReducer';
import compareReducer from "./compareReducer";

const rootReducer = combineReducers({
  account: accountReducer,
  compare: compareReducer,
  notifications: notificationsReducer
});

export default rootReducer;
