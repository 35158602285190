import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import AddStockDepotForm from "./AddStockDepotForm";
import axios from "src/utils/axios";
import useIsMountedRef from "src/hooks/useIsMountedRef";

function AddStockDepotModal({stock, onSubmit, onCancel, open, ...rest}) {
    const isMountedRef = useIsMountedRef();
    const [depots, setDepots] = useState(null);

    const getDepots = useCallback(() => {
        axios
            .get('/api/user/depots', {
                params: {
                    offset: 0,
                    limit: 30
                }
            })
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("depots")) {
                        setDepots(response.data.payload.depots);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getDepots();
    }, [getDepots]);

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={onCancel} {...rest}>
            {/* Dialog renders its body even if not open */}
            {open && (
                <AddStockDepotForm stock={stock} depots={depots} onCancel={onCancel} onSubmit={onSubmit}/>
            )}
        </Dialog>
    );
};

AddStockDepotModal.propTypes = {
    stock: PropTypes.object,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool
};

AddStockDepotModal.defaultProps = {
    onSubmit: () => {
    },
    onCancel: () => {
    },
    open: false
};

export default AddStockDepotModal;
