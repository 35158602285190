import React, {useState} from 'react';
import NavBar from './NavBar';
import TopBar from './TopBar';
import {Outlet} from "react-router-dom";
import {Box} from "@mui/material";

function DashboardLayout({children}) {
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    return (
        <Box sx={{
            backgroundColor: 'background.dark',
            display: 'flex',
            height: '100%',
            overflow: 'hidden',
            width: '100%'
        }}>
            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)}/>
            <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
            />
            <Box sx={{
                display: 'flex',
                flex: '1 1 auto',
                overflow: 'hidden',
                paddingTop: 8,
                paddingLeft: {xs: 0, lg: 32},
            }}>
                <Box sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    overflow: 'hidden'
                }}>
                    <Box sx={{
                        flex: '1 1 auto',
                        height: '100%',
                        overflow: 'auto',
                    }}>
                        {children}
                        <Outlet/>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

DashboardLayout.propTypes = {};

export default DashboardLayout;
