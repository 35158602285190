/* eslint-disable no-template-curly-in-string */
import numeral from 'numeral';

// load a locale
numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: ' Tsd.',
    million: ' Mio.',
    billion: ' Mrd.',
    trillion: ' Bil.'
  },
  ordinal: function (number) {
    return '.';
  },
  currency: {
    symbol: '€'
  }
});

// switch between locales
numeral.locale('de');

export default numeral;
