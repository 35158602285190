import React from 'react';
import {
  Typography,
  Grid
} from '@mui/material';

function Header({...rest}) {

  return (
    <Grid
      container
      spacing={3}
      justifyContent="space-between"
      {...rest}
    >
      <Grid item>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          News-Archiv
        </Typography>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
};

export default Header;
