import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    MenuItem,
    Select
} from '@mui/material';
import axios from "src/utils/axios";
import useIsMountedRef from 'src/hooks/useIsMountedRef';

function IndexSelect({value, ...rest}) {
    const isMountedRef = useIsMountedRef();
    const [indexes, setIndexes] = useState(null);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
            },
        },
    };

    const getIndexes = useCallback(() => {
        axios
            .get('/api/general/indexes')
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("indexes")) {
                        setIndexes(response.data.payload.indexes);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getIndexes();
    }, [getIndexes]);

    return (
        <Select
            label="Index"
            name="Index_kurzList"
            variant="outlined"
            multiple
            MenuProps={MenuProps}
            value={indexes ? value : []}
            {...rest}
        >
            {(!indexes || indexes.length === 0) && <MenuItem value="" disabled>Keine Auswahl vorhanden</MenuItem>}
            {indexes && indexes.length > 0 && <MenuItem value=""><em>Keine Auswahl</em></MenuItem>}
            {
                indexes && indexes.length > 0 &&
                indexes.map(index => (
                    <MenuItem key={index.Index_kurz} value={index.Index_kurz}>
                        {index["Index_kurz:bez"]}
                    </MenuItem>
                ))
            }
        </Select>
    );
}

IndexSelect.propTypes = {
    value: PropTypes.any.isRequired
};

export default IndexSelect;
