function getChartScoreColor(name) {
  let color = null;
  let backgroundColor = null;

  switch (name) {
    case 'trendScore':
      color = 'rgba(112, 159, 178)';
      backgroundColor = 'rgba(112, 159, 178, 0.4)';
      break;

    case 'risikoScore':
      color = 'rgba(68, 170, 181)';
      backgroundColor = 'rgba(68, 170, 181, 0.4)';
      break;

    case 'technischerScore':
      color = 'rgba(119, 85, 161)';
      backgroundColor = 'rgba(119, 85, 161, 0.4)';
      break;

    case 'valueScore':
      color = 'rgba(0, 175, 243)';
      backgroundColor = 'rgba(0, 175, 243, 0.4)';
      break;

    case 'kombiScore':
      color = 'rgba(26, 104, 185)';
      backgroundColor = 'rgba(26, 104, 185, 0.4)';
      break;

    default:
      break;
  }

  return {'color': color, 'backgroundColor': backgroundColor};
}

function getColorByValue(name, value) {
  let color = null;
  let backgroundColor = null;
  let colorScores = ['trendScore', 'risikoScore', 'technischerScore', 'valueScore', 'kombiScore'];

  if (!colorScores.includes(name)) {
    return {};
  }

  if (value <= 0.2) {
    color = '#bb273f';
    backgroundColor = '#ffefef';
  } else if (value <= 0.4) {
    color = '#e2981f';
    backgroundColor = '#fff8eb';
  } else if (value <= 0.6) {
    color = '#c3b924';
    backgroundColor = '#f8fbde';
  } else if (value <= 0.8) {
    color = '#51ae2a';
    backgroundColor = '#f3f8f2';
  } else if (value <= 1) {
    color = '#51ae2a';
    backgroundColor = '#e0f4db';
  }

  return {'color': color, 'backgroundColor': backgroundColor};
}

export default {
  getColorByValue, getChartScoreColor
};
