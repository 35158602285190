import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {MenuItem, Select} from '@mui/material';
import axios from "src/utils/axios";
import useIsMountedRef from 'src/hooks/useIsMountedRef';

function DepotMultiselect({value, ...rest}) {
    const isMountedRef = useIsMountedRef();
    const [depots, setDepots] = useState(null);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
            },
        },
    };

    const getDepots = useCallback(() => {
        axios
            .get('/api/user/depots')
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("depots")) {
                        setDepots(response.data.payload.depots);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getDepots();
    }, [getDepots]);

    return (
        <Select
            label="Depots"
            name="excludeDepotIdList"
            variant="outlined"
            MenuProps={MenuProps}
            multiple
            value={depots ? value : []}
            {...rest}
        >
            {(!depots || depots.length === 0) && <MenuItem value="" disabled>Keine Auswahl vorhanden</MenuItem>}
            {depots && depots.length > 0 && <MenuItem value=""><em>Keine Auswahl</em></MenuItem>}
            {
                depots && depots.length > 0 &&
                depots.map(depot => (
                    <MenuItem key={depot.id} value={depot.id}>
                        {depot["bezeichnung"]}
                    </MenuItem>
                ))
            }
        </Select>
    );
}

DepotMultiselect.propTypes = {
    value: PropTypes.any.isRequired
};

export default DepotMultiselect;
