import React, {useState} from 'react';
import {Link as RouterLink, useParams} from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    Container,
    Divider,
    Link,
    Typography
} from '@mui/material';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import LegalNoteLinkList from "src/components/LegalNoteLinkList";
import PasswordResetForm from './PasswordResetForm';

function PasswordResetView() {
    const {email, token} = useParams();
    const [success, setSuccess] = useState(null);

    const handleSubmitSuccess = () => {
        setSuccess(true);
    };

    return (
        <Page
            title="Passwort vergessen"
            style={{
                justifyContent: 'center',
                backgroundColor: 'background.dark',
                display: 'flex',
                height: '100%',
                minHeight: '100vh',
                flexDirection: 'column',
                paddingBottom: 80,
                paddingTop: 80
            }}
        >
            <Container maxWidth="sm">
                <Box
                    mb={5}
                    display="flex"
                    alignItems="center"
                >
                    <RouterLink to="/">
                        <Logo invert/>
                    </RouterLink>
                </Box>
                <Card>
                    <CardContent>
                        {
                            success === true &&
                            <>
                                <Typography
                                    gutterBottom
                                    variant="h2"
                                    color="textPrimary"
                                >
                                    Passwort geändert
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Wir haben Ihr neues Passwort erfolgreich gespeichert.
                                </Typography>
                                <Box mt={3}>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        Sie können sich jetzt mit Ihrer E-Mail-Adresse und Ihrem neuen Passwort <Link component={RouterLink} to="/login"
                                                                                                                      underline="none"> anmelden</Link>.
                                    </Typography>
                                </Box>
                                <Box my={2}>
                                    <Divider/>
                                </Box>
                                <Link
                                    component={RouterLink}
                                    to="/login"
                                    variant="body2"
                                    color="textSecondary"
                                    underline="none">
                                    Jetzt anmelden
                                </Link>
                            </>
                        }
                        {
                            !success &&
                            <>
                                <Typography
                                    gutterBottom
                                    variant="h2"
                                    color="textPrimary"
                                >
                                    Passwort ändern
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    Bitte geben Sie hier Ihr neues Passwort ein.
                                </Typography>
                                <Box mt={3}>
                                    <PasswordResetForm user={email} token={token} onSubmitSuccess={handleSubmitSuccess}/>
                                </Box>
                                <Box my={2}>
                                    <Divider/>
                                </Box>
                                <Link
                                    component={RouterLink}
                                    to="/login"
                                    variant="body2"
                                    color="textSecondary"
                                    underline="none">
                                    Jetzt anmelden
                                </Link>
                            </>
                        }
                    </CardContent>
                </Card>
            </Container>
            <Box display="flex" justifyContent="center" mt={2}>
                <LegalNoteLinkList/>
            </Box>
        </Page>
    );
}

export default PasswordResetView;
