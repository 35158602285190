import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, ListItem} from '@mui/material';
import NavLink from "src/components/NavLink";

function NavItem({title, href, depth, children, icon: Icon, open: openProp, info: Info, ...rest}) {
    const classes = [];
    const [open, setOpen] = useState(openProp);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const style = {paddingLeft};

    return (<ListItem
        disableGutters
        sx={{
            display: 'flex',
            paddingTop: 0,
            paddingBottom: 0
        }}
        key={title}
        {...rest}
    >
        <Button
            activeClassName="active"
            sx={{
                '&.active': {
                    color: 'secondary.main',
                    '& div': {
                        fontWeight: 'medium',
                    },
                    '& svg': {
                        color: 'secondary.main',
                    },
                },
                '& div': {
                    marginLeft: 1
                },
                color: 'text.secondary',
                padding: '10px 8px',
                justifyContent: 'flex-start',
                textTransform: 'none',
                letterSpacing: 0,
                width: '100%',
                fontWeight: 'medium',
            }}
            component={NavLink}
            exact="true"
            style={style}
            to={href}
        >
            {Icon && <Icon
                size="20"
            />}
            <div>{title}</div>
            {Info && <Info/>}
        </Button>
    </ListItem>);
}

NavItem.propTypes = {
    children: PropTypes.node,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.any,
    info: PropTypes.any,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
    open: false
};

export default NavItem;
