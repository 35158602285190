import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Typography, Breadcrumbs, Link} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Header({...rest}) {
    return (
        <div {...rest}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                <Link color="inherit" to="/help" underline="none" component={RouterLink}>Hilfe</Link>
                <Typography color="textPrimary">Videokurse</Typography>
            </Breadcrumbs>
            <Typography variant="h3" color="textPrimary">Videokurse</Typography>
        </div>
    );
}

export default Header;
