import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import AddEditRankingForm from "./AddEditRankingForm";
import {useFormikContext} from "formik";

function AddEditRankingModal({ranking, onSubmit, onCancel, open, ...rest}) {
    const {values} = useFormikContext();

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={onCancel} {...rest}>
            {/* Dialog renders its body even if not open */}
            {open && (
                <AddEditRankingForm ranking={ranking} rankingFilterOptions={values} onCancel={onCancel} onSubmit={onSubmit}/>
            )}
        </Dialog>
    );
};

AddEditRankingModal.propTypes = {
    ranking: PropTypes.object,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool
};

AddEditRankingModal.defaultProps = {
    onSubmit: () => {
    },
    onCancel: () => {
    },
    open: false
};

export default AddEditRankingModal;
