import jwt_decode from 'jwt-decode';
import axios from 'src/utils/axios';
import {history} from 'src/App';

class AuthService {
    setAxiosInterceptors = ({onLogout}) => {
        axios.interceptors.response.use(
            (response) => response,
            (error) => {

                if (error.response && error.response.status === 401) {
                    this.setSession(null);


                    if (onLogout) {
                        onLogout();
                        history.push('/login');
                    }
                }

                if (error.response.status === 404) {
                    history.push('/404');
                }

                return Promise.reject(error);
            }
        );
    };

    handleAuthentication() {
        const accessToken = this.getAccessToken();

        if (!accessToken) {
            return;
        }

        if (this.isValidToken(accessToken)) {
            this.setSession(accessToken);
        } else {
            this.setSession(null);
        }
    }

    loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
        axios.post('/api/login_check', {username: email, password})
            .then((response) => {
                if (response.data.user) {
                    this.setSession(response.data.token);
                    resolve(response.data.user);
                } else {
                    reject(response.data.error);
                }
            })
            .catch((error) => {
                reject(error);
            });
    })

    loginInWithToken = () => new Promise((resolve, reject) => {
        axios.get('/api/user/me')
            .then((response) => {
                if (response.data.payload.hasOwnProperty("user")) {
                    resolve(response.data.payload.user);
                } else {
                    reject(response.data.error);
                }
            })
            .catch((error) => {
                reject(error);
            });
    })

    logout = () => {
        this.setSession(null);
    }

    setSession = (accessToken) => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        } else {
            localStorage.removeItem('accessToken');
            delete axios.defaults.headers.common.Authorization;
        }
    }

    getAccessToken = () => localStorage.getItem('accessToken');

    isValidToken = (accessToken) => {
        if (!accessToken) {
            return false;
        }

        try {
            const decoded = jwt_decode(accessToken);
            const currentTime = Date.now() / 1000;

            return decoded.exp > currentTime;
        } catch (error) {
            return false;
        }
    };

    isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
