//import {fade} from "@mui/material";

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const FORM = {
  MESSAGES: {
    INVALID_AGB_DATENSCHUTZ: 'Bitte akzeptieren Sie unsere Allgemeinen Geschäftsbedingungen und Datenschutzbestimmungen.'
  }
};

export const LIMITS = {
  MAX_STOCK_COMPARES: 3
};

/*
export const SCORES = {
  'kombiScore': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'technischerScore': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'trendScore': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'smaRatio': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'deltaSma': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'hochTiefRatio': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'risikoScore': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'verlustVola': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'cdd': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'upDownDays': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'qnr': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'valueScore': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'kgv': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'kuv': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'ebitev': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'roc': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'modRoa': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'grMarg': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
  'netMarg': {
    RANGES: [
      {
        MIN: 0,
        MAX: 0.4,
        COLOR: '#f44336',
        BG_COLOR: fade('#f44336', 0.08)
      },
      {
        MIN: 0.4,
        MAX: 0.7,
        COLOR: '#ff9800',
        BG_COLOR: fade('#ff9800', 0.08)
      },
      {
        MIN: 0.7,
        MAX: 1,
        COLOR: '#4caf50',
        BG_COLOR: fade('#4caf50', 0.08)
      },
    ]
  },
};
*/
