import {Box, Card, CardContent, CardHeader, Container} from '@mui/material';
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Moment from "moment";
import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router";
import {Navigate} from 'react-router-dom';
import {useParams} from "react-router-dom";
import CustomDataGrid from "src/components/CustomDataGrid";
import DangerouslyHtml from "src/components/DangerouslyHtml";
import LoadingScreen from "src/components/LoadingScreen";
import Page from 'src/components/Page';
import ScoreLabel from "src/components/ScoreLabel";
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import numeral from "src/mixins/numeral";
import axios from 'src/utils/axios';
import {getTitle} from "src/utils/meta";
import Chart from "./Chart";
import Header from './Header';
import {useGridApiRef} from "@mui/x-data-grid-pro";

function InvestmentStrategyDetailView() {
    const apiRef = useGridApiRef();
    const isMountedRef = useIsMountedRef();
    const navigate = useNavigate();
    const {paramIndex} = useParams();
    const [investmentstrategie, setInvestmentstrategie] = useState(null);

    const getInvestmentstrategien = useCallback(() => {
        axios
            .get('/api/general/investmentstrategien')
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("investmentstrategien")) {
                        let investmentstrategien = response.data.payload.investmentstrategien;

                        if (investmentstrategien[paramIndex]) {
                            investmentstrategien[paramIndex]["chartBenchmarkPerformance"] = investmentstrategien[paramIndex]["benchmark"]["performance"].map((value) => {
                                return {
                                    x: value.datum,
                                    y: value.wert
                                }
                            });

                            investmentstrategien[paramIndex]["chartDepotPerformance"] = investmentstrategien[paramIndex]["strategie"]["performance"].map((value) => {
                                return {
                                    x: value.datum,
                                    y: value.wert
                                }
                            });

                            setInvestmentstrategie(investmentstrategien[paramIndex]);
                        } else {
                            setInvestmentstrategie(false);
                        }
                    }
                }
            });
    }, [isMountedRef, paramIndex]);

    useEffect(() => {
        getInvestmentstrategien();
    }, [getInvestmentstrategien]);

    if (investmentstrategie === null) {
        return <LoadingScreen/>;
    }

    if (investmentstrategie === false) {
        return <Navigate to="/404"/>;
    }

    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle(investmentstrategie["Bezeichnung"] + " - Investmentstrategien")}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth="lg">
                <Header investmentstrategie={investmentstrategie["Bezeichnung"]}/>
                <Box mt={1}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Performance</TableCell>
                                            <TableCell>Strategie</TableCell>
                                            <TableCell>Benchmark</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Performance seit Auflage</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["strategie"]["perf_auflage"]).format('0.00 %')}</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["benchmark"]["perf_auflage"]).format('0.00 %')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Performance 3 Monate</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["strategie"]["perf_3monate"]).format('0.00 %')}</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["benchmark"]["perf_3monate"]).format('0.00 %')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Volatilität</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["strategie"]["volatilitaet"]).format('0.00 %')}</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["benchmark"]["volatilitaet"]).format('0.00 %')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Maximaler Drawdown</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["strategie"]["max_drawdown"]).format('0.00 %')}</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["benchmark"]["max_drawdown"]).format('0.00 %')}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Backtestergebnisse</TableCell>
                                            <TableCell>Strategie</TableCell>
                                            <TableCell>Benchmark</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Jährliche Rendite seit 2002</TableCell>
                                            <TableCell
                                                align="right">{numeral(investmentstrategie["strategie"]["bt_rendite_jaehrlich"]).format('0.00 %')}</TableCell>
                                            <TableCell
                                                align="right">{numeral(investmentstrategie["benchmark"]["bt_rendite_jaehrlich"]).format('0.00 %')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Volatilität</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["strategie"]["bt_volatilitaet"]).format('0.00 %')}</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["benchmark"]["bt_volatilitaet"]).format('0.00 %')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Maximaler Drawdown</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["strategie"]["bt_max_drawdown"]).format('0.00 %')}</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["benchmark"]["bt_max_drawdown"]).format('0.00 %')}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Depotumschlagsfaktor</TableCell>
                                            <TableCell align="right">{numeral(investmentstrategie["strategie"]["bt_umschlag"]).format('0.00')}</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Chart dataDepot={investmentstrategie["chartDepotPerformance"]} dataBenchmark={investmentstrategie["chartBenchmarkPerformance"]}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader title="Strategie"/>
                                <Divider/>
                                <CardContent>
                                    <DangerouslyHtml html={investmentstrategie["beschreibung"]}/>
                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card>
                                <CardHeader title="Aktuelle Depotzusammensetzung"/>
                                <Divider/>
                                <CustomDataGrid
                                    apiRef={apiRef}
                                    configSettingKey="investment_strategy_detail"
                                    onRowClick={(params) => {
                                        navigate(`/stocks/search/${params.row.Index_kurz}/${params.row.ISIN}`);
                                    }}
                                    columns={[
                                        {
                                            headerName: "Name",
                                            field: "Aktienname",
                                            defaultSort: 'asc',
                                            width: 300,
                                        },
                                        {
                                            headerName: "ISIN",
                                            field: "ISIN",
                                        },
                                        {
                                            headerName: "Sektor",
                                            field: "Sector:bez",
                                            sortable: false
                                        },
                                        {
                                            headerName: "Index",
                                            field: "Index_kurz:bez",
                                            sortable: false,
                                        },
                                        {
                                            headerName: "Kurs",
                                            field: "Kurs",
                                            type: "number",
                                            renderCell: params => numeral(params.row.Kurs).format('0.00') + " " + params.row["Currency:symbol"]
                                        },
                                        {
                                            headerName: "Trend",
                                            field: "Trend_Score",
                                            type: "number",
                                            width: 90,
                                            renderCell: params => <ScoreLabel key="trendScore" name="trendScore" value={params.row.Trend_Score}/>
                                        },
                                        {
                                            headerName: "Safety",
                                            field: "Risiko_Score",
                                            type: "number",
                                            width: 90,
                                            renderCell: params => <ScoreLabel key="risikoScore" name="risikoScore" value={params.row.Risiko_Score}/>
                                        },
                                        {
                                            headerName: "Tech",
                                            field: "Technisch_Score",
                                            type: "number",
                                            width: 90,
                                            renderCell: params => <ScoreLabel key="technischerScore" name="technischerScore"
                                                                              value={params.row.Technisch_Score}/>
                                        },
                                        {
                                            headerName: "Value",
                                            field: "Value_Score",
                                            type: "number",
                                            width: 90,
                                            renderCell: params => <ScoreLabel key="valueScore" name="valueScore" value={params.row.Value_Score}/>
                                        },
                                        {
                                            headerName: "Kombi",
                                            field: "Kombi_Score",
                                            type: "number",
                                            width: 90,
                                            renderCell: params => <ScoreLabel key="kombiScore" name="kombiScore" value={params.row.Kombi_Score}/>
                                        },
                                        {
                                            headerName: "Marktkapitalisierung",
                                            field: "MarketCap",
                                            type: "number",
                                            width: 175,
                                            renderCell: params => numeral(params.row.MarketCap).format('(0,0)') + " Mio. €",
                                        },
                                        {
                                            headerName: "Handelsvolumen",
                                            field: "Handelsvolumen",
                                            type: "number",
                                            width: 175,
                                            renderCell: params => numeral(params.row.Handelsvolumen).format('(0,0)') + " Tsd. €",
                                        },
                                        {
                                            headerName: "Kaufkurs",
                                            field: "Kaufkurs",
                                            type: "number",
                                            minWidth: 90,
                                            renderCell: params => numeral(params.row.Kaufkurs).format('0.00') + " " + params.row["Currency:symbol"]
                                        },
                                        {
                                            headerName: "Kaufwert",
                                            field: "Kaufpreis:calc",
                                            type: "number",
                                            minWidth: 90,
                                            renderCell: params => numeral(params.row['Kaufpreis:calc']).format('0,0.00') + " " + params.row["Currency:symbol"]
                                        },
                                        {
                                            headerName: "Stück",
                                            field: "quantity",
                                            type: "number",
                                            minWidth: 90,
                                        },
                                        {
                                            headerName: "Wert",
                                            field: "Kurspreis:calc",
                                            type: "number",
                                            minWidth: 90,
                                            renderCell: params => numeral(params.row['Kurspreis:calc']).format('0,0.00') + " " + params.row["Currency:symbol"]
                                        },
                                        {
                                            headerName: "Performance",
                                            field: "Kursperformance:calc",
                                            type: "number",
                                            minWidth: 130,
                                            renderCell: params => numeral(params.row["Kursperformance:calc"]).format('0.0 %')
                                        },
                                    ]}
                                    data={investmentstrategie["depot_aktien"]}
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                "ISIN": false,
                                                "Sector:bez": false,
                                                "Index_kurz:bez": false,
                                                "MarketCap": false,
                                                "Handelsvolumen": false,
                                                "Kaufpreis:calc": false
                                            },
                                        },
                                        sorting: {
                                            sortModel: [{field: 'Aktienname', sort: 'asc'}],
                                        },
                                    }}
                                />
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            <Card>
                                <CardHeader title="Letzte Trades"/>
                                <Divider/>
                                <CustomDataGrid
                                    apiRef={apiRef}
                                    configSettingKey="investment_strategy_detail_trades"
                                    onRowClick={(params) => {
                                        navigate(`/stocks/search/${params.row.Index_kurz}/${params.row.ISIN}/${Moment(params.row.datum).format('YYYY-MM-DD')}`);
                                    }}
                                    columns={[
                                        {
                                            headerName: "Kauf/Verkauf",
                                            field: "Transaktionstyp",
                                            minWidth: 130,
                                        },
                                        {
                                            headerName: "Aktienname",
                                            field: "Aktienname",
                                            flex: 1,
                                        },
                                        {
                                            headerName: "ISIN",
                                            field: "ISIN",
                                        },
                                        {
                                            headerName: "Kauf-/Verkaufskurs",
                                            field: "Transaktionskurs",
                                            type: "number",
                                            minWidth: 150,
                                            renderCell: params => numeral(params.row.Transaktionskurs).format('0.00')
                                        },
                                        {headerName: "Stück", field: "quantity", type: "number"},
                                        {
                                            headerName: "Datum",
                                            field: "datum",
                                            type: "date",
                                            defaultSort: 'desc',
                                            minWidth: 130,
                                            renderCell: params => Moment(params.row["datum"]).format('DD.MM.YYYY')
                                        },
                                        {
                                            headerName: "Performance",
                                            field: "performance",
                                            type: "number",
                                            minWidth: 130,
                                            renderCell: params => numeral(params.row["performance"]).format('0.00 %')
                                        },
                                    ]}
                                    getRowId={(row: any) => row.ISIN + row.datum}
                                    data={investmentstrategie["trades"]}
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                "ISIN": false,
                                            },
                                        },
                                        sorting: {
                                            sortModel: [{field: 'datum', sort: 'desc'}],
                                        },
                                    }}
                                />
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
}

export default InvestmentStrategyDetailView;
