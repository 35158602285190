import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {MenuItem, Select} from '@mui/material';
import axios from "src/utils/axios";
import useIsMountedRef from 'src/hooks/useIsMountedRef';

function SectorSelect({value, ...rest}) {
    const isMountedRef = useIsMountedRef();
    const [sectors, setSectors] = useState(null);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
            },
        },
    };

    const getSectors = useCallback(() => {
        axios
            .get('/api/general/sectors')
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("sectors")) {
                        setSectors(response.data.payload.sectors);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getSectors();
    }, [getSectors]);

    return (
        <Select
            label="Sektor"
            name="SectorList"
            variant="outlined"
            MenuProps={MenuProps}
            multiple
            value={sectors ? value : []}
            {...rest}
        >
            {(!sectors || sectors.length === 0) && <MenuItem value="" disabled>Keine Auswahl vorhanden</MenuItem>}
            {sectors && sectors.length > 0 && <MenuItem value=""><em>Keine Auswahl</em></MenuItem>}
            {
                sectors && sectors.length > 0 &&
                sectors.map(sector => (
                    <MenuItem key={sector.Sector} value={sector.Sector}>
                        {sector["Sector:bez"]}
                    </MenuItem>
                ))
            }
        </Select>
    );
}

SectorSelect.propTypes = {
    value: PropTypes.any.isRequired
};

export default SectorSelect;
