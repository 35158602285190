import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Button, Card, CardContent, CardHeader, Divider, FormHelperText, Grid, Switch, TextField, Typography} from '@mui/material';
import Yup from 'src/mixins/yup';
import {updateProfile} from "src/actions/accountActions";
import {Formik} from "formik";
import {useSnackbar} from "notistack";

function General({...rest}) {
    const account = useSelector((state) => state.account);
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    return (
        <Formik
            enableReinitialize
            initialValues={{
                email: account.user.username,
                firstName: account.user.vorname,
                lastName: account.user.nachname
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email().max(50).required(),
                firstName: Yup.string().max(50).required(),
                lastName: Yup.string().max(50).required()
            })}
            onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                try {
                    await dispatch(updateProfile({
                        "vorname": values.firstName,
                        "nachname": values.lastName
                    }));
                    //resetForm();
                    setStatus({success: true});
                    enqueueSnackbar('Profil gespeichert', {
                        variant: 'success'
                    });
                } catch (error) {
                    setStatus({success: false});
                    setErrors({submit: error.message});
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
              }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...rest}
                >
                    <Card>
                        <CardHeader title="Persönliche Daten"/>
                        <Divider/>
                        <CardContent>
                            <Grid
                                container
                                spacing={4}
                            >
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(touched.firstName && errors.firstName)}
                                        fullWidth
                                        helperText={touched.firstName && errors.firstName}
                                        label="Vorname"
                                        name="firstName"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                        type="firstName"
                                        value={values.firstName}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(touched.lastName && errors.lastName)}
                                        fullWidth
                                        helperText={touched.lastName && errors.lastName}
                                        label="Nachname"
                                        name="lastName"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                        type="lastName"
                                        value={values.lastName}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    xs={12}
                                >
                                    <TextField
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        label="E-Mail-Adresse"
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        required
                                        type="email"
                                        value={values.email}
                                        variant="outlined"
                                        disabled
                                    />
                                </Grid>
                                {
                                    false && <>

                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h6"
                                                color="textPrimary"
                                            >
                                                Neues von investolio
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                Mit diesem Newsletter informieren wir Sie in unregelmäßigen Abständen über Neuerungen auf unserer Seite und stellen
                                                Ihnen ausgewählte
                                                Hilfethemen und Wissenswertes rund über unsere Strategien und Scoring-Modelle zur Verfügung.
                                            </Typography>
                                            <Switch checked={values.newsletter1} edge="start" name="newsletter1"/>
                                        </Grid>
                                        <Grid item md={6} xs={12}>
                                            <Typography
                                                variant="h6"
                                                color="textPrimary"
                                            >
                                                investolio Wochenupdate
                                            </Typography>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                Dieser Newsletter informiert Sie wöchentlich über die Entwicklung der Aktienmärkte, versorgt Sie mit einem aktuellen
                                                Marktbericht und
                                                liefert Ihnen interessante Aktienmarktstatistiken.
                                            </Typography>
                                            <Switch checked={values.newsletter2} edge="start" name="newsletter2"/>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                            {errors.submit && (
                                <Box mt={3}>
                                    <FormHelperText error>
                                        {errors.submit}
                                    </FormHelperText>
                                </Box>
                            )}
                        </CardContent>
                        <Divider/>
                        <Box
                            p={2}
                            display="flex"
                            justifyContent="flex-end"
                        >
                            <Button
                                color="secondary"
                                disabled={isSubmitting}
                                type="submit"
                                variant="contained"
                            >
                                Speichern
                            </Button>
                        </Box>
                    </Card>
                </form>
            )}
        </Formik>
    );
}

General.propTypes = {};

export default General;
