import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
    Typography,
    Breadcrumbs,
    Link,
    Grid, Button, SvgIcon
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {PlusCircle as PlusCircleIcon} from "react-feather";

function Header({...rest}) {
    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            {...rest}
        >
            <Grid item>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small"/>}
                    aria-label="breadcrumb"
                >
                    <Link color="inherit" to="/account" underline="none" component={RouterLink}>
                        Aktien
                    </Link>
                    <Typography color="textPrimary">
                        Vergleich
                    </Typography>
                </Breadcrumbs>
                <Typography
                    variant="h3"
                    color="textPrimary"
                >
                    Vergleich
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    color="secondary"
                    variant="contained"
                    sx={{
                        marginRight: 1
                    }}
                    component={RouterLink}
                    to="/stocks/search"
                >
                    <SvgIcon
                        fontSize="small"
                        sx={{
                            marginRight: 1
                        }}
                    >
                        <PlusCircleIcon/>
                    </SvgIcon>
                    Aktie hinzufügen
                </Button>
            </Grid>
        </Grid>
    );
}

Header.propTypes = {};

export default Header;
