import React from 'react';
import PropTypes from 'prop-types';
import {Box, Card, CardMedia, Typography} from '@mui/material';
import Vimeo from '@u-wave/react-vimeo';
import DangerouslyHtml from "src/components/DangerouslyHtml";

function VideoCard({videoList, ...rest}) {
    return (
        <Card {...rest}>
            <Box px={3} pb={2} pt={3}>
                <CardMedia>
                    <Vimeo video={videoList.vimeoVideoUrl} responsive/>
                </CardMedia>
                <Box mt={2}>
                    <Typography color="textPrimary" variant="h5">
                        {videoList.titel}
                    </Typography>
                </Box>
            </Box>
            <Box pb={2} px={3}>
                <DangerouslyHtml html={videoList.beschreibung}/>
            </Box>
        </Card>
    );
}

VideoCard.propTypes = {
    videoList: PropTypes.object.isRequired
};

export default VideoCard;
