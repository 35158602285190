import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Card,
    Container
} from '@mui/material';
import Page from 'src/components/Page';
import Header from './Header';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import LoadingScreen from "src/components/LoadingScreen";
import {useSnackbar} from "notistack";
import {getTitle} from "src/utils/meta";
import CustomDataGrid from "src/components/CustomDataGrid";
import {useNavigate} from "react-router";
import numeral from "src/mixins/numeral";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowModes,
    GridActionsCellItem
} from '@mui/x-data-grid';
import {useGridApiRef} from "@mui/x-data-grid-pro";

const getFilter = (ranking) => {
    let keyWhitelist = {
        "Index_kurzList:bez": "Index",
        "SectorList:bez": "Sektor",
        "MarketCap_min": "Marktkapitalisierung (min.)",
        "MarketCap_max": "Marktkapitalisierung (max.)",
        "Handelsvolumen_min": "Handelsvolumen (min.)",
        "Handelsvolumen_max": "Handelsvolumen (max.)",
        "Trend_Score_min": "Trend Score (min.)",
        "Trend_Score_max": "Trend Score (max.)",
        "Kombi_Score_min": "Kombi Score (min.)",
        "Kombi_Score_max": "Kombi Score (max.)",
        "Technisch_Score_min": "Technisch Score (min.)",
        "Technisch_Score_max": "Technisch Score (max.)",
        "Risiko_Score_min": "Safety Score (min.)",
        "Risiko_Score_max": "Safety Score (max.)",
        "Value_Score_min": "Value Score (min.)",
        "Value_Score_max": "Value Score (max.)",
    };

    if (ranking && ranking.filteroptions) {
        return (
            Object.keys(keyWhitelist).map(key => {
                let ret = null;

                if (ranking.filteroptions[key]) {
                    if (typeof ranking.filteroptions[key] === 'object') {
                        let value = Object.keys(ranking.filteroptions[key]).map(function (k) {
                            return ranking.filteroptions[key][k]
                        }).join(", ");
                        ret = <div key={key}>{keyWhitelist[key]}: {value}</div>;
                    } else {
                        let value = ranking.filteroptions[key];
                        value = typeof value === 'number' ? numeral(value).format('0,0.[00]') : value;
                        ret = <div key={key}>{keyWhitelist[key]}: {value}</div>;
                    }
                }

                return ret;
            })
        )
    }
};

function RankingListView() {
    const apiRef = useGridApiRef();
    const isMountedRef = useIsMountedRef();
    const [rankings, setRankings] = useState(null);
    const [rowModesModel, setRowModesModel] = useState({});
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();
    const getRankings = useCallback(() => {
        axios
            .get('/api/filter/filters', {
                params: {
                    offset: 0,
                    limit: 30
                }
            })
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("filters")) {
                        setRankings(response.data.payload.filters);
                    }
                }
            });
    }, [isMountedRef]);

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id) => () => {
        axios.delete('/api/filter/filters/' + id)
            .then((response) => {
                if (!response.data.payload.hasOwnProperty("count") || response.data.payload.count === 0) {
                    throw new Error(response.data.errorMessage);
                } else {
                    return axios
                        .get('/api/filter/filters', {
                            params: {
                                offset: 0,
                                limit: 30
                            }
                        })
                }
            }).then((response) => {
            if (response.data.payload.hasOwnProperty('filters')) {
                setRankings(response.data.payload.filters);
                enqueueSnackbar('Ranking gelöscht', {
                    variant: 'success'
                });
            } else {
                throw new Error(response.data.errorMessage)
            }
        }).catch((error) => {
            enqueueSnackbar('Ranking konnte nicht gelöscht werden: ' + error, {
                variant: 'error'
            });
        })
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });
    };

    const processRowUpdate = useCallback(
        (newRow, oldRow) =>
            new Promise(async (resolve, reject) => {
                axios.put('/api/filter/filters/' + newRow.id, {
                    "bezeichnung": newRow.bezeichnung,
                }).then((response) => {
                    if (!response.data.payload.hasOwnProperty("count")) {
                        resolve(oldRow);
                        enqueueSnackbar('Ranking "' + oldRow.bezeichnung + '" konnte nicht aktualisiert werden', {
                            variant: 'error'
                        });
                    } else {
                        return axios
                            .get('/api/filter/filters', {
                                params: {
                                    offset: 0,
                                    limit: 30
                                }
                            });
                    }

                }).then((response) => {
                    if (response.data.payload.hasOwnProperty("filters")) {
                        resolve(newRow);
                        setRankings(response.data.payload.filters);
                        enqueueSnackbar('Ranking "' + newRow.bezeichnung + '" aktualisiert', {
                            variant: 'success'
                        });
                    }
                });
            }),
        [],
    );

    useEffect(() => {
        getRankings();
    }, [getRankings]);

    if (!rankings) {
        return <LoadingScreen/>;
    }

    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle("Rankings")}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth={false}>
                <Header/>
                <Box mt={3}>
                    <Card>
                        <CustomDataGrid
                            apiRef={apiRef}
                            columns={[
                                {
                                    headerName: "Name",
                                    field: "bezeichnung",
                                    flex: 1,
                                    editable: true
                                },
                                {
                                    headerName: "Suchfilter",
                                    field: "Kaufkurs",
                                    flex: 1,
                                    sortable: false,
                                    renderCell: params => {
                                        return <div>{getFilter(params.row)}</div>
                                    }
                                },
                                {
                                    field: 'actions',
                                    type: 'actions',
                                    width: 100,
                                    getActions: ({id}) => {
                                        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                                        if (isInEditMode) {
                                            return [
                                                <GridActionsCellItem
                                                    icon={<SaveIcon/>}
                                                    label="Save"
                                                    onClick={handleSaveClick(id)}
                                                />,
                                                <GridActionsCellItem
                                                    icon={<CancelIcon/>}
                                                    label="Cancel"
                                                    className="textPrimary"
                                                    onClick={handleCancelClick(id)}
                                                    color="inherit"
                                                />,
                                            ];
                                        }

                                        return [
                                            <GridActionsCellItem
                                                icon={<EditIcon/>}
                                                label="Edit"
                                                onClick={handleEditClick(id)}
                                            />,
                                            <GridActionsCellItem
                                                icon={<DeleteIcon/>}
                                                label="Delete"
                                                onClick={handleDeleteClick(id)}
                                            />,
                                        ];
                                    }
                                }
                            ]}
                            data={rankings}
                            initialState={{
                                sorting: {
                                    sortModel: [{field: 'bezeichnung', sort: 'asc'}],
                                },
                            }}
                            configSettingKey="ranking"
                            onRowClick={(params) => navigate(`/stocks/rankings/${params.row.id}`)}
                            editMode="row"
                            rowModesModel={rowModesModel}
                            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                            processRowUpdate={processRowUpdate}
                        />
                    </Card>
                </Box>
            </Container>
        </Page>
    );
}

export default RankingListView;
