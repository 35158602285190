import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Breadcrumbs, Grid, Link, Typography} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Header({investmentstrategie, ...rest}) {
    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            {...rest}
        >
            <Grid item>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small"/>}
                    aria-label="breadcrumb"
                >
                    <Link
                        variant="body1"
                        color="inherit"
                        to="/investment"
                        component={RouterLink}
                        underline="none">
                        Investment
                    </Link>
                    <Link
                        variant="body1"
                        color="inherit"
                        to="/investment/strategy"
                        component={RouterLink}
                        underline="none">
                        Investmentstrategien
                    </Link>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                    >
                        {investmentstrategie}
                    </Typography>
                </Breadcrumbs>
                <Typography
                    variant="h3"
                    color="textPrimary"
                >
                    {investmentstrategie}
                </Typography>
            </Grid>
        </Grid>
    );
}

Header.propTypes = {
    investmentstrategie: PropTypes.string.isRequired
};

export default Header;
