import {applyMiddleware, createStore, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createLogger} from 'redux-logger';
import rootReducer from 'src/reducers';
import config from 'src/config';

import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const loggerMiddleware = createLogger();

export function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware];

  if (config.ENABLE_REDUX_LOGGER) {
    middlewares.push(loggerMiddleware);
  }

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const persistConfig = {
    key: 'root',
    storage,
    // https://github.com/rt2zz/redux-persist#basic-usage
    whitelist: ['compare'] // only compare will be persisted
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer, preloadedState, composedEnhancers);
  const persistor = persistStore(store);

  return {store, persistor};
}
