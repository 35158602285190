import React from 'react';
import {useNavigate} from 'react-router';
import Yup from 'src/mixins/yup';
import {Formik} from 'formik';
import {useSnackbar} from 'notistack';
import {
    Box,
    Card,
    CardContent,
    FormHelperText,
    Grid,
    TextField
} from '@mui/material';
import axios from "src/utils/axios";
import SpinnerButton from "src/components/SpinnerButton";

function DepotCreateForm({...rest}) {
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    return (
        <Formik
            initialValues={{
                name: '',
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().max(255).required()
            })}
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting
            }) => {

                await axios.post('/api/user/depots', {
                    bezeichnung: values.name,
                }).then((response) => {
                    setStatus({success: true});
                    setSubmitting(false);
                    enqueueSnackbar('Depot angelegt', {
                        variant: 'success'
                    });
                    navigate('/depot');
                }).catch((error) => {
                    error = (error.response && error.response.data.message) || 'Es ist ein unerwarteter Fehler aufgetreten.';

                    setStatus({success: false});
                    setErrors({submit: error});
                    setSubmitting(false);
                });
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values
              }) => (
                <form
                    onSubmit={handleSubmit}
                    {...rest}
                >
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            lg={8}
                        >
                            <Card>
                                <CardContent>
                                    <TextField
                                        error={Boolean(touched.name && errors.name)}
                                        fullWidth
                                        helperText={touched.name && errors.name}
                                        label="Name des Depots"
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        variant="outlined"
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    {errors.submit && (
                        <Box mt={3}>
                            <FormHelperText error>
                                {errors.submit}
                            </FormHelperText>
                        </Box>
                    )}
                    <Box mt={2}>
                        <SpinnerButton isSubmitting={isSubmitting}>
                            Depot anlegen
                        </SpinnerButton>
                    </Box>
                </form>
            )}
        </Formik>
    );
}

DepotCreateForm.propTypes = {};

export default DepotCreateForm;
