import React, {useEffect, useState, useCallback} from 'react';
import {debounce} from "lodash";
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import {createFilterOptions} from '@mui/material/Autocomplete';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/de';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import CircularProgress from '@mui/material/CircularProgress';
import {IconButton} from "@mui/material";
import Moment from 'moment';
import {useSelector} from "react-redux";
import axios from 'src/utils/axios';
import SpinnerButton from "src/components/SpinnerButton";
import {LocalizationProvider} from "@mui/x-date-pickers";

function Toolbar({searchIsin, searchIndexKurz, searchDate, onSearch, loading, ...rest}) {
    const [date, setDate] = useState(searchDate);
    const [autocompleteDefaultValue, setAutocompleteDefaultValue] = useState(null);
    const [autocompleteSearchTerm, setAutocompleteSearchTerm] = useState('');
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [autocompleteIsLoading, setAutocompleteIsLoading] = useState(false);
    const [autocompleteIsOpen, setAutocompleteIsOpen] = useState(false);
    const [autocompleteNoResult, setAutocompleteNoResult] = useState(false);
    const [selectedStock, setSelectedStock] = useState(null);
    const account = useSelector((state) => state.account);

    const autocompleteSearch = useCallback(debounce((query) => {
        setSelectedStock(null);

        return axios
            .get('/api/invest/akties/search', {
                params: {
                    searchtext: query
                }
            }).then((response) => {
                if (response.data.payload.hasOwnProperty("aktien") && response.data.payload.aktien.length > 0) {
                    // Set back to false since request finished
                    setAutocompleteIsLoading(false);

                    // Set results state
                    setAutocompleteOptions(response.data.payload.aktien);


                    setAutocompleteNoResult(query !== '' && query.length > 1 && response.data.payload.aktien.length === 0);
                } else {
                    setAutocompleteIsOpen(false);
                    setAutocompleteIsLoading(false);
                    setAutocompleteOptions([]);
                    setAutocompleteSearchTerm('');
                }
            })
            .catch(error => {
                setAutocompleteIsOpen(false);
                setAutocompleteIsLoading(false);
                setAutocompleteOptions([]);
                setAutocompleteSearchTerm('');
            });
    }, 500), []);

    if (typeof date === 'object') {
        // date = 2021-10-20
        setDate(date.format('YYYY-MM-DD'));
    }

    const disableWeekends = date => {
        return date.day() === 0 || date.day() === 6
    };

    const filterOptions = createFilterOptions({
        stringify: option => option.Aktienname + ' ' + option.ISIN,
    });

    const handleSearchSubmit = event => {
        event.preventDefault();

        let isin = selectedStock && selectedStock.hasOwnProperty('ISIN') ? selectedStock.ISIN : searchIsin;
        let indexKurz = selectedStock && selectedStock.hasOwnProperty('Index_kurz') ? selectedStock.Index_kurz : searchIndexKurz;
        //let date = searchDate === Moment(date).format('YYYY-MM-DD') ? searchDate : date;

        onSearch(isin, indexKurz, Moment(date).format('YYYY-MM-DD'));
    };

    useEffect(() => {
        if (autocompleteDefaultValue === null && searchIsin) {
            setAutocompleteDefaultValue({ISIN: searchIsin});
        }

        if (autocompleteSearchTerm && !selectedStock) {
            // Set isSearching state
            setAutocompleteIsLoading(true);

            // Fire off our API call
            autocompleteSearch(autocompleteSearchTerm);
        }
    }, [autocompleteDefaultValue, selectedStock, searchIsin, autocompleteSearchTerm, autocompleteSearch]);

    return (
        <Card>
            <CardContent sx={{
                '&:last-child': {
                    paddingBottom: 2
                }
            }}
            >
                <form onSubmit={handleSearchSubmit}>
                    <Box alignItems="center" display={{xs: 'block', sm: 'flex'}}>
                        <Box pt={{xs: 1, sm: 0}} flexGrow={2}>
                            <Autocomplete
                                freeSolo
                                filterOptions={filterOptions}
                                open={autocompleteIsOpen}
                                onOpen={() => {
                                    setAutocompleteIsOpen(true)
                                }}
                                onClose={() => {
                                    setAutocompleteIsOpen(false)
                                }}
                                onChange={(event, option) => {
                                    console.log(date);
                                    setSelectedStock(option);
                                    if (option) {
                                        onSearch(option.ISIN, option.Index_kurz, Moment(date).format('YYYY-MM-DD'));
                                    }
                                }}
                                onInputChange={
                                    (event, value) => {
                                        setSelectedStock(null);

                                        if (value.length < 2) {
                                            setAutocompleteIsOpen(false);
                                            setAutocompleteIsLoading(false);
                                            setAutocompleteOptions([]);
                                            setAutocompleteSearchTerm('');
                                        } else {
                                            setAutocompleteSearchTerm(value);
                                        }
                                    }
                                }
                                getOptionLabel={(option) => option.ISIN}
                                value={autocompleteDefaultValue}
                                options={autocompleteOptions}
                                loading={autocompleteIsLoading}
                                loadingText="Lade..."
                                clearText="Inhalt löschen"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        style={{
                                            minWidth: 200
                                        }}
                                        fullWidth
                                        required
                                        autoFocus
                                        label="ISIN / Name"
                                        name="searchText"
                                        type="text"
                                        variant="outlined"
                                        error={autocompleteNoResult}
                                        value={autocompleteDefaultValue}
                                        // https://stackoverflow.com/questions/67612308/react-material-ui-autocomplete-component-button-after-clear-field-button
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <>
                                                    <IconButton size="small">
                                                        {autocompleteIsLoading ?
                                                            <CircularProgress color="inherit" size={16}/> : <></>}
                                                    </IconButton>
                                                    {params.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}
                                    />
                                )}
                                renderOption={(props, option, {selected}) => (
                                    <li {...props} key={props.id}>
                                        <Grid container>
                                            <Grid item xs={12} md={8}>
                                                <Typography variant="body2" color="textPrimary">
                                                    {option.Aktienname}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <Typography variant="body2" color="textSecondary">
                                                    {option.ISIN}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </li>
                                )}
                            />
                        </Box>
                        <Box pt={{xs: 3, sm: 0}} pl={{sm: 2, md: 4}} pr={{sm: 2, md: 4}} flexGrow={1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                <DatePicker
                                    value={date}
                                    inputVariant="outlined"
                                    disableFuture
                                    shouldDisableDate={disableWeekends}
                                    label="Datum (Tageskurs)"
                                    onChange={date => setDate(date)}
                                    renderInput={(params) => <TextField name="date" required fullWidth {...params} />}
                                    minDate={Moment().subtract(10, 'years')}
                                    maxDate={account.user.livedate}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box pt={{xs: 1, sm: 0}} flexGrow={1}>
                            <SpinnerButton isSubmitting={loading}
                                           disabled={(!(typeof selectedStock === 'object' && selectedStock !== null && selectedStock !== '') && Moment(date).format('YYYY-MM-DD') === searchDate) || loading}
                                           fullWidth
                                           size="large">Suchen</SpinnerButton>
                        </Box>
                    </Box>
                </form>
            </CardContent>
        </Card>
    );
};

Toolbar.propTypes = {
    searchIsin: PropTypes.string,
    searchIndexKurz: PropTypes.string,
    searchDate: PropTypes.string,
    onSearch: PropTypes.func,
    loading: PropTypes.bool
};

export default Toolbar;
