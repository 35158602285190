import React from 'react';
import {Box, Container, Grid, Typography, Button} from '@mui/material';
import Header from "./Header";
import Page from "src/components/Page";
import {useSelector} from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import {Search as SearchIcon} from "react-feather";
import {Link as RouterLink} from "react-router-dom";
import LoadingScreen from "src/components/LoadingScreen";
import {getTitle} from "src/utils/meta";
import CompareStockCard from "src/components/CompareStockCard";

function CompareView() {
    const comparedStocks = useSelector((state) => state.compare.stocks);

    if (comparedStocks === null) {
        return <LoadingScreen/>;
    }

    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle("Vergleich")}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }}
                       maxWidth="lg">
                <Header/>
                {
                    comparedStocks && comparedStocks.length === 0 &&
                    <>
                        <Typography
                            variant="subtitle1"
                            color="textPrimary"
                        >
                            Sie haben noch keine Aktie zum Vergleich hinzugefügt
                        </Typography>
                        <Box mt={2}>
                            <Button
                                component={RouterLink}
                                to="/stocks/search"
                                variant="contained"
                            >
                                <SearchIcon sx={{
                                    marginRight: 1
                                }}/>
                                Zur Aktiensuche
                            </Button>
                        </Box>
                    </>
                }
                <Box mt={3}>
                    <Grid container spacing={3}>
                        {!comparedStocks && <LinearProgress/>}
                        {
                            comparedStocks && comparedStocks.length > 0 &&
                            comparedStocks.map(stock => (
                                <Grid
                                    key={stock.id}
                                    item
                                    xl={4}
                                    lg={4}
                                    md={6}
                                    xs={12}
                                >
                                    <CompareStockCard isin={stock.isin} index_kurz={stock.index_kurz}/>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
}

CompareView.propTypes = {};

export default CompareView;
