import React, {useEffect, useState, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardHeader,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    alpha,
    useTheme, Box
} from '@mui/material';
import moment from 'moment';
import {Line} from 'react-chartjs-2';
import DownsamplePlugin from 'src/mixins/chartjs-plugin-downsample-date';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import MoreIcon from '@mui/icons-material/MoreVert';
import axios from 'src/utils/axios';
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";

function PriceChart({aktie, ...rest}) {
    const theme = useTheme();
    const isMountedRef = useIsMountedRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedTimespan, setSelectedTimespan] = useState('1Y');

    const [loading, setLoading] = useState(true);
    const [dataKurs, setDataKurs] = useState([]);
    const [dataKursHidden, setDataKursHidden] = useState(false);

    const chartReference = useRef(null);

    const getChartData = useCallback(() => {
        setLoading(true);
        axios
            .get('/api/invest/aktie/scores', {
                params: {
                    ISIN: aktie.ISIN,
                    Index_kurz: aktie.Index_kurz,
                    datum: aktie.datum,
                    timespan: selectedTimespan
                }
            }).then((response) => {
            if (isMountedRef.current) {
                const restructuredData = {
                    'Kurs': [],
                };

                if (response.data.payload.scores.length > 0) {
                    for (const score of response.data.payload.scores) {
                        restructuredData['Kurs'].push({
                            x: score.datum,
                            y: score.Kurs,
                        });
                    }

                    setDataKurs(restructuredData['Kurs']);
                    setLoading(false);
                }
            }
        });
    }, [isMountedRef, aktie, selectedTimespan]);

    useEffect(() => {
        getChartData();
    }, [getChartData]);

    const timespans = [
        {value: '1W', label: '1 Woche'},
        {value: '1M', label: '1 Monat'},
        {value: '3M', label: '3 Monate'},
        {value: '1Y', label: '1 Jahre'},
        {value: '3Y', label: '3 Jahre'},
        {value: '5Y', label: '5 Jahre'},
    ];

    const lineChartOptions = {
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                type: 'time',
                distribution: 'series',
                offset: true,
                ticks: {
                    major: {
                        enabled: true,
                        fontStyle: 'bold'
                    },
                    source: 'data',
                    autoSkip: true,
                    autoSkipPadding: 50,
                    time: {
                        unitStepSize: 10
                    },
                    maxRotation: 0,
                    fontColor: theme.palette.text.secondary,
                    padding: 20,
                },
                gridLines: {
                    display: false,
                    drawBorder: false
                },
            }],
            yAxes: [{
                gridLines: {
                    borderDash: [2],
                    borderDashOffset: [2],
                    color: theme.palette.divider,
                    drawBorder: false,
                    zeroLineBorderDash: [2],
                    zeroLineBorderDashOffset: [2],
                    zeroLineColor: theme.palette.divider
                },
                scaleLabel: {
                    display: true,
                },
                ticks: {
                    autoSkip: true,
                    autoSkipPadding: 50,
                    fontColor: theme.palette.text.secondary,
                    padding: 20,
                },
            }]
        },
        tooltips: {
            mode: 'single',
            enabled: true,
            intersect: false,
            caretSize: 10,
            yPadding: 20,
            xPadding: 20,
            borderWidth: 1,
            borderColor: theme.palette.divider,
            backgroundColor: theme.palette.background.default,
            titleFontColor: theme.palette.text.primary,
            bodyFontColor: theme.palette.text.secondary,
            footerFontColor: theme.palette.text.secondary,
            callbacks: {
                title: function (tooltipItems, data) {
                    if (tooltipItems[0]) {
                        return moment(tooltipItems[0].xLabel).format('DD.MM.YYYY');
                    }
                },
            }
        },
        legend: {
            position: 'bottom',
            labels: {
                padding: 20
            },
            onClick: function (e, legendItem) {
                switch (legendItem.datasetIndex) {
                    case 0:
                        setDataKursHidden(!legendItem.hidden);
                        break;

                    default:
                        break;
                }
            },
            onHover: function (e) {
                e.target.style.cursor = 'pointer';
            },
        },
        downsample: {
            enabled: false,
            // max number of points to display per dataset
            threshold: 52 * 5 * 5, // 52 Wochen x 5 Tage x 5 Jahre
        },
        plugins: {
            datalabels: {
                display: false
            },
        }
    };

    const reloadChart = () => {
        return {
            datasets: [{
                label: 'Aktienkurs',
                fill: true,
                borderWidth: 1,
                lineTension: 0.1,
                backgroundColor: alpha(theme.palette.secondary.main, 0.25),
                borderColor: theme.palette.secondary.main,
                pointBackgroundColor: 'rgb(13, 71, 161)',
                pointHoverRadius: 2,
                pointRadius: 0,
                pointHitRadius: 2,
                data: dataKurs,
                hidden: dataKursHidden
            }]
        };
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (newTimespan) => {
        if (newTimespan !== null && newTimespan !== selectedTimespan) {
            setSelectedTimespan(newTimespan);
        }

        setAnchorEl(null);
    };

    return (
        <Card {...rest}>
            <CardHeader
                title="Kurs"
                action={
                    <>
                        <Tooltip title="Zeitraum auswählen">
                            <IconButton onClick={handleMenuOpen} size="large">
                                <MoreIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            {
                                timespans.map((timespan) => (
                                    <MenuItem
                                        selected={timespan.value === selectedTimespan}
                                        key={timespan.value}
                                        onClick={() => handleMenuItemClick(timespan.value)}
                                    >{timespan.label}</MenuItem>
                                ))}
                        </Menu>
                    </>
                }/>
            <Divider/>
            {loading && <LinearProgress/>}
            <Box sx={{
                paddingTop: 3,
                paddingBottom: 2,
                height: 350,
            }}>
                <Line ref={chartReference} redraw data={reloadChart} options={lineChartOptions} plugins={[DownsamplePlugin]}/>
            </Box>
        </Card>
    );
}
;

PriceChart.propTypes = {
    aktie: PropTypes.object
};

export default PriceChart;
