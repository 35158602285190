import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
//import 'react-quill/dist/quill.snow.css';
// "react-quill": "^2.0.0-beta.4" entfernt, wurde nur hier eingebunden. Warum? Wahrscheinlich wurde mal etwas mit einem WYSIWYG-Editor ausprobiert? Das Benutzer oder sogar Herr König Inhalte im Frontend pflegen sollten?
import 'nprogress/nprogress.css';
import {enableES5} from 'immer';
import React from 'react';
import {Provider} from 'react-redux';
import {CookiesProvider} from 'react-cookie';
import * as serviceWorker from 'src/serviceWorker';
import {PersistGate} from 'redux-persist/integration/react'
import {configureStore} from 'src/store';
import App from 'src/App';
import {createRoot} from 'react-dom/client';

enableES5();

const {store, persistor} = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
        <CookiesProvider>
            <App/>
        </CookiesProvider>
    </PersistGate>
</Provider>);

serviceWorker.unregister();
