import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {Typography, Breadcrumbs, Link, Grid} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Header({videoCourse, ...rest}) {
    return (<Grid container spacing={3} justifyContent="space-between" {...rest}>
        <Grid item>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>} aria-label="breadcrumb">
                <Link variant="body1" color="inherit" to="/depot" component={RouterLink} underline="none">Hilfe</Link>
                <Link variant="body1" color="inherit" to="/help/video-course" component={RouterLink} underline="none">Videokurse</Link>
                <Typography variant="body1" color="textPrimary">{videoCourse}</Typography>
            </Breadcrumbs>
            <Typography variant="h3" color="textPrimary">{videoCourse}</Typography>
        </Grid>
    </Grid>);
}

Header.propTypes = {
    videoCourse: PropTypes.string.isRequired
};

export default Header;
