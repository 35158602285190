import React, {useCallback, useState, useEffect} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Box, Button, Card, CardHeader, Divider, Link, Typography} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import ScoreLabel from "src/components/ScoreLabel";
import LinearProgress from "@mui/material/LinearProgress";
import CustomDataGrid from "src/components/CustomDataGrid";
import {useNavigate} from "react-router";
import {useGridApiRef} from "@mui/x-data-grid-pro";

function Depots({...rest}) {
    const apiRef = useGridApiRef();
    const isMountedRef = useIsMountedRef();
    const navigate = useNavigate();
    const [depots, setDepots] = useState(null);

    const getDepots = useCallback(() => {
        axios
            .get('/api/user/depots', {
                params: {
                    offset: 0,
                    limit: 30
                }
            })
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("depots")) {
                        setDepots(response.data.payload.depots);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getDepots();
    }, [getDepots]);

    return (
        <Card {...rest}>
            <CardHeader title="Meine Depots"/>
            <Divider/>
            {
                !depots &&
                <LinearProgress/>
            }
            {
                depots && depots.length > 0 &&
                <CustomDataGrid
                    apiRef={apiRef}
                    options={{
                        header: false,
                    }}
                    onRowClick={(params) => navigate(`/depot/${params.row.id}`)}
                    columns={[{
                        headerName: "Bezeichnung",
                        field: "bezeichnung",
                        defaultSort: 'asc',
                        flex: 1,
                    }, {
                        headerName: "Aktien",
                        field: "count",
                        renderCell: params => <>Aktien ({params.row.depot_aktie_count})</>
                    }, {
                        headerName: "Scores",
                        field: "scores",
                        width: 445,
                        renderCell: params =>
                            <Box sx={{py: 2}}>
                                <ScoreLabel display="inline-flex" flexDirection="column" key="Trend_Score_AVG" name="Trend_Score_AVG"
                                            label="Trend_Score_AVG"
                                            value={params.row.Trend_Score_AVG} mr={1}/>
                                <ScoreLabel display="inline-flex" flexDirection="column" key="Risiko_Score_AVG" name="Risiko_Score_AVG"
                                            label="Risiko_Score_AVG"
                                            value={params.row.Risiko_Score_AVG} mr={1}/>
                                <ScoreLabel display="inline-flex" flexDirection="column" key="Technisch_Score_AVG" name="Technisch_Score_AVG"
                                            label="Technisch_Score_AVG"
                                            value={params.row.Technisch_Score_AVG} mr={1}/>
                                <ScoreLabel display="inline-flex" flexDirection="column" key="Value_Score_AVG" name="Trend_Score_AVG"
                                            label="Value_Score_AVG"
                                            value={params.row.Value_Score_AVG} mr={1}/>
                                <ScoreLabel display="inline-flex" flexDirection="column" key="Kombi_Score_AVG" name="Kombi_Score_AVG"
                                            label="Kombi_Score_AVG"
                                            value={params.row.Kombi_Score_AVG} mr={1}/>
                            </Box>
                    },
                    ]}
                    data={depots}
                />
            }
            <Box
                p={2}
                display="flex"
                justifyContent="flex-end"
            >
                <Button
                    component={RouterLink}
                    size="small"
                    to="/depot"
                >
                    Zur Depotübersicht
                    <NavigateNextIcon sx={{
                        marginLeft: 1
                    }}/>
                </Button>
            </Box>
        </Card>
    );
}

Depots.propTypes = {};

export default Depots;
