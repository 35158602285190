import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Breadcrumbs,
    Grid,
    Link,
    Typography
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function Header({ranking, ...rest}) {
    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            {...rest}
        >
            <Grid item>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small"/>}
                    aria-label="breadcrumb"
                >
                    <Link
                        variant="body1"
                        color="inherit"
                        to="/stocks"
                        component={RouterLink}
                        underline="none">
                        Aktien
                    </Link>
                    <Link
                        variant="body1"
                        color="inherit"
                        to="/stocks/rankings"
                        component={RouterLink}
                        underline="none">
                        Rankings
                    </Link>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                    >
                        {ranking && ranking.bezeichnung ? ranking.bezeichnung : "Neues Ranking"}
                    </Typography>
                </Breadcrumbs>
                <Typography
                    variant="h3"
                    color="textPrimary"
                >
                    {ranking && ranking.bezeichnung ? ranking.bezeichnung : "Neues Ranking"}
                </Typography>
            </Grid>
        </Grid>
    );
}

Header.propTypes = {
    ranking: PropTypes.any
};

export default Header;
