import React, {useState} from 'react';
import {IconButton, InputAdornment, TextField} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function PasswordTextFieldSwitcher({...rest}) {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordMask = () => {
        setShowPassword(prev => !prev);
    };

    return (
        <TextField
            type={showPassword ? "text" : "password"}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePasswordMask}
                            onMouseDown={togglePasswordMask}
                            size="large">
                            {showPassword ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            {...rest}
        />
    );
}

PasswordTextFieldSwitcher.propTypes = {};

export default PasswordTextFieldSwitcher;
