import React, {useCallback, useState, useEffect} from 'react';
import {
    Card,
    CardHeader,
    Divider, Link
} from '@mui/material';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import ScoreLabel from "src/components/ScoreLabel";
import LinearProgress from "@mui/material/LinearProgress";
import CustomDataGrid from "src/components/CustomDataGrid";
import {useNavigate} from "react-router";
import numeral from "src/mixins/numeral";
import {useGridApiRef} from "@mui/x-data-grid-pro";

function PromisingStocks({...rest}) {
    const apiRef = useGridApiRef();
    const isMountedRef = useIsMountedRef();
    const navigate = useNavigate();
    const [stocks, setStocks] = useState(null);

    const getPromisingStocks = useCallback(() => {
        axios
            .get('/api/general/promisingstocks', {
                params: {
                    offset: 0,
                    limit: 50
                }
            })
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("aktien")) {
                        setStocks(response.data.payload.aktien.depot_aktien);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getPromisingStocks();
    }, [getPromisingStocks]);

    return (
        <Card {...rest}>
            <CardHeader title="Aktuell aussichtsreiche Aktien"/>
            <Divider/>
            {
                !stocks &&
                <LinearProgress/>
            }
            {
                stocks && stocks.length > 0 &&
                <CustomDataGrid
                    apiRef={apiRef}
                    configSettingKey="promising_stocks"
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                "ISIN": false,
                                "Sector:bez": false,
                                "Index_kurz:bez": false
                            },
                        },
                    }}
                    onRowClick={(params) => navigate(`/stocks/search/${params.row.Index_kurz}/${params.row.ISIN}`)}
                    columns={[
                        {
                            headerName: "Name",
                            field: "Aktienname",
                            defaultSort: 'asc',
                            flex: 1,
                            minWidth: 200,
                        },
                        {
                            headerName: "ISIN",
                            field: "ISIN",
                            width: 160,
                        },
                        {
                            headerName: "Sektor",
                            field: "Sector:bez",
                            sortable: false,
                            width: 160,
                        },
                        {
                            headerName: "Index",
                            field: "Index_kurz:bez",
                            sortable: false,
                            width: 160,
                        },
                        {
                            headerName: "Kurs",
                            field: "Kurs",
                            type: "number",
                            renderCell: params => numeral(params.row.Kurs).format('0.00') + " " + params.row["Currency:symbol"]
                        },
                        {
                            headerName: "Trend",
                            field: "Trend_Score",
                            type: "number",
                            width: 90,
                            renderCell: params => <ScoreLabel key="trendScore" name="trendScore" value={params.row.Trend_Score}/>
                        },
                        {
                            headerName: "Safety",
                            field: "Risiko_Score",
                            type: "number",
                            width: 90,
                            renderCell: params => <ScoreLabel key="risikoScore" name="risikoScore" value={params.row.Risiko_Score}/>
                        },
                        {
                            headerName: "Tech",
                            field: "Technisch_Score",
                            type: "number",
                            width: 90,
                            renderCell: params => <ScoreLabel key="technischerScore" name="technischerScore" value={params.row.Technisch_Score}/>
                        },
                        {
                            headerName: "Value",
                            field: "Value_Score",
                            type: "number",
                            width: 90,
                            renderCell: params => <ScoreLabel key="valueScore" name="valueScore" value={params.row.Value_Score}/>
                        },
                        {
                            headerName: "Kombi",
                            field: "Kombi_Score",
                            type: "number",
                            width: 90,
                            renderCell: params => <ScoreLabel key="kombiScore" name="kombiScore" value={params.row.Kombi_Score}/>
                        },
                    ]}
                    data={stocks}
                    initialState={{
                        sorting: {
                            sortModel: [{field: 'Aktienname', sort: 'asc'}],
                        },
                    }}
                />
            }
        </Card>
    );
}

PromisingStocks.propTypes = {};

export default PromisingStocks;
