/* eslint-disable no-param-reassign */
import _ from 'lodash';
import produce from 'immer';
import {ADD_STOCK, REMOVE_STOCK} from 'src/actions/compareActions';

const initialState = {
  stocks: [],
};

const compareReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_STOCK: {
      const {isin, index_kurz} = action.payload;

      return produce(state, (draft) => {
        draft.stocks.push({
          id: index_kurz + "-" + isin,
          isin: isin,
          index_kurz: index_kurz
        });
      });
    }

    case REMOVE_STOCK: {
      const {index_kurz, isin} = action.payload;

      return produce(state, (draft) => {
        draft.stocks = _.filter(draft.stocks, stock => stock.id !== index_kurz + "-" + isin);
      });
    }

    default: {
      return state;
    }
  }
};

export default compareReducer;
