import config from "src/config";

export const getTitle = (title)=> {
  if (config.title && config.titleSeparator) {
    if (title) {
      title = title + ' ' + config.titleSeparator + ' ' + config.title;
    } else {
      title = config.title;
    }
  }

  return title;
}
