const hostname = window && window.location && window.location.hostname;

var config = {
  title: 'investolio',
  titleSeparator: '-',
};

// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
switch (hostname) {
  case 'localhost':
    config.GA_MEASUREMENT_ID = "UA-179486510-1";
    config.STAGE = 'dev';
    config.APP_API_ENDPOINT = 'https://investolio.dev.m-s.de';
    config.ENABLE_REDUX_LOGGER = true;
    break;

  case 'investolio-app.dev.m-s.de':
    config.GA_MEASUREMENT_ID = "UA-179486510-1";
    config.STAGE = 'test';
    config.APP_API_ENDPOINT = 'https://investolio.dev.m-s.de';
    config.ENABLE_REDUX_LOGGER = true;
    break;

  // www.investolio.de
  default:
    config.GA_MEASUREMENT_ID = "UA-179486510-1";
    config.STAGE = 'prod';
    config.APP_API_ENDPOINT = 'https://api.investolio.de';
    config.ENABLE_REDUX_LOGGER = false;
    break;
}

export default config;
