import React from 'react';
import PropTypes from 'prop-types';
import Yup from 'src/mixins/yup';
import {Formik} from 'formik';
import {useSnackbar} from 'notistack';
import {
    Box,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Divider,
    FormHelperText
} from '@mui/material';
import axios from "src/utils/axios";
import SpinnerButton from "src/components/SpinnerButton";

function AddStockWatchlistForm({stock, watchlists, onCancel, onSubmit, ...rest}) {
    const {enqueueSnackbar} = useSnackbar();

    return (
        <Formik
            initialValues={{
                watchlist: '',
            }}
            validationSchema={Yup.object().shape({
                watchlist: Yup.number().required(),
            })}
            onSubmit={async (values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                await axios.post('/api/user/depots/akties', {
                    "depot_id": values.watchlist,
                    "ISIN": stock.ISIN,
                    "Index_kurz": stock.Index_kurz,
                    "quantity": values.num,
                    "Kaufkurs": values.price
                }).then((response) => {
                    resetForm();
                    setStatus({success: true});
                    setSubmitting(false);
                    enqueueSnackbar('Aktie zur Watchlist hinzugefügt', {
                        variant: 'success'
                    });
                    onSubmit();
                }).catch((error) => {
                    error = (error.response && error.response.data.message) || 'Es ist ein unerwarteter Fehler aufgetreten.';

                    setStatus({success: false});
                    setErrors({submit: error});
                    setSubmitting(false);
                });
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldTouched,
                  setFieldValue,
                  touched,
                  values
              }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...rest}
                >
                    <Box p={3} pb={0}>
                        <Typography
                            align="center"
                            gutterBottom
                            variant="h3"
                            color="textPrimary"
                        >Aktie zur Watchlist hinzufügen</Typography>
                    </Box>
                    <Box p={3}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>Watchlist</InputLabel>
                            <Select
                                error={Boolean(touched.watchlist && errors.watchlist)}
                                label="Watchlist"
                                name="watchlist"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                value={values.watchlist}
                            >
                                {watchlists.length === 0 && <MenuItem value="" disabled>Keine Watchlist vorhanden</MenuItem>}
                                {
                                    watchlists.length > 0 &&
                                    watchlists.map(watchlist => (
                                        <MenuItem key={watchlist.id} value={watchlist.id}>{watchlist.bezeichnung}</MenuItem>
                                    ))
                                }
                            </Select>
                            {Boolean(touched.watchlist && errors.watchlist) && (
                                <FormHelperText error>
                                    {errors.watchlist}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Box>
                    <Divider/>
                    <Box
                        p={2}
                        display="flex"
                        alignItems="center"
                    >
                        <Box flexGrow={1}/>
                        <Button onClick={onCancel}>
                            Abbrechen
                        </Button>
                        <SpinnerButton isSubmitting={isSubmitting} sx={{
                            marginLeft: 2
                        }}>
                            Hinzufügen
                        </SpinnerButton>
                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}
                    </Box>
                </form>
            )}
        </Formik>
    );
}

AddStockWatchlistForm.propTypes = {
    stock: PropTypes.object,
    watchlists: PropTypes.array,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
};

AddStockWatchlistForm.defaultProps = {
    onSubmit: () => {
    },
    onCancel: () => {
    },
};

export default AddStockWatchlistForm;
