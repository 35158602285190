export const ADD_STOCK = '@compare/add-stock';
export const REMOVE_STOCK = '@compare/remove-stock';

export function addStock(index_kurz, isin) {
  return (dispatch) => dispatch({
    type: ADD_STOCK,
    payload: {
      index_kurz, isin
    }
  });
}

export function removeStock(index_kurz, isin) {
  return (dispatch) => dispatch({
    type: REMOVE_STOCK,
    payload: {
      index_kurz, isin
    }
  });
}
