import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
    Breadcrumbs,
    Grid,
    Link,
    Typography
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {useSelector} from "react-redux";

function Header({...rest}) {
    const account = useSelector((state) => state.account);

    const greetingText = () => {
        var greeting = 'Hallo';
        var hours = new Date().getHours();

        if (hours < 11) {
            greeting = 'Guten Morgen';
        } else if (hours >= 11 && hours <= 17) {
            greeting = 'Guten Tag';
        } else if (hours >= 17 && hours <= 24) {
            greeting = 'Guten Abend ';
        }

        return greeting;
    }

    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            {...rest}
        >
            <Grid item>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small"/>}
                    aria-label="breadcrumb"
                >
                    <Link
                        variant="body1"
                        color="inherit"
                        to="/"
                        component={RouterLink}
                        underline="none">
                        Reports
                    </Link>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                    >
                        Dashboard
                    </Typography>
                </Breadcrumbs>
                <Typography
                    variant="h3"
                    color="textPrimary"
                >
                    {greetingText()} {account.user.vorname}
                </Typography>
            </Grid>
        </Grid>
    );
}

Header.propTypes = {};

export default Header;
