import React, {useCallback, useEffect, useState} from 'react';
import {Box, Container} from '@mui/material';
import Page from 'src/components/Page';
import LoadingScreen from "src/components/LoadingScreen";
import axios from "src/utils/axios";
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import DangerouslyHtml from "src/components/DangerouslyHtml";
import Header from './Header';
import Grid from "@mui/material/Grid";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FaqListView() {
    const isMountedRef = useIsMountedRef();
    const [expanded, setExpanded] = useState(false);
    const [faqs, setFaqs] = useState(null);

    const getFaqs = useCallback(() => {
        axios
            .get('/api/general/faqthemen')
            .then((response) => {
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("faqthemen")) {
                        setFaqs(response.data.payload.faqthemen);
                    }
                }
            });
    }, [isMountedRef]);

    useEffect(() => {
        getFaqs();
    }, [getFaqs]);

    if (!faqs) {
        return <LoadingScreen/>;
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Page
            sx={{
                backgroundColor: 'background.dark',
                minHeight: '100%',
            }}
            title="FAQ"
        >
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth="lg">
                <Header/>
                <Box mt={1}>
                    <Grid container spacing={3}>
                        {
                            faqs.map((faqTopic, faqTopicIndex) =>
                                <Grid key={faqTopicIndex} item xs={12} md={6}>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        mt={2}
                                        mb={4}
                                        style={{position: 'relative'}}
                                    >
                                        <Typography
                                            variant="h5"
                                            color="textPrimary"
                                            sx={{
                                                '&:before': {
                                                    position: 'absolute',
                                                    bottom: -8,
                                                    left: 0,
                                                    content: '" "',
                                                    height: 3,
                                                    width: 48,
                                                    backgroundColor: 'primary.main'
                                                }
                                            }}
                                        >
                                            {faqTopic.titel}
                                        </Typography>
                                    </Box>
                                    {
                                        faqTopic.faqs.map((faq, faqIndex) =>
                                            <Accordion key={faqIndex} expanded={expanded === "panel-" + faqTopicIndex + "-" + faqIndex}
                                                       onChange={handleChange("panel-" + faqTopicIndex + "-" + faqIndex)}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon/>} id={"panel-" + faqTopicIndex + "-" + faqIndex}>
                                                    <Typography variant="subtitle2" color="textSecondary">
                                                        {faq['frage']}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <DangerouslyHtml html={faq['antwort']}/>
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    }
                                </Grid>
                            )
                        }
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
}

export default FaqListView;
