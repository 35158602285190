/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import {useLocation, matchPath} from 'react-router';
import {Link as RouterLink} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {Box, Chip, Divider, Drawer, Hidden, Link, List, ListSubheader, Typography} from '@mui/material';
import {
    Search as SearchIcon,
    Monitor as MonitorIcon,
    Code as CodeIcon,
    DollarSign as DollarSignIcon,
    HelpCircle as HelpCircleIcon,
    BarChart as BarChartIcon,
    Youtube as YoutubeIcon,
    User as UserIcon,
    Eye as EyeIcon,
    TrendingUp as TrendingUpIcon,
    PieChart as PieChartIcon
} from 'react-feather';
import LegalNoteLinkList from "src/components/LegalNoteLinkList";
import NavItem from './NavItem';
import {useSelector} from "react-redux";

function renderNavItems({items, ...rest}) {
    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) => reduceChildRoutes({acc, item, ...rest}),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({acc, pathname, item, depth = 0}) {
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: true
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                key={key}
                info={item.info}
                open={Boolean(open)}
                title={item.title}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                key={key}
                info={item.info}
                title={item.title}
            />
        );
    }

    return acc;
}

function NavBar({openMobile, onMobileClose,}) {
    const location = useLocation();
    const comparedStocks = useSelector((state) => state.compare.stocks);
    //const {user} = useSelector((state) => state.account);

    const navConfig = [
        {
            subheader: '',
            items: [
                {
                    title: 'Dashboard',
                    icon: MonitorIcon,
                    href: '/'
                },
            ]
        },
        {
            subheader: 'Aktien',
            items: [
                {
                    title: 'Aktiensuche',
                    icon: SearchIcon,
                    href: '/stocks/search',
                },
                {
                    title: 'Rankings',
                    icon: BarChartIcon,
                    href: '/stocks/rankings',
                },
                {
                    title: 'Vergleich',
                    icon: CodeIcon,
                    href: '/stocks/compare',
                    info: () => (
                        comparedStocks.length === 0 ? null :
                            <Chip
                                color="secondary"
                                size="small"
                                label={comparedStocks.length}
                            />
                    ),
                }
            ]
        },
        {
            subheader: 'Depot',
            items: [
                {
                    title: 'Depots',
                    icon: DollarSignIcon,
                    href: '/depot',
                },
                {
                    title: 'Watchlist',
                    icon: EyeIcon,
                    href: '/depot/watchlist',
                }
            ]
        },
        {
            subheader: 'Investment',
            items: [
                {
                    title: 'Investmentstrategien',
                    icon: TrendingUpIcon,
                    href: '/investment/strategy',
                },
                {
                    title: 'Investitionsquote',
                    icon: PieChartIcon,
                    href: '/investment/rate',
                },
            ]
        },
        {
            subheader: 'Verwaltung',
            items: [
                {
                    title: 'Benutzerkonto',
                    href: '/account/general',
                    icon: UserIcon
                },
                /*
                {
                  title: 'Einstellungen',
                  href: '/settings',
                  icon: SettingsIcon
                },
                */
            ]
        },
        {
            subheader: 'Hilfe',
            items: [
                {
                    title: 'FAQ',
                    href: '/help/faq',
                    icon: HelpCircleIcon,
                },
                {
                    title: 'Videokurse',
                    href: '/help/video-course',
                    icon: YoutubeIcon
                },
            ]
        }
    ];

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line
    }, [location.pathname]);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <PerfectScrollbar options={{suppressScrollX: true}}>
                <Box p={2}>
                    {navConfig.map((config) => (
                        <List
                            key={config.subheader}
                            subheader={(
                                <ListSubheader
                                    disableGutters
                                    disableSticky
                                >
                                    {config.subheader}
                                </ListSubheader>
                            )}
                        >
                            {renderNavItems({items: config.items, pathname: location.pathname})}
                        </List>
                    ))}
                </Box>
                <Divider/>
                <Box p={2}>
                    <Box display="flex" mt={2} ml={-1}>
                        <LegalNoteLinkList/>
                    </Box>
                    {
                        false &&
                        <Box
                            p={2}
                            borderRadius={1}
                            bgcolor="background.dark"
                        >
                            <Typography
                                variant="h6"
                                color="textPrimary"
                            >
                                Jetzt 30% sparen und
                            </Typography>
                            <Link
                                variant="h6"
                                color="secondary"
                                component={RouterLink}
                                to="/account/subscription"
                                underline="none">
                                Mitgliedschaft verlängern
                            </Link>
                        </Box>
                    }
                </Box>
            </PerfectScrollbar>
        </Box>
    );

    return <>
        <Hidden lgUp>
            <Drawer
                anchor="left"
                PaperProps={{
                    sx: {
                        width: 256,
                    }
                }}
                onClose={onMobileClose}
                open={openMobile}
                variant="temporary"
            >
                {content}
            </Drawer>
        </Hidden>
        <Hidden lgDown>
            <Drawer
                anchor="left"
                PaperProps={{
                    sx: {
                        width: 256,
                        top: 64,
                        height: 'calc(100% - 64px)'
                    }
                }}
                open
                variant="persistent"
            >
                {content}
            </Drawer>
        </Hidden>
    </>;
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

export default NavBar;
