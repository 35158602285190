import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Box, Button, Container, Grid, Typography} from '@mui/material';
import Page from 'src/components/Page';
import Header from './Header';
import PromisingStocks from "./PromisingStocks";
import Rankings from "./Rankings";
import Depots from "./Depots";
import News from "./News";
import {getTitle} from "src/utils/meta";

function DashboardView() {
    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={getTitle("Dashboard")}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: {lg: 8},
                paddingRight: {lg: 8},
            }} maxWidth={false}>
                <Header/>
                <Box mt={1}>
                    <Grid container spacing={3}>
                        <Grid xs={12} md={8} item>
                            <Depots/>
                        </Grid>
                        <Grid xs={12} md={4} item>
                            <Rankings/>
                        </Grid>
                        <Grid xs={12} item>
                            <PromisingStocks/>
                        </Grid>
                        <Grid xs={12} item>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                mb={3}
                            >
                                <Typography
                                    sx={{
                                        position: 'relative',
                                        '&:before': {
                                            position: 'absolute',
                                            bottom: -8,
                                            left: 0,
                                            content: '" "',
                                            height: 3,
                                            width: 48,
                                            backgroundColor: 'primary.main'
                                        }
                                    }}
                                    variant="h5"
                                    color="textPrimary"
                                >
                                    News
                                </Typography>
                                <Button
                                    component={RouterLink}
                                    to="/news"
                                >
                                    Archiv
                                    <KeyboardArrowRightIcon sx={{
                                        marginLeft: 1
                                    }}/>
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <News/>
                </Box>
            </Container>
        </Page>
    );
}

export default DashboardView;
