import axios from 'axios';
import config from 'src/config'

const instance = axios.create({
  baseURL: config.APP_API_ENDPOINT,
  timeout: 10000,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    // Du musst im Request ein no-cache Header setzen:
    // this.xhr.setRequestHeader('Cache-Control', 'no-cache');
    // Wenn der nicht gesetzt ist, kann es sein, das die Response gecached wird. In diesem Fall wird der Access-Control-Allow-Origin-Header weggelassen
    'Cache-Control': 'no-cache',
  }
});

export default instance;
