import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  Breadcrumbs,
  Button,
  Grid,
  Link,
  SvgIcon,
  Typography
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  PlusCircle as PlusCircleIcon
} from 'react-feather';

function Header({onDepotAdd, ...rest}) {
  return (
    <Grid
      container
      spacing={3}
      justifyContent="space-between"
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small"/>}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/depot"
            component={RouterLink}
            underline="none">
            Aktien
          </Link>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            Rankings
          </Typography>
        </Breadcrumbs>
        <Typography
          variant="h3"
          color="textPrimary"
        >
          Meine Rankings
        </Typography>
      </Grid>
      <Grid item>
        <Button
          color="secondary"
          variant="contained"
          component={RouterLink}
          to="/stocks/rankings/add"
        >
          <SvgIcon
            fontSize="small"
            sx={{
                marginRight: 1
            }}
          >
            <PlusCircleIcon/>
          </SvgIcon>
          Neues Ranking
        </Button>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {};

export default Header;
