import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {useTheme, TableCell, TableRow, Typography} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import ScoreLabel from "src/components/ScoreLabel";

const getRowStyle = (depth, theme) => {
  let ret = {};

  if (depth === 0) {
    //ret = {background: theme.palette.background.dark};
  }

  return ret;
};

function Row({onHelp, label, name, value, helpTitle, helpText, children, depth}) {
  const theme = useTheme();

  return <>
    <TableRow style={getRowStyle(depth, theme)} hover>
      <TableCell>
        <Box pl={depth * 3}>
          {
            depth === 0 ? <Typography color="textPrimary" variant="h5">{label}</Typography> : label
          }
        </Box>
      </TableCell>
      <TableCell width="1%">
        <ScoreLabel name={name} value={value}/>
      </TableCell>
      <TableCell width="1%" align="right">
        <IconButton onClick={() => onHelp(helpTitle, helpText)} size="large">
          <SvgIcon fontSize="small">
            <HelpOutlineIcon/>
          </SvgIcon>
        </IconButton>
      </TableCell>
    </TableRow>

    {children && children.map((score, i) => <Row onHelp={onHelp} key={i} depth={(depth + 1)} {...score} />)}
  </>;
};

Row.defaultProps = {
  depth: 0
};

Row.propTypes = {
  onHelp: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  helpTitle: PropTypes.string,
  helpText: PropTypes.string,
  depth: PropTypes.number,
  className: PropTypes.string
};

export default Row;
