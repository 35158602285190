import React from 'react';
import clsx from 'clsx';
import Yup from 'src/mixins/yup';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {Box, FormHelperText, TextField} from '@mui/material';
import SpinnerButton from "src/components/SpinnerButton";
import axios from 'src/utils/axios';

function PasswordRequestForm({onSubmitSuccess, ...rest}) {
    return (
        <Formik
            initialValues={{
                email: '',
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email().max(255).required(),
            })}
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                await axios.put('/api/open/users/' + values.email + '/passwordresetrequest').then((response) => {
                    onSubmitSuccess();
                }).catch((error) => {
                    error = (error.response && error.response.data.message) || 'Es ist ein unerwarteter Fehler aufgetreten.';

                    setStatus({success: false});
                    setErrors({submit: error});
                    setSubmitting(false);
                });
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
              }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...rest}
                >
                    <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="E-Mail-Adresse"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="email"
                        value={values.email}
                        variant="outlined"
                    />
                    <Box mt={2}>
                        <SpinnerButton size="large" fullWidth isSubmitting={isSubmitting}>
                            Passwort zurücksetzen
                        </SpinnerButton>
                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}
                    </Box>
                </form>
            )}
        </Formik>
    );
}

PasswordRequestForm.propTypes = {
    onSubmitSuccess: PropTypes.func
};

PasswordRequestForm.default = {
    onSubmitSuccess: () => {
    }
};

export default PasswordRequestForm;
