import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Container,
    Divider,
    Tab,
    Tabs
} from '@mui/material';
import {Link, Navigate, useParams} from 'react-router-dom';
import Page from 'src/components/Page';
import Header from './Header';
import Subscription from './Subscription';
import General from "./General";
import _ from 'lodash';

function AccountView() {
    const {paramCurrentTab} = useParams();
    const [currentTab, setCurrentTab] = useState('general');
    const tabs = [
        {value: 'general', label: 'Profil'},
        {value: 'subscription', label: 'Mitgliedschaft'},
        //{value: 'security', label: 'Sicherheit'}
    ];

    const handleTabsChange = (event, value) => {
        setCurrentTab(value);
    };

    const getActiveTabByUrlParam = useCallback(() => {
        let tmpCurrentTab = false;

        if (paramCurrentTab) {
            if (_.findKey(tabs, {'value': paramCurrentTab})) {
                tmpCurrentTab = paramCurrentTab;
            }
        }

        setCurrentTab(tmpCurrentTab);
    }, [paramCurrentTab, tabs]);

    useEffect(() => {
        getActiveTabByUrlParam();
    }, [getActiveTabByUrlParam]);

    if (currentTab === false) {
        return <Navigate to="/404"/>;
    }

    return (
        <Page
            sx={{
                backgroundColor: 'background.dark',
                minHeight: '100%',
            }}
            title="Benutzerkonto"
        >
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth="lg">
                <Header/>
                <Box mt={3}>
                    <Tabs
                        onChange={handleTabsChange}
                        scrollButtons="auto"
                        value={currentTab}
                        variant="scrollable"
                        textColor="secondary"
                    >
                        {tabs.map((tab) => (
                            <Tab
                                to={"/account/" + tab.value}
                                component={Link}
                                key={tab.value}
                                label={tab.label}
                                value={tab.value}
                            />
                        ))}
                    </Tabs>
                </Box>
                <Divider/>
                <Box mt={3}>
                    {currentTab === 'general' && <General/>}
                    {currentTab === 'subscription' && <Subscription/>}
                </Box>
            </Container>
        </Page>
    );
}

export default AccountView;
