import React from 'react';
import PropTypes from "prop-types";

function Logo({invert, ...rest}) {
    const logoPath = invert ? '/static/logo-invert.svg' : '/static/logo.svg';

    return (
        <img
            alt="Logo"
            height={25}
            src={logoPath}
            {...rest}
        />
    );
}

Logo.propTypes = {
    invert: PropTypes.bool
};

export default Logo;
