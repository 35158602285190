import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import {useSnackbar} from 'notistack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import AccountBalance from '@mui/icons-material/AccountBalance';
import CompareArrows from '@mui/icons-material/CompareArrows';
import ListAlt from '@mui/icons-material/ListAlt';
import {Box, CardHeader} from "@mui/material";
import SpinnerButton from "src/components/SpinnerButton";
import {useDispatch, useSelector} from "react-redux";
import {addStock, removeStock} from "src/actions/compareActions";
import {LIMITS} from "src/constants";
import AddStockDepotModal from "./AddStockDepotModal";
import numeral from "src/mixins/numeral";
import AddStockWatchlistModal from "./AddStockWatchlistModal";

function Details({data, ...rest}) {
    const {enqueueSnackbar} = useSnackbar();
    const comparedStocks = useSelector((state) => state.compare.stocks);
    const [isStockCompared, setIsStockCompared] = useState(false);
    const dispatch = useDispatch();
    const [modelAddWatchlistOpen, setModelAddWatchlistOpen] = useState(false);
    const [modelAddDepotOpen, setModelAddDepotOpen] = useState(false);

    const compareStockId = data.Index_kurz + '-' + data.ISIN;
    const createData = (key, value) => {
        return {key, value};
    };

    const rows = [
        createData('ISIN', data.ISIN),
        //createData('WKN', data.LocalIdentifier),
        createData('Indexzugehörigkeit', data['Index_kurz:bez']),
        createData('Industrie', data['Industry:bez']),
        createData('Land', data['Country:bez']),
        createData('Sektor', data['Sector:bez']),
        createData('Marktkapitalisierung', numeral(data.MarketCap).format('(0,0)') + " Mio. EUR"),
        createData('Handelsvolumen', numeral(data.Handelsvolumen).format('(0,0)') + " Tsd. EUR"),
    ];

    const handleStockWatchlistAdd = (stock) => {
        setModelAddWatchlistOpen(false);
    };

    const handleStockWatchlistCancel = (stock) => {
        setModelAddWatchlistOpen(false);
    };

    const handleStockDepotAdd = (stock) => {
        setModelAddDepotOpen(false);
    };

    const handleStockDepotCancel = (stock) => {
        setModelAddDepotOpen(false);
    };

    const handleCompareClick = event => {
        if (comparedStocks.length < LIMITS.MAX_STOCK_COMPARES) {
            if (isStockCompared) {
                dispatch(removeStock(data.Index_kurz, data.ISIN,));
                enqueueSnackbar('Aktie vom Vergleich entfernt', {
                    variant: 'success'
                });
            } else {
                dispatch(addStock(data.Index_kurz, data.ISIN));
                enqueueSnackbar('Aktie zum Vergleich hinzugefügt', {
                    variant: 'success'
                });
            }
        } else {
            enqueueSnackbar('Aktie nicht zum Vergleich hinzugefügt (maximal ' + LIMITS.MAX_STOCK_COMPARES + ' Stück)', {
                variant: 'error'
            });
        }
    };

    useEffect(() => {
        setIsStockCompared(false);

        if (comparedStocks.length > 0) {
            comparedStocks.forEach(function (stock) {
                if (stock.id === compareStockId) {
                    setIsStockCompared(true);
                }
            });
        }
    }, [comparedStocks, compareStockId]);

    return (
        <Card {...rest}>
            <CardHeader
                title={data.Aktienname + ' / ' + numeral(data.Kurs).format('0.00') + ' ' + data.Currency + ' / ' + numeral(data.KursEUR).format('0.00') + ' EUR'}/>
            <Divider/>
            <Table>
                <TableBody>
                    {rows.map(row => (
                        <TableRow key={row.key}>
                            <TableCell sx={{
                                fontWeight: 'medium'
                            }}>
                                {row.key}
                            </TableCell>
                            <TableCell>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {row.value}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box sx={{
                padding: 1,
                display: {
                    md: 'flex',
                    xs: 'block',
                },
                justifyContent: 'center'
            }}>
                <SpinnerButton color={isStockCompared ? "default" : "secondary"} onClick={handleCompareClick} sx={{
                    margin: 1,
                    whiteSpace: "nowrap",
                }}>
                    <CompareArrows sx={{marginRight: 1}}/>
                    Vergleichen
                </SpinnerButton>
                <SpinnerButton color="secondary" onClick={event => setModelAddWatchlistOpen(true)} sx={{
                    margin: 1,
                    whiteSpace: "nowrap",
                }}>
                    <ListAlt sx={{marginRight: 1}}/>
                    Watchlist setzen
                </SpinnerButton>
                <SpinnerButton color="secondary" onClick={event => setModelAddDepotOpen(true)} sx={{
                    margin: 1,
                    whiteSpace: "nowrap",
                }}>
                    <AccountBalance sx={{marginRight: 1}}/>
                    Depot hinzufügen
                </SpinnerButton>
                <AddStockWatchlistModal stock={data} onSubmit={handleStockWatchlistAdd} onCancel={handleStockWatchlistCancel} open={modelAddWatchlistOpen}/>
                <AddStockDepotModal stock={data} onSubmit={handleStockDepotAdd} onCancel={handleStockDepotCancel} open={modelAddDepotOpen}/>
            </Box>
        </Card>
    );
};

Details.propTypes = {
    data: PropTypes.object
};

export default Details;
