import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {NumericFormat} from 'react-number-format';
import Yup from 'src/mixins/yup';
import {Formik} from 'formik';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormHelperText,
    Grid,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    FormControl
} from '@mui/material';
import Typography from '@mui/material/Typography';
import SpinnerButton from "src/components/SpinnerButton";
import SectorSelect from "src/components/SectorSelect";
import IndexSelect from "src/components/IndexSelect";
import DepotMultiselect from "src/components/DepotMulitselect";
import AddEditRankingModal from "./AddEditRankingModal";

function SearchRankingForm({ranking, formRef, onSearch, getFilterOptions, mode, onSaveSuccess, onSearchSuccess, ...rest}) {
    const [modalSaveOpen, setModalSaveOpen] = useState(false);

    let filter = ranking ? ranking["filteroptions"] : {};

    const handleModalOpen = () => {
        setModalSaveOpen(true);
    };

    const handleModalSave = (ranking) => {
        setModalSaveOpen(false);
        onSaveSuccess(ranking);
    };

    const handleModalClose = () => {
        setModalSaveOpen(false);
    };

    return (
        <Formik
            //enableReinitialize
            innerRef={formRef}
            initialValues={{
                Index_kurzList: filter["Index_kurzList"] ? filter["Index_kurzList"] : [],
                SectorList: filter["SectorList"] ? filter["SectorList"] : [],
                MarketCap_min: filter["MarketCap_min"] ? filter["MarketCap_min"] : '',
                MarketCap_max: filter["MarketCap_max"] ? filter["MarketCap_max"] : '',
                Handelsvolumen_min: filter["Handelsvolumen_min"] ? filter["Handelsvolumen_min"] : '',
                Handelsvolumen_max: filter["Handelsvolumen_max"] ? filter["Handelsvolumen_max"] : '',
                Trend_Score_min: filter["Trend_Score_min"] ? filter["Trend_Score_min"] : '',
                Trend_Score_max: filter["Trend_Score_max"] ? filter["Trend_Score_max"] : '',
                Kombi_Score_min: filter["Kombi_Score_min"] ? filter["Kombi_Score_min"] : '',
                Kombi_Score_max: filter["Kombi_Score_max"] ? filter["Kombi_Score_max"] : '',
                Technisch_Score_min: filter["Technisch_Score_min"] ? filter["Technisch_Score_min"] : '',
                Technisch_Score_max: filter["Technisch_Score_max"] ? filter["Technisch_Score_max"] : '',
                Risiko_Score_min: filter["Risiko_Score_min"] ? filter["Risiko_Score_min"] : '',
                Risiko_Score_max: filter["Risiko_Score_max"] ? filter["Risiko_Score_max"] : '',
                Value_Score_min: filter["Value_Score_min"] ? filter["Value_Score_min"] : '',
                Value_Score_max: filter["Value_Score_max"] ? filter["Value_Score_max"] : '',
                excludeDepotIdList: filter["excludeDepotIdList"] ? filter["excludeDepotIdList"] : [],
            }}
            validationSchema={Yup.object().shape({
                Trend_Score_min: Yup.number().min(0).max(100),
                Trend_Score_max: Yup.number().min(0).max(100).when('Trend_Score_min', (Trend_Score_min, schema) => Trend_Score_min ? schema.min(Trend_Score_min) : schema),
                Kombi_Score_min: Yup.number().min(0).max(100),
                Kombi_Score_max: Yup.number().min(0).max(100).when('Kombi_Score_min', (Kombi_Score_min, schema) => Kombi_Score_min ? schema.min(Kombi_Score_min) : schema),
                Technisch_Score_min: Yup.number().min(0).max(100),
                Technisch_Score_max: Yup.number().min(0).max(100).when('Technisch_Score_min', (Technisch_Score_min, schema) => Technisch_Score_min ? schema.min(Technisch_Score_min) : schema),
                Risiko_Score_min: Yup.number().min(0).max(100),
                Risiko_Score_max: Yup.number().min(0).max(100).when('Risiko_Score_min', (Risiko_Score_min, schema) => Risiko_Score_min ? schema.min(Risiko_Score_min) : schema),
                Value_Score_min: Yup.number().min(0).max(100),
                Value_Score_max: Yup.number().min(0).max(100).when('Value_Score_min', (Value_Score_min, schema) => Value_Score_min ? schema.min(Value_Score_min) : schema),
            })}
            onSubmit={(values, {
                resetForm,
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                onSearch();
                setStatus({success: true});
                setSubmitting(false);
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values,
                  validateForm
              }) => (
                <form
                    onSubmit={handleSubmit}
                    {...rest}
                >
                    <Card>
                        <CardHeader title="Suchfilter"/>
                        <Divider/>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Index</InputLabel>
                                        <IndexSelect
                                            error={Boolean(touched.Index_kurzList && errors.Index_kurzList)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.Index_kurzList}
                                        />
                                        {Boolean(touched.Index_kurzList && errors.Index_kurzList) && (
                                            <FormHelperText error>
                                                {errors.Index_kurzList}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Sektor</InputLabel>
                                        <SectorSelect
                                            error={Boolean(touched.SectorList && errors.SectorList)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.SectorList}
                                        />
                                        {Boolean(touched.SectorList && errors.SectorList) && (
                                            <FormHelperText error>
                                                {errors.SectorList}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Box mb={-1}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Marktkapitalisierung (in Mio.)
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="MarketCap_min">von</InputLabel>
                                                <NumericFormat
                                                    allowNegative={false}
                                                    decimalSeparator={","}
                                                    decimalScale={2}
                                                    thousandSeparator={"."}
                                                    customInput={OutlinedInput}
                                                    id="MarketCap_min"
                                                    name="MarketCap_min"
                                                    label="von"
                                                    error={Boolean(touched.MarketCap_min && errors.MarketCap_min)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.MarketCap_min}
                                                    endAdornment={<InputAdornment position="end">Mio. €</InputAdornment>}
                                                />
                                                {Boolean(touched.MarketCap_min && errors.MarketCap_min) && (
                                                    <FormHelperText error>
                                                        {errors.MarketCap_min}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="MarketCap_max">bis</InputLabel>
                                                <NumericFormat
                                                    allowNegative={false}
                                                    decimalSeparator={","}
                                                    decimalScale={2}
                                                    thousandSeparator={"."}
                                                    customInput={OutlinedInput}
                                                    id="MarketCap_max"
                                                    name="MarketCap_max"
                                                    label="bis"
                                                    error={Boolean(touched.MarketCap_max && errors.MarketCap_max)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.MarketCap_max}
                                                    endAdornment={<InputAdornment position="end">Mio. €</InputAdornment>}
                                                />

                                                {Boolean(touched.MarketCap_max && errors.MarketCap_max) && (
                                                    <FormHelperText error>
                                                        {errors.MarketCap_max}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Box mb={-1}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Handelsvolumen (in Tsd.)
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Handelsvolumen_min">von</InputLabel>
                                                <NumericFormat
                                                    allowNegative={false}
                                                    decimalSeparator={","}
                                                    decimalScale={2}
                                                    thousandSeparator={"."}
                                                    customInput={OutlinedInput}
                                                    id="Handelsvolumen_min"
                                                    name="Handelsvolumen_min"
                                                    label="von"
                                                    error={Boolean(touched.Handelsvolumen_min && errors.Handelsvolumen_min)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Handelsvolumen_min}
                                                    endAdornment={<InputAdornment position="end">Tsd. €</InputAdornment>}
                                                />
                                                {Boolean(touched.Handelsvolumen_min && errors.Handelsvolumen_min) && (
                                                    <FormHelperText error>
                                                        {errors.Handelsvolumen_min}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Handelsvolumen_max">bis</InputLabel>
                                                <NumericFormat
                                                    allowNegative={false}
                                                    decimalSeparator={","}
                                                    decimalScale={2}
                                                    thousandSeparator={"."}
                                                    customInput={OutlinedInput}
                                                    id="Handelsvolumen_max"
                                                    name="Handelsvolumen_max"
                                                    label="bis"
                                                    error={Boolean(touched.Handelsvolumen_max && errors.Handelsvolumen_max)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Handelsvolumen_max}
                                                    endAdornment={<InputAdornment position="end">Tsd. €</InputAdornment>}
                                                />
                                                {Boolean(touched.Handelsvolumen_max && errors.Handelsvolumen_max) && (
                                                    <FormHelperText error>
                                                        {errors.Handelsvolumen_max}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Box mb={-1}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Trend Score
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Trend_Score_min">von</InputLabel>
                                                <OutlinedInput
                                                    id="Trend_Score_min"
                                                    name="Trend_Score_min"
                                                    type="number"
                                                    label="von"
                                                    error={Boolean(touched.Trend_Score_min && errors.Trend_Score_min)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Trend_Score_min}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                                {Boolean(touched.Trend_Score_min && errors.Trend_Score_min) && (
                                                    <FormHelperText error>
                                                        {errors.Trend_Score_min}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Trend_Score_max">bis</InputLabel>
                                                <OutlinedInput
                                                    id="Trend_Score_max"
                                                    name="Trend_Score_max"
                                                    type="number"
                                                    label="bis"
                                                    error={Boolean(touched.Trend_Score_max && errors.Trend_Score_max)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Trend_Score_max}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                                {Boolean(touched.Trend_Score_max && errors.Trend_Score_max) && (
                                                    <FormHelperText error>
                                                        {errors.Trend_Score_max}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Box mb={-1}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Value Score
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Value_Score_min">von</InputLabel>
                                                <OutlinedInput
                                                    id="Value_Score_min"
                                                    name="Value_Score_min"
                                                    type="number"
                                                    label="von"
                                                    error={Boolean(touched.Value_Score_min && errors.Value_Score_min)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Value_Score_min}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                                {Boolean(touched.Value_Score_min && errors.Value_Score_min) && (
                                                    <FormHelperText error>
                                                        {errors.Value_Score_min}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Value_Score_max">bis</InputLabel>
                                                <OutlinedInput
                                                    id="Value_Score_max"
                                                    name="Value_Score_max"
                                                    type="number"
                                                    label="bis"
                                                    error={Boolean(touched.Value_Score_max && errors.Value_Score_max)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Value_Score_max}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                                {Boolean(touched.Value_Score_max && errors.Value_Score_max) && (
                                                    <FormHelperText error>
                                                        {errors.Value_Score_max}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Box mb={-1}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Safety Score
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Risiko_Score_min">von</InputLabel>
                                                <OutlinedInput
                                                    id="Risiko_Score_min"
                                                    name="Risiko_Score_min"
                                                    type="number"
                                                    label="von"
                                                    error={Boolean(touched.Risiko_Score_min && errors.Risiko_Score_min)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Risiko_Score_min}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                                {Boolean(touched.Risiko_Score_min && errors.Risiko_Score_min) && (
                                                    <FormHelperText error>
                                                        {errors.Risiko_Score_min}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Risiko_Score_max">bis</InputLabel>
                                                <OutlinedInput
                                                    id="Risiko_Score_max"
                                                    name="Risiko_Score_max"
                                                    type="number"
                                                    label="bis"
                                                    error={Boolean(touched.Risiko_Score_max && errors.Risiko_Score_max)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Risiko_Score_max}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                                {Boolean(touched.Risiko_Score_max && errors.Risiko_Score_max) && (
                                                    <FormHelperText error>
                                                        {errors.Risiko_Score_max}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Box mb={-1}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Kombi Score
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Kombi_Score_min">von</InputLabel>
                                                <OutlinedInput
                                                    id="Kombi_Score_min"
                                                    name="Kombi_Score_min"
                                                    type="number"
                                                    label="von"
                                                    error={Boolean(touched.Kombi_Score_min && errors.Kombi_Score_min)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Kombi_Score_min}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                                {Boolean(touched.Kombi_Score_min && errors.Kombi_Score_min) && (
                                                    <FormHelperText error>
                                                        {errors.Kombi_Score_min}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Kombi_Score_max">bis</InputLabel>
                                                <OutlinedInput
                                                    id="Kombi_Score_max"
                                                    name="Kombi_Score_max"
                                                    type="number"
                                                    label="bis"
                                                    error={Boolean(touched.Kombi_Score_max && errors.Kombi_Score_max)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Kombi_Score_max}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                                {Boolean(touched.Kombi_Score_max && errors.Kombi_Score_max) && (
                                                    <FormHelperText error>
                                                        {errors.Kombi_Score_max}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Box mb={-1}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Technischer Score
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Technisch_Score_min">von</InputLabel>
                                                <OutlinedInput
                                                    id="Technisch_Score_min"
                                                    name="Technisch_Score_min"
                                                    type="number"
                                                    label="von"
                                                    error={Boolean(touched.Technisch_Score_min && errors.Technisch_Score_min)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Technisch_Score_min}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                                {Boolean(touched.Technisch_Score_min && errors.Technisch_Score_min) && (
                                                    <FormHelperText error>
                                                        {errors.Technisch_Score_min}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="Technisch_Score_max">bis</InputLabel>
                                                <OutlinedInput
                                                    id="Technisch_Score_max"
                                                    name="Technisch_Score_max"
                                                    type="number"
                                                    label="bis"
                                                    error={Boolean(touched.Technisch_Score_max && errors.Technisch_Score_max)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.Technisch_Score_max}
                                                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                />
                                                {Boolean(touched.Technisch_Score_max && errors.Technisch_Score_max) && (
                                                    <FormHelperText error>
                                                        {errors.Technisch_Score_max}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Box mb={-1}>
                                                <Typography variant="subtitle2" color="textSecondary">
                                                    Aktien ausschließen, die in ausgewählten Depots bereits enthalten sind
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel>Depot</InputLabel>
                                                <DepotMultiselect
                                                    error={Boolean(touched.excludeDepotIdList && errors.excludeDepotIdList)}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.excludeDepotIdList}
                                                />
                                                {Boolean(touched.excludeDepotIdList && errors.excludeDepotIdList) && (
                                                    <FormHelperText error>
                                                        {errors.excludeDepotIdList}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {errors.submit && (
                                <Box mt={3}>
                                    <FormHelperText error>
                                        {errors.submit}
                                    </FormHelperText>
                                </Box>
                            )}
                            <Box mt={3}>
                                <Button onClick={() => validateForm().then(handleModalOpen)}>
                                    Speichern
                                </Button>
                                <SpinnerButton isSubmitting={isSubmitting} sx={{
                                    marginLeft: 2
                                }}>
                                    Suchen
                                </SpinnerButton>
                            </Box>
                        </CardContent>
                    </Card>
                    <AddEditRankingModal ranking={ranking} onSubmit={handleModalSave} onCancel={handleModalClose} open={modalSaveOpen}/>
                </form>
            )}
        </Formik>
    );
}

SearchRankingForm.propTypes = {
    ranking: PropTypes.object,
    onSearch: PropTypes.func,
    formRef: PropTypes.any,
    getFilterOptions: PropTypes.func,
    onSaveSuccess: PropTypes.func,
    onSearchSuccess: PropTypes.func,
};

SearchRankingForm.defaultProps = {
    ranking: {},
    onSearch: () => {

    },
    onSaveSuccess: () => {
    },
    onSearchSuccess: () => {
    },
};

export default SearchRankingForm;
