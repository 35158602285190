import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Typography,
} from '@mui/material';
import Page from 'src/components/Page';
import FindInPageIcon from '@mui/icons-material/FindInPage';

function Error404View() {
    return (
        <Page
            sx={{
                backgroundColor: 'background.dark',
                minHeight: '100%',
                display: 'flex',
                alignItems: 'center',
            }}
            title="404: Not found"
        >
            <Container sx={{
                padding: 3,
                paddingTop: 5,
                paddingBottom: 5
            }}
                       maxWidth="lg">
                <Typography
                    align="center"
                    variant="h1"
                    color="textPrimary"
                >
                    Seite nicht gefunden (Fehler 404)
                </Typography>
                <Typography
                    align="center"
                    variant="subtitle2"
                    color="textSecondary"
                >
                    Wir können die von Ihnen aufgerufene Seite nicht finden.
                    Es scheint, dass diese Seite nicht (mehr) existiert.
                </Typography>
                <Box
                    mt={6}
                    display="flex"
                    justifyContent="center"
                >
                    <FindInPageIcon style={{
                        fontSize: 200
                    }} color="primary"/>
                </Box>
                <Box
                    mt={6}
                    display="flex"
                    justifyContent="center"
                >
                    <Button
                        color="secondary"
                        component={RouterLink}
                        to="/"
                        variant="outlined"
                    >
                        Zum Dashboard
                    </Button>
                </Box>
            </Container>
        </Page>
    );
}

export default Error404View;
