import React from 'react';
import {useDispatch} from 'react-redux';
import Yup from 'src/mixins/yup';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import {Box, TextField, FormHelperText} from '@mui/material';
import {login} from 'src/actions/accountActions';
import {useParams} from "react-router-dom";
import SpinnerButton from "src/components/SpinnerButton";
import PasswordTextFieldSwitcher from "src/components/PasswordTextFieldSwitcher";

function LoginForm({onSubmitSuccess, ...rest}) {
    const dispatch = useDispatch();
    const {message} = useParams();

    return (
        <Formik
            initialValues={{
                email: '',
                password: ''
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email().max(255).required(),
                password: Yup.string().required()
            })}
            onSubmit={async (values, {
                setErrors,
                setStatus,
                setSubmitting
            }) => {
                try {
                    await dispatch(login(values.email, values.password));
                    onSubmitSuccess();
                } catch (error) {
                    let errorMessage = (error.response && error.response.data.message) || 'Es ist ein unerwarteter Fehler aufgetreten.';

                    if (error.response && error.response.status === 401) {
                        errorMessage = 'Ihre eingegebenen Zugangsdaten sind nicht korrekt oder unbekannt.'
                    }

                    setStatus({success: false});
                    setErrors({submit: errorMessage});
                    setSubmitting(false);
                }
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
              }) => (
                <form
                    noValidate
                    onSubmit={handleSubmit}
                    {...rest}
                >
                    <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="E-Mail-Adresse"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        type="email"
                        value={values.email}
                        variant="outlined"
                    />
                    <PasswordTextFieldSwitcher
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        label="Passwort"
                        margin="normal"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={values.password}
                        variant="outlined"
                    />
                    <Box mt={2}>
                        <SpinnerButton size="large" fullWidth isSubmitting={isSubmitting}>
                            Anmelden
                        </SpinnerButton>
                        {message && message === 'expired' && (
                            <Box mt={3}>
                                <FormHelperText error>
                                    {'Das Zeitlimit Ihrer Sitzung ist abgelaufen. Wir haben Sie aus Sicherheitsgründen abgemeldet, da Sie auf unserer Website für einige Zeit nicht aktiv waren. Nicht gespeicherte Daten wurden gelöscht. Bitte melden Sie sich erneut an.'}
                                </FormHelperText>
                            </Box>
                        )}
                        {errors.submit && (
                            <Box mt={3}>
                                <FormHelperText error>
                                    {errors.submit}
                                </FormHelperText>
                            </Box>
                        )}
                    </Box>
                </form>
            )}
        </Formik>
    );
}

LoginForm.propTypes = {
    onSubmitSuccess: PropTypes.func
};

LoginForm.defaultProps = {
    onSubmitSuccess: () => {
    }
};

export default LoginForm;
