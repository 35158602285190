import Grid from "@mui/material/Grid";
import React from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Link,
    Paper,
    Typography,
    Hidden, GlobalStyles
} from '@mui/material';
import {useSelector} from "react-redux";
import numeral from "src/mixins/numeral";

/*
    list: {
        color: theme.palette.text.secondary,
        ...theme.typography.body2,
        padding: theme.spacing(2),
        ul: {
            margin: theme.spacing(2),
            '&:last-child': {
                marginBottom: 0
            },
            li: {
                color: theme.palette.text.secondary,
                ...theme.typography.body2
            },
        },

    }
 */

function Subscription({...rest}) {
    const account = useSelector((state) => state.account);

    return (
        <>
            <GlobalStyles styles={(theme) => ({
                ul: {
                    margin: theme.spacing(2),
                    '&:last-child': {
                        marginBottom: 0
                    }
                },
                li: {
                    color: theme.palette.text.secondary,
                    ...theme.typography.body2
                },
            })}/>
            <div {...rest}>
                {
                    account.user.payment === null && <>
                        <Box mb={3}>
                            <Card>
                                <CardHeader title="Kein aktives Abonnement"/>
                                <Divider/>
                                <CardContent>
                                    <Box p={1}>
                                        <Typography
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            Sie besitzen noch kein aktives Abonnement, daher ist für Sie nur der Hilfe-Bereich zugänglich.<br/>
                                            Überzeugen Sie sich von unserem Angebot und erhalten Sie uneingeschränkten Zugriff! Testen Sie uns kostenlos +
                                            unverbindlich
                                            14 Tage!
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </>
                }

                <Card {...rest}>
                    <CardHeader title={account.user.payment === null ? "Unser kostenloses Angebot" : "Mein Abonnement"}/>
                    <Divider/>
                    <CardContent>
                        {
                            account.user.payment === null && <>
                                <Paper variant="outlined">
                                    <Box sx={{
                                        padding: 3,
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div>
                                            <Typography
                                                display="inline"
                                                variant="h4"
                                                color="textPrimary"
                                            >
                                                Test-Abonnement
                                            </Typography>
                                        </div>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                        >
                                            <Typography
                                                display="inline"
                                                variant="h4"
                                                color="textPrimary"
                                            >
                                                {numeral(0).format('0.00') + " EUR"}
                                            </Typography>
                                            <Box mx={1}>
                                                <Typography
                                                    display="inline"
                                                    variant="subtitle1"
                                                >/</Typography>
                                            </Box>
                                            <Typography
                                                display="inline"
                                                variant="subtitle1"
                                            >14 Tage*
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider/>
                                    <Box p={3}>
                                        <Typography
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            Ihr unverbindliches Test-Abonnement umfasst:
                                        </Typography>
                                        <Hidden lgUp>
                                            <ul>
                                                <li>Ergebnisse unsere Scoring-Modelle zu über 4.000 Aktien</li>
                                                <li>Daten zu den einzelnen Kennzahlen der Scoring-Modelle</li>
                                                <li>Vollständiger Zugriff auf unser Aktien-Ranking</li>
                                                <li>Erstellen eigener Rankings</li>
                                                <li>Vollständiger Einblick in unsere Investmentstrategien</li>
                                                <li>Anlegen eigener Strategien & Depots</li>
                                                <li>Analysen & Ergebnisse unserer Investitionsquote</li>
                                                <li>Ihr persönliches Dashboard</li>
                                                <li>Zugriff auf zahlreiche Artikel & News</li>
                                                <li>Videokurse aus der Basis-Mitgliedschaft</li>
                                            </ul>
                                        </Hidden>
                                        <Hidden lgDown>
                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    <ul>
                                                        <li>Ergebnisse unsere Scoring-Modelle zu über 4.000 Aktien</li>
                                                        <li>Daten zu den einzelnen Kennzahlen der Scoring-Modelle</li>
                                                        <li>Vollständiger Zugriff auf unser Aktien-Ranking</li>
                                                        <li>Erstellen eigener Rankings</li>
                                                        <li>Vollständiger Einblick in unsere Investmentstrategien</li>
                                                    </ul>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <ul>
                                                        <li>Anlegen eigener Strategien & Depots</li>
                                                        <li>Analysen & Ergebnisse unserer Investitionsquote</li>
                                                        <li>Ihr persönliches Dashboard</li>
                                                        <li>Zugriff auf zahlreiche Artikel & News</li>
                                                        <li>Videokurse aus der Basis-Mitgliedschaft</li>
                                                    </ul>
                                                </Grid>
                                            </Grid>
                                        </Hidden>
                                    </Box>
                                </Paper>
                                <Box
                                    mt={2}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Link
                                        href={"https://www.digistore24.com/product/346133/112310?email=" + account.user.username}
                                        underline="none">
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                        >
                                            Jetzt kostenlos testen
                                        </Button>
                                    </Link>
                                </Box>
                                <Box mt={4}>
                                    <Typography variant="caption" color="textSecondary">* 14 Tage kostenlos testen. Im Anschluss an Ihr Test-Abonnement 39,00 EUR /
                                        Monat oder
                                        390,00
                                        EUR / Jahr.</Typography>
                                </Box>
                            </>
                        }
                        {
                            account.user.payment && <>
                                <Paper variant="outlined">
                                    <Box sx={{
                                        padding: 3,
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div>
                                            <Typography
                                                display="inline"
                                                variant="h4"
                                                color="textPrimary"
                                            >
                                                {account.user.payment.product_name}
                                            </Typography>
                                        </div>
                                    </Box>
                                    <Divider/>
                                    <Box p={3}>
                                        <Typography
                                            variant="body2"
                                            color="textPrimary"
                                        >
                                            Ihr investolio Premium-Abonnement umfasst:
                                        </Typography>
                                        <Hidden lgUp>
                                            <ul>
                                                <li>Ergebnisse unsere Scoring-Modelle zu über 4.000 Aktien</li>
                                                <li>Daten zu den einzelnen Kennzahlen der Scoring-Modelle</li>
                                                <li>Vollständiger Zugriff auf unser Aktien-Ranking</li>
                                                <li>Erstellen eigener Rankings</li>
                                                <li>Vollständiger Einblick in unsere Investmentstrategien</li>
                                                <li>Anlegen eigener Strategien & Depots</li>
                                                <li>Analysen & Ergebnisse unserer Investitionsquote</li>
                                                <li>Ihr persönliches Dashboard</li>
                                                <li>Zugriff auf zahlreiche Artikel & News</li>
                                                <li>Videokurse aus der Basis-Mitgliedschaft</li>
                                            </ul>
                                        </Hidden>
                                        <Hidden lgDown>
                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    <ul>
                                                        <li>Ergebnisse unsere Scoring-Modelle zu über 4.000 Aktien</li>
                                                        <li>Daten zu den einzelnen Kennzahlen der Scoring-Modelle</li>
                                                        <li>Vollständiger Zugriff auf unser Aktien-Ranking</li>
                                                        <li>Erstellen eigener Rankings</li>
                                                        <li>Vollständiger Einblick in unsere Investmentstrategien</li>
                                                    </ul>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <ul>
                                                        <li>Anlegen eigener Strategien & Depots</li>
                                                        <li>Analysen & Ergebnisse unserer Investitionsquote</li>
                                                        <li>Ihr persönliches Dashboard</li>
                                                        <li>Zugriff auf zahlreiche Artikel & News</li>
                                                        <li>Videokurse aus der Basis-Mitgliedschaft</li>
                                                    </ul>
                                                </Grid>
                                            </Grid>
                                        </Hidden>
                                    </Box>
                                </Paper>
                                <Box
                                    mt={2}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    {
                                        <Link href={account.user.payment.receipt_url} underline="none">
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                            >
                                                Bestellung anzeigen
                                            </Button>
                                        </Link>
                                    }
                                </Box>
                                <Box
                                    mt={2}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    {
                                        (account.user.payment.event === "on_rebill_cancelled" || account.user.payment.event === "on_payment_missed") &&
                                        <Link href={account.user.payment.renew_url} underline="none">
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                            >
                                                Mitgliedschaft verlängern
                                            </Button>
                                        </Link>
                                    }
                                </Box>
                                <Box mt={4}>
                                    {
                                        (account.user.payment.event === "on_rebill_cancelled" || account.user.payment.event === "on_payment_missed") && account.user.payment.hasOwnProperty("rebill_stop_noted_at") ?
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                Sie können Ihr Abonnement jederzeit wieder aufnehmen:
                                                <br/>
                                                <Link href={account.user.payment.renew_url} color="secondary" underline="none">
                                                    Kündigung widerrufen
                                                </Link>
                                            </Typography>
                                            :
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                Sie können Ihr Abonnement jederzeit kündigen:
                                                <br/>
                                                <Link
                                                    href={account.user.payment.rebilling_stop_url}
                                                    color="secondary"
                                                    underline="none">
                                                    Abonnement kündigen
                                                </Link>
                                            </Typography>
                                    }
                                </Box>
                            </>
                        }
                    </CardContent>
                </Card>
            </div>
        </>
    );
}

Subscription.propTypes = {};

export default Subscription;
