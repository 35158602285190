import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Breadcrumbs, Button,
    Grid,
    Link, SvgIcon,
    Typography
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {PlusCircle as PlusCircleIcon} from "react-feather";

function Header({watchlist, ...rest}) {
    return (
        <Grid
            container
            spacing={3}
            justifyContent="space-between"
            {...rest}
        >
            <Grid item>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small"/>}
                    aria-label="breadcrumb"
                >
                    <Link
                        variant="body1"
                        color="inherit"
                        to="/depot"
                        component={RouterLink}
                        underline="none">
                        Depot
                    </Link>
                    <Link
                        variant="body1"
                        color="inherit"
                        to="/depot/watchlist"
                        component={RouterLink}
                        underline="none">
                        Watchlists
                    </Link>
                    <Typography
                        variant="body1"
                        color="textPrimary"
                    >
                        {watchlist}
                    </Typography>
                </Breadcrumbs>
                <Typography
                    variant="h3"
                    color="textPrimary"
                >
                    {watchlist}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    color="secondary"
                    variant="contained"
                    component={RouterLink}
                    to="/stocks/search"
                >
                    <SvgIcon
                        fontSize="small"
                        sx={{
                            marginRight: 1
                        }}
                    >
                        <PlusCircleIcon/>
                    </SvgIcon>
                    Aktie hinzufügen
                </Button>
            </Grid>
        </Grid>
    );
}

Header.propTypes = {
    watchlist: PropTypes.string.isRequired
};

export default Header;
