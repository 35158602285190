import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    Typography
} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import numeral from "src/mixins/numeral";

function KombiScore({onHelp, label, value, helpTitle, helpText}) {
    return (
        <Card>
            <CardHeader
                sx={{
                    color: 'secondary.contrastText',
                    backgroundColor: 'secondary.main',
                    padding: 2,
                    paddingBottom: 1
                }}
                title="Kombi-Score"
                action={
                    <IconButton onClick={() => onHelp(helpTitle, helpText)} size="large">
                        <HelpOutlineIcon style={{color: "#ffffff"}}/>
                    </IconButton>
                }
            />
            <CardContent sx={{
                color: 'secondary.contrastText',
                backgroundColor: 'secondary.main',
                py: 0,
                px: 2
            }}>
                <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                >
                    <Typography
                        variant="h3"
                        color="inherit"
                    >
                        {numeral(value).format('(0.0 %)')}
                    </Typography>
                    <LinearProgress
                        sx={{
                            my: 0,
                            mx: 1,
                            flexGrow: 1,
                            backgroundColor: 'common.white',
                            "& .MuiLinearProgress-bar": {
                                backgroundColor: '#8cb3dc'
                            }
                        }}
                        value={value * 100}
                        variant="determinate"
                    />
                </Box>
            </CardContent>
        </Card>
    );
}

KombiScore.propTypes = {
    onHelp: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.number,
    helpTitle: PropTypes.string,
    helpText: PropTypes.string,
};

export default KombiScore;
