import React, {useCallback, useEffect, useState} from 'react';
import {Link as RouterLink, useParams} from 'react-router-dom';
import {Box, Card, CardContent, Container, Divider, Link, Typography} from '@mui/material';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import useIsMountedRef from "src/hooks/useIsMountedRef";
import axios from 'src/utils/axios';
import LinearProgress from "@mui/material/LinearProgress";

function Verify() {
    const isMountedRef = useIsMountedRef();
    const {email, token} = useParams();
    const [status, setStatus] = useState(null);

    const verifyUser = useCallback(() => {
        axios
            .get('/api/open/user/verify', {
                params: {
                    username: email,
                    vt: token
                }
            })
            .then((response) => {
                /*
                Verifizierung erfolgreich:
                - E-Mail erfolgreich bestätigt
                 */
                if (isMountedRef.current) {
                    if (response.data.payload.hasOwnProperty("user") && response.data.payload.user.username) {
                        setStatus(true);
                    }
                }
            })
            .catch((error) => {
                /**
                 Verifizierung fehlgeschlagen:
                 - E-Mail-Adresse unbekannt
                 - Token unbekannt
                 - Token abgelaufen
                 - E-Mail bereits bestätigt
                 */
                setStatus(false);
            });
    }, [isMountedRef, email, token]);

    useEffect(() => {
        verifyUser();
    }, [verifyUser]);

    return (
        <Page
            style={{
                justifyContent: 'center',
                backgroundColor: 'background.dark',
                display: 'flex',
                height: '100%',
                minHeight: '100%',
                flexDirection: 'column',
                paddingBottom: 80,
                paddingTop: 80
            }}
            title="Registrierung"
        >
            <Container maxWidth="sm">
                <Box mb={5} display="flex" alignItems="center">
                    <RouterLink to="/">
                        <Logo invert/>
                    </RouterLink>
                </Box>
                <Card>
                    <CardContent>
                        {status === null && <LinearProgress/>}
                        {
                            status === true &&
                            <>
                                <Typography gutterBottom variant="h2" color="textPrimary"> Verifizierung erfolgreich </Typography>
                                <Typography variant="subtitle1" color="textSecondary"> Ihre E-Mail-Adresse wurde erfolgreich bestätigt. </Typography>
                                <Box mt={3}>
                                    <Typography variant="body2" color="textSecondary">
                                        Sie können sich nun mit Ihrem Benutzernamen und Ihrem Passwort anmelden.
                                    </Typography>
                                </Box>
                                <Box my={2}>
                                    <Divider/>
                                </Box>
                                <Link component={RouterLink} to="/login" variant="body2" color="textSecondary" underline="none">Jetzt anmelden</Link>
                            </>
                        }
                        {
                            status === false &&
                            <>
                                <Typography gutterBottom variant="h2" color="textPrimary">
                                    Verifizierung fehlgeschlagen
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    Ihre E-Mail-Adresse konnte nicht bestätigt werden!
                                </Typography>
                                <Box mt={3}>
                                    <Typography variant="body2" color="textSecondary">
                                        Ihre E-Mail-Adresse wurde bereits bestätigt oder die Gültigkeit des Links aus Ihrer erhaltenen E-Mail ist abgelaufen.
                                    </Typography>
                                </Box>
                                <Box my={2}>
                                    <Divider/>
                                </Box>
                                <Link component={RouterLink} to="/" variant="body2" color="textSecondary" underline="none">Zur Startseite</Link>
                            </>
                        }
                    </CardContent>
                </Card>
            </Container>
        </Page>
    );
}

export default Verify;
