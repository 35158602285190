import _ from "lodash";
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Container} from '@mui/material';
import Page from 'src/components/Page';
import numeral from "src/mixins/numeral";
import Header from './Header';
import {useParams} from "react-router";
import {Navigate} from 'react-router-dom';
import useIsMountedRef from "src/hooks/useIsMountedRef";
import axios from "src/utils/axios";
import LoadingScreen from "src/components/LoadingScreen";
import {getTitle} from "src/utils/meta";
import RankingStockResults from "./RankingStockResults";
import SearchRankingForm from "./SearchRankingForm";
import {useGridApiRef} from "@mui/x-data-grid-pro";

function RankingDetailView() {
    const apiRef = useGridApiRef();
    const formRef = useRef();
    const isMountedRef = useIsMountedRef();
    const [manualSearchCountForUpdating, setManualSearchCountForUpdating] = useState(0);
    const [ranking, setRanking] = useState(null);
    const [showSearchResult, setShowSearchResult] = useState(false);
    const {id} = useParams();

    const onFormSearch = () => {
        if (showSearchResult) {
            setManualSearchCountForUpdating(manualSearchCountForUpdating + 1);
        } else {
            setShowSearchResult(true);
        }
    };

    const onTableSearch = useCallback((queryOptions) => {
        let orderfield = queryOptions?.sortModel[0]?.field;
        let orderdirection = queryOptions?.sortModel[0]?.sort === 'asc' ? 'ASC' : 'DESC';
        let paginationPage = queryOptions.page;
        let paginationPageSize = queryOptions.pageSize;

        if (Object.keys(apiRef.current).length) {
            if (!paginationPage) {
                paginationPage = apiRef.current.state.pagination.page;
            }

            if (!paginationPageSize) {
                paginationPageSize = apiRef.current.state.pagination.pageSize;
            }

            let sortModel = apiRef.current.getSortModel();
            orderfield = sortModel[0]?.field;
            orderdirection = sortModel[0]?.sort === 'asc' ? 'ASC' : 'DESC';
        }

        let filterOptions = _.merge(getFormFilterOptions(), {
            'search': queryOptions?.search,
            'orderfield': orderfield,
            'orderdirection': orderdirection
        });

        return new Promise(async (resolve, reject) => {
            let response = await axios.post('/api/invest/akties/filters', {
                "offset": paginationPage * paginationPageSize,
                "limit": paginationPageSize,
                filteroptions: filterOptions
            });

            if (response) {
                if (response.data.payload.hasOwnProperty("aktien")) {
                    // The table requires all rows to have an unique id property. A row was provided without id in the rows prop.
                    // To prevent the loss of state between renders, please provide an unique id for each row.
                    response.data.payload.aktien = response.data.payload.aktien.map(aktie => {
                        return {
                            id: aktie.Index_kurz + '_' + aktie.ISIN,
                            ...aktie
                        }
                    });

                    resolve({
                        data: response.data.payload.aktien,
                        totalCount: response.data.payload.totalCount,
                    });
                }
            }

            reject();
        });
    }, [isMountedRef]);

    const getFormFilterOptions = () => {
        let filteroptions = {};

        Object.keys(formRef.current.values).forEach(key => {
            if (formRef.current.values[key] && formRef.current.values[key] !== "") {
                switch (key) {
                    case 'Handelsvolumen_min':
                    case 'Handelsvolumen_max':
                    case 'MarketCap_min':
                    case 'MarketCap_max':
                        filteroptions[key] = numeral(formRef.current.values[key]).value();
                        break;

                    case 'Trend_Score_min':
                    case 'Trend_Score_max':
                    case 'Kombi_Score_min':
                    case 'Kombi_Score_max':
                    case 'Technisch_Score_min':
                    case 'Technisch_Score_max':
                    case 'Risiko_Score_min':
                    case 'Risiko_Score_max':
                    case 'Value_Score_min':
                    case 'Value_Score_max':
                        filteroptions[key] = formRef.current.values[key] && parseInt(formRef.current.values[key]) > 0 ? formRef.current.values[key] / 100 : formRef.current.values[key];
                        break;

                    default:
                        filteroptions[key] = formRef.current.values[key];
                        break;
                }
            }
        });

        ["Index_kurzList", "SectorList", "excludeDepotIdList"].forEach((multiSelectFieldName) => {
            // Wenn "Keine Auswahl" gewählt wurde, enthält das Feld ein Array mit einem Leerstring auf Index "0". Das Backend erwartet bei keiner Auswahl aber
            // leeres Array, also ohne einen leeren Index, daher hier eine Umwandlung vornehmen
            if (filteroptions[multiSelectFieldName].length === 1 && filteroptions[multiSelectFieldName][0] === '') {
                filteroptions[multiSelectFieldName] = [];
            }
        });

        return filteroptions;
    };

    const getRanking = useCallback(() => {
        if (id) {
            setShowSearchResult(true);

            axios
                .get('/api/filter/filters/' + id)
                .then((response) => {
                    if (isMountedRef.current) {
                        if (response.data.payload.hasOwnProperty("filter") /*&& response.data.payload.depotsaktien*/) {
                            setRanking(response.data.payload.filter);
                        }
                    }
                })
                .catch((error) => {
                    setRanking(false);
                    //setStocks(false);
                });
        }
    }, [isMountedRef, id]);

    useEffect(() => {
        getRanking();
    }, [getRanking]);

    if (id) {
        if (ranking === null) {
            return <LoadingScreen/>;
        }

        if (ranking === false) {
            return <Navigate to="/404"/>;
        }
    }

    return (
        <Page sx={{
            backgroundColor: 'background.dark',
            minHeight: '100%',
        }} title={ranking ? getTitle('Ranking "' + ranking.bezeichnung + '"') : "Neues Ranking"}>
            <Container sx={{
                paddingTop: 3,
                paddingBottom: 3
            }} maxWidth={false}>
                <Header ranking={ranking}/>
                <Box mt={3}>
                    <SearchRankingForm ranking={ranking} formRef={formRef} onSearch={onFormSearch}/>
                </Box>
                {
                    showSearchResult &&
                    <Box mt={6}>
                        <RankingStockResults apiRef={apiRef} manualSearchCountForUpdating={manualSearchCountForUpdating} onSearch={onTableSearch}/>
                    </Box>
                }
            </Container>
        </Page>
    );
}

export default RankingDetailView;
