import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Box, Card, CardContent, Container, Divider, Link, Typography} from '@mui/material';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import LegalNoteLinkList from "src/components/LegalNoteLinkList";
import RegisterForm from './RegisterForm';

function RegisterView() {
    const [registrationSuccess, setRegistrationSuccess] = useState(null);

    const handleSubmitSuccess = () => {
        setRegistrationSuccess(true);
    };

    return (
        <Page
            style={{
                justifyContent: 'center',
                backgroundColor: 'background.dark',
                display: 'flex',
                height: '100%',
                minHeight: '100vh',
                flexDirection: 'column',
                paddingBottom: 80,
                paddingTop: 80
            }}
            title="Registrieren"
        >
            <Container maxWidth="sm">
                <Box mb={5} display="flex" alignItems="center">
                    <RouterLink to="/">
                        <Logo invert/>
                    </RouterLink>
                </Box>
                <Card>
                    <CardContent>
                        {
                            registrationSuccess === true &&
                            <>
                                <Typography gutterBottom variant="h2" color="textPrimary"> Registrierung </Typography>
                                <Typography variant="subtitle1" color="textSecondary"> Ihr Benutzerkonto wurde erfolgreich erstellt </Typography>
                                <Box mt={3}>
                                    <Typography variant="body2" color="textSecondary">
                                        Wir haben Ihnen per E-Mail einen Link zur Bestätigung Ihrer E-Mail-Adresse geschickt. Bitte bestätigen Sie über diesen
                                        Link Ihre E-Mail-Adresse.
                                    </Typography>
                                </Box>
                                <Box my={2}><Divider/></Box>
                                <Link component={RouterLink} to="/" variant="body2" color="textSecondary" underline="none"> Zur Startseite </Link>
                            </>
                        }
                        {
                            !registrationSuccess &&
                            <>
                                <Typography gutterBottom variant="h2" color="textPrimary">Registrieren</Typography>
                                <Typography variant="subtitle1" color="textSecondary">Erstellen Sie schnell und einfach ein neues Konto.</Typography>
                                <Box mt={3}><RegisterForm onSubmitSuccess={handleSubmitSuccess}/></Box>
                                <Box my={2}><Divider/></Box>
                                <Link component={RouterLink} to="/login" variant="body2" color="textSecondary" underline="none">Benutzerkontovorhanden?</Link>
                            </>
                        }
                    </CardContent>
                </Card>
                <Box display="flex" justifyContent="center" mt={2}>
                    <LegalNoteLinkList/>
                </Box>
            </Container>
        </Page>
    );
}

export default RegisterView;
