import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useSnackbar} from 'notistack';
import {Link as RouterLink} from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import useIsMountedRef from "src/hooks/useIsMountedRef";
import axios from "src/utils/axios";
import LinearProgress from "@mui/material/LinearProgress";
import ScoreLabel from "src/components/ScoreLabel";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useDispatch, useSelector} from "react-redux";
import {removeStock} from "src/actions/compareActions";
import numeral from "src/mixins/numeral";
import Chart from "./Chart";

function CompareStockCard({isin, index_kurz, ...rest}) {
  const {enqueueSnackbar} = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const [stock, setStock] = useState(null);
  const account = useSelector((state) => state.account);

  const getStock = useCallback(() => {
    axios
      .get('/api/invest/aktie', {
        params: {
          ISIN: isin,
          Index_kurz: index_kurz
        }
      })
      .then((response) => {
        if (isMountedRef.current) {
          if (response.data.payload.hasOwnProperty("aktie")) {
            setStock(response.data.payload.aktie[0]);
          }
        }
      });
  }, [isMountedRef, isin, index_kurz]);

  useEffect(() => {
    getStock();
  }, [getStock]);

  const getRow = (key, value) => (
    <TableRow>
      <TableCell sx={{fontWeight: 'medium'}}>
        {key}
      </TableCell>
      <TableCell align="right">
        {
          React.isValidElement(value) ? {value} : (
            <Typography variant="body2" color="textSecondary">
              {value}
            </Typography>
          )}
      </TableCell>
    </TableRow>
  );

  const handleDeleteClick = () => {
    //setAnchorEl(null);
    dispatch(removeStock(index_kurz, isin));
    enqueueSnackbar('Aktie vom Vergleich entfernt', {
      variant: 'success'
    });
  };

  return (
    <>
      {
        !stock &&
        <Card {...rest}>
          <LinearProgress/>
          <CardHeader title=""/>
        </Card>
      }
      {
        stock &&
        <Card {...rest}>
          <CardHeader title={stock.Aktienname} subheader={stock.ISIN}/>
          <Chart indexKurz={stock.Index_kurz} isin={stock.ISIN} datum={account.user.livedate}/>
          <Divider/>
          <Table>
            <TableBody>
              {getRow("Kurs", numeral(stock.Kurs).format('0.00') + " " + stock.Currency)}

              <TableRow>
                <TableCell sx={{fontWeight: 'medium'}}>
                  Trend-Score
                </TableCell>
                <TableCell align="right">
                  <ScoreLabel name="trendScore" value={stock.Trend_Score}/>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{fontWeight: 'medium'}}>
                  Safety-Score
                </TableCell>
                <TableCell align="right">
                  <ScoreLabel name="risikoScore" value={stock.Risiko_Score}/>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{fontWeight: 'medium'}}>
                  Technischer-Score
                </TableCell>
                <TableCell align="right">
                  <ScoreLabel name="technischerScore" value={stock.Technisch_Score}/>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{fontWeight: 'medium'}}>
                  Value-Score
                </TableCell>
                <TableCell align="right">
                  <ScoreLabel name="valueScore" value={stock.Value_Score}/>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell sx={{fontWeight: 'medium'}}>
                  Kombi-Score
                </TableCell>
                <TableCell align="right">
                  <ScoreLabel name="kombiScore" value={stock.Kombi_Score}/>
                </TableCell>
              </TableRow>

              {getRow("Marktkapitalisierung", numeral(stock.MarketCap).format('(0,0)') + " Mio. EUR")}
              {getRow("Handelsvolumen", numeral(stock.Handelsvolumen).format('(0,0)') + " Tsd. EUR")}
              {getRow("Land", stock["Country:bez"])}
              {getRow("Industrie", stock["Industry:bez"])}
            </TableBody>
          </Table>
          <Box
            p={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Button component={RouterLink} to={`/stocks/search/${index_kurz}/${isin}`}>
              <VisibilityIcon sx={{mr: 1}}/>
              Aktie anzeigen
            </Button>
            <Button onClick={handleDeleteClick}>
              <DeleteOutlineIcon sx={{mr: 1}}/>
              Aktie entfernen
            </Button>
          </Box>
        </Card>
      }
    </>
  );
}

CompareStockCard.propTypes = {
  isin: PropTypes.string.isRequired,
  index_kurz: PropTypes.string.isRequired,
};

export default CompareStockCard;
